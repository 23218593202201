import L from 'leaflet'

// ${strokeColor}
export const speedometerDivIcon = (
    size,
    fillColor = '#ffbf00',
    strokeColor = '#000'
) => new L.DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
    <path dill="${strokeColor}" d="m188.4 39.14c-82.32 0-149.3 66.98-149.3 149.\
3 0 24.33 5.97 47.25 16.34 67.56h25.47c-3.145-4.969-6.015-10.13-8.453-15.54l18.\
63-7.844-2.27-5.393-18.63 7.84c-5.042-12.73-8.03-26.44-8.684-40.79h20.06v-11.\
68h-20.05c0.63-13.8 3.438-27.03 8.154-39.35l18.69 7.607 2.197-5.412-18.76-7.\
627c5.63-12.91 13.41-24.66 22.79-34.93l14.15 14.15 8.262-8.262-14.15-14.15c9.\
9-9.03 21.18-16.55 33.55-22.12l7.84 18.63 5.387-2.268-7.84-18.63c12.72-5.\
042 26.43-8.028 40.78-8.682v20.05h11.68v-20.05c13.8 0.634 27.06 3.436 39.36 8.\
152l-7.607 18.69 5.402 2.197 7.631-18.76c29 12.64 52.33 35.78 65.31 64.59l-18.\
64 7.84 2.27 5.389 18.63-7.84c5.052 12.73 8.025 26.44 8.684 40.79h-20.04v11.\
7h20.04c-0.625 13.8-3.439 27.02-8.145 39.35l-18.69-7.613-2.197 5.426 18.75 7.\
617c-2.571 5.905-5.645 11.54-9.068 16.93h25.44c10.37-20.32 16.34-43.23 16.34-\
67.56 0-82.32-66.98-149.3-149.3-149.3z" stroke-width="4.735"/>
    <path d="m140.6 201.7 194.7-156.3-146.7 194.2-6.778-36.31z" fill="#cb0000" 
      fill-rule="evenodd"/>
  </svg>`,
  iconAnchor: [size / 2, size / 2],
  popupAnchor: [0, -size / 2],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [size, size],
  className: '',
})
