import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { DivIcon } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'


import { Device } from '../Device'
import { sortBy } from '../../common'
import { useSupercluster } from '../../hooks'
import { useStore } from '../../stores/rootStore'


import './clusterLayer.css'
import { toJS } from 'mobx'


const getLevel = (number) => {
  const level = {
    level: 0,
    size: 0,
  }

  switch (true) {
    case number < 4:
      level.level = 1
      level.size = 40
      break

    case number >= 4 && number < 8:
      level.level = 2
      level.size = 50
      break

    case number >= 8 && number < 12:
      level.level = 3
      level.size = 60
      break

    default:
      level.level = 4
      level.size = 70
  }
  return level
}

const markerIcon = (number) => {
  const level = getLevel(number)
  return new DivIcon({
    html: `<div><span>${number}</span></div>`,
    iconAnchor: [level.size / 2, level.size / 2],
    popupAnchor: [0, -(level.size / 2)],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [level.size, level.size], // new L.Point(size, size),
    className: `cluser-level-${level.level}`, // 'with-shadow',
  })
}

const popupStyle = { width: '240px', maxHeight: '240px', overflowY: 'auto', margin: '20px' }
const popupListStyle = { margin: '3px auto', cursor: 'pointer' }

const ClusterMarker = ({ number, info, ...props }) => {
  const ref = useRef()
  return <Marker ref={ref} icon={markerIcon(number)} {...props}>
    <Popup offset={[0, 0]}>
      {/* <h3>Объекты в кластере</h3> */}
      <div style={popupStyle}>
        {info.sort(sortBy('title')).map((dev, key) => {
          return (
            <p key={key} style={popupListStyle}
              title={dev.fullTitle + ' ' + dev.imei}
              onClick={() => {
                if (typeof props.onClusterItemClick === 'function') props.onClusterItemClick(dev.id)
                ref.current.leafletElement.closePopup()
              }}
            >
              {dev.title}
            </p>
          )
        })}
      </div>
    </Popup>
  </Marker>
}

ClusterMarker.propTypes = {
  number: PropTypes.number,
  info: PropTypes.array,
  onClusterItemClick: PropTypes.func,
}

const __ClusterLayer = (props) => {
  const store = useStore()

  const {clusters, supercluster} = useSupercluster({
    points: store.map.devicesGeoJSON,
    bounds: [
      store.map.currentBounds[1],
      store.map.currentBounds[0],
      store.map.currentBounds[3],
      store.map.currentBounds[2],
    ],
    zoom: toJS(store.map.currentZoom),
    options: {
      // log: true,
      // reduce: (accumulated, props) => {
      //   // console.info(accumulated, props)
      //   if (accumulated.entityID != null && accumulated.clusterDevices == null) {
      //     accumulated.clusterDevices = [accumulated.entityID]
      //   }
      //   if (accumulated.entityID) {
      //     accumulated.entityID = ''
      //   }
      //   if (accumulated.entityID != null && props.entityID.length !== 0) {
      //     accumulated.clusterDevices.push(props.entityID)
      //   }
      // },
      // map: (props) => ({ entityID: props.id }),
      radius: 100,
      minZoom: 3,
      maxZoom: 17,
    },
  })

  // React.useEffect(() => {
  //   store.map.restartMapData()

  //   return () => {
  //     store.map.stopMapData()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const onClusterItemClick = (id) => {
    if (id === store.map.selected.id) {
      store.map.deselect()
    } else {
      store.map.findDevice(id)
    }
  }

  const selectedDevice = store.map.devices.find((d) => d.id === store.map.selected.id)

  return <>
  {selectedDevice!= null && selectedDevice.data.lastValid != null &&
    <Device
      key={`dev-${selectedDevice.id}`}
      id={selectedDevice.id}
      lastPoint={selectedDevice.data.lastValid}
      onClick={store.map.deselect}
    />}

    {clusters.map((cluster, key) => {
      if (cluster.properties.cluster) {
        const info = []

        getChildren(supercluster, cluster.id).forEach((id) => {
          const device = store.map.devices.find((d) => d.id === id)
          if (device != null) {
            info.push({
              id: device.id,
              imei: device.imei,
              title: device.params.title,
              fullTitle: device.params.fullTitle,
            })
          }
        })

        return <ClusterMarker
          key={key}
          position={[cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]]}
          number={cluster.properties.point_count}
          info={info}
          onClusterItemClick={onClusterItemClick}
        />
      }

      return <Device key={`dev-${cluster.id}`} id={cluster.id}
        lastPoint={cluster.properties.lastPoint}
        onClick={() => { store.map.findDevice(cluster.id, true) }}
      />
    })}
  </>
}

export const ClusterLayer = observer(__ClusterLayer)

export default ClusterLayer

const getChildren = (supercluster, id) => {
  const devices =[]
  const children = supercluster.getChildren(id)
  children.forEach((c) => {
    if (c.properties?.cluster) {
      devices.push(...getChildren(supercluster, c.id))
    } else {
      devices.push(c.id)
    }
  })
  return devices
}
