import React from 'react'
import { PropTypes } from 'prop-types'
import { FilterOutlined, UpOutlined } from '@ant-design/icons'

import styles from './CollapsablePanel.module.css'


export const CollapsablePanel = (props) => {
  return (
    <div
      className={`${styles.panel} ${props.collapsed ? styles.closed : ''}`}
      title={props.title}
    >
      {props.collapsed && <FilterOutlined onClick={props.onToggle} />}
      {!props.collapsed && <UpOutlined className={styles.icon} title='Свернуть' onClick={props.onToggle} />}

      {!props.collapsed && <header><span className={styles.caption}>{props.caption}</span></header>}
      {!props.collapsed && props.children}
    </div>
  )
}

CollapsablePanel.propTypes = {
  onToggle: PropTypes.func,
  collapsed: PropTypes.bool,
  caption: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
}

CollapsablePanel.defaultProps = {
  caption: '',
  title: '',
}
