import React from 'react'
import { render } from 'react-dom'
import promiseFinally from 'promise.prototype.finally'
import { Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
// import {I18nextProvider} from 'react-i18next'
// import i18n from './i18n'
import history from './history'
// import registerServiceWorker from './registerServiceWorker'


import { ErrorHandler } from './components/ErrorHandler'
import App from './App'
// import API from './tools/api'
// import rootStore from './stores/rootStore'
import { RootStore, StoreContext } from './stores/rootStore'
// import { prod } from './common'
// import { StoreProvider } from './stores/provider'


import 'antd/dist/antd.css'
import './index.css'


promiseFinally.shim()


const store = new RootStore()


render(
  // <I18nextProvider i18n={i18n}>
  <StoreContext.Provider value={store}>
  <Provider rootStore={store}>
    {/* <StoreProvider api={API} rootStore={rootStore} features={['geozones']}></StoreProvider> */}
    <Router history={history}>
      <ErrorHandler>
        <App />
      </ErrorHandler>
    </Router>
  </Provider>
  </StoreContext.Provider>,
  // </I18nextProvider>,
  document.getElementById('root'),
)

// registerServiceWorker()
