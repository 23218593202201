/**
 * track worker draft
 */
const workercode = () => {
  const earthRadius = 6371000 // Earth radius in meters
  const earthRadiusKm = earthRadius / 1000 // Earth radius in kilometers
  const HZ = 0.01745329251994329577
  const earthRadiusR = earthRadiusKm * (Math.PI / 180)

  const parseJSON = (argument) => {
    const parts = argument.match(
      /(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2})(?:\.(\d{0,7}))?(?:Z|\+00:?00)?/,
    )
    if (parts) {
      return new Date(
        Date.UTC(
          +parts[1],
          parts[2] - 1,
          +parts[3],
          +parts[4],
          +parts[5],
          +parts[6],
          +((parts[7] || '0') + '00').substring(0, 3),
        ),
      )
    }
    return new Date(NaN)
  }

  const distanceFrom = (point1, point2) => {
    if (!point1 || !point1.lat || !point1.lon) return 0 // throw new Error('Invalid first point')
    if (!point2 || !point2.lat || !point2.lon) return 0 // throw new Error('Invalid second point')

    const { lat: lat1, lon: lon1 } = point1
    const { lat: lat2, lon: lon2 } = point2

    let pxl = (lon1 - lon2) * earthRadiusR * Math.cos(lat1 * HZ)
    pxl = pxl * pxl

    let pyl = (lat1 - lat2) * earthRadiusR
    pyl = pyl * pyl

    const r = pxl + pyl

    return Math.sqrt(r)
  }

  const trackDistance = (f) => (track) => {
    const l = track.length
    let ttl = 0
    for (let i = 0; i < l - 1; i++) {
      const point1 = track[i].coords
      const point2 = track[i + 1].coords
      ttl += f(point1, point2)
    }
    return Math.round(ttl)
  }

  const rawTrackDistance = trackDistance(distanceFrom)

  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener('message', (e) => {
    const track = e.data.track
    const helpers = e.data.track.helpers
    // const rawTrackDistance = e.data.rawTrackDistance
    // const Moment = e.data.moment

    const points = []
    track.data.forEach((point) => {
      if (!point.coords.valid) return
      points.push(point)
    })

    if (points.length === 0) {
      const err = new Error('No valid points')
      err.Name = 'ErrNoValidPoints'
      /* eslint-disable-next-line no-restricted-globals */
      self.postMessage({ error: err })
      return
    }

    // Calculating distances
    if (helpers.shifts.length > 0) {
      helpers.shifts.forEach((shift, index) => {
        if (shift.shiftType === 9) {
          helpers.shifts[index].distance = rawTrackDistance(track.data)
          return
        }

        const shiftData = []
        const start = parseJSON(shift.timeBegin)
        const end = parseJSON(shift.timeEnd)

        for (let i = 0; i < points.length; i++) {
          if (points[i].time < start) continue
          if (points[i].time > end) break
          shiftData.push(track.data[i])
        }
        helpers.shifts[index].distance = rawTrackDistance(shiftData)
      })
    }

    const response = {
      points: points,
      helpers: helpers,
    }

    /* eslint-disable-next-line no-restricted-globals */
    self.postMessage(response)
  })
}

let code = workercode.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))

const blob = new Blob([code], { type: 'application/javascript' })
export const trackWorker = URL.createObjectURL(blob)
