import React, { Suspense } from 'react'
import { NavLink, Route, RouteComponentProps, Switch, useLocation, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Button from 'antd/es/button'
import { ArrowLeftOutlined } from '@ant-design/icons'


// import { MapFeature } from '../../store/interfaces'
import { useStore } from '../../stores/rootStore'


import styles from './SideControlPanel.module.css'


interface MobileControlPanelProps extends RouteComponentProps {
  features: any[] // TODO type MapFeature[]
}


// TODO On mobile stays horizontal
const MobileControlPanel: React.FC<MobileControlPanelProps> = (props) => {
  const store = useStore()
  const location = useLocation()

  const onClick = () => {
    store.ui.setSideMenuActive(!store.ui.sideMenuActive)
  }

  const current = props.features.find((ft) => location.pathname === ft.path) || props.features[0]

  return <>
    {!store.ui.sideMenuActive && <div className={styles.floatingContainer}>
      <Button shape='circle' size='large' onClick={() => store.ui.setSideMenuActive(true)}>
        {<current.icon />}
      </Button>
    </div>}

    <div className={store.ui.sideMenuActive ? styles.mcontainer : styles.mcontainerCollapsed}>
      <div className={styles.controls}>
        {props.features.map((ft) => {
          return <NavLink
            className={styles.linkIcon}
            activeClassName={styles.linkIconActive}
            key={`tab-key-${ft.name}`}
            title={ft.title}
            exact
            to={ft.path}
            onClick={() => {
              if (store.ui.sideMenuActive && location.pathname === ft.path) {
                store.ui.setSideMenuActive(false)
              } else {
                store.ui.setSideMenuActive(true)
              }
            }}
          >
            <ft.icon />
          </NavLink>
        })}
      </div>
      <div className={styles.mpanel}>
        <div className={styles.panelHeader}>
          <div className={styles.headerTitle}>
            <Switch>
              {props.features.map((ft) => {
                return <Route key={`panel-header-${ft.name}`} exact path={ft.path} render={() => {
                  return <span className={styles.headerLabel}>{ft.title}</span>
                }} />
              })}
            </Switch>
          </div>

          <div className={styles.collapseIcon} onClick={onClick}>
            <ArrowLeftOutlined />
          </div>
        </div>

        <div className={styles.panelBody}>
          <Suspense fallback={<div>Загрузка...</div>}>
            <Switch>
              {props.features.map((ft) => {
                return <Route
                  key={`panel-route-${ft.name}`}
                  exact
                  path={ft.path}
                  component={ft.control}
                />
              })}
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>

    {/* <div className={styles.controls}>
      {props.features.map((ft) => {
        return <NavLink
          className={styles.linkIcon}
          activeClassName={styles.linkIconActive}
          key={`tab-key-${ft.name}`}
          title={ft.title}
          exact
          to={ft.path}
          onClick={() => {
            if (collapsed) {
              setCollapsed(false)
            }
          }}
        >
          <ft.icon />
        </NavLink>
      })}
    </div>

    <div className={collapsed ? styles.panelCollapsed : styles.panel}>
      <div className={styles.panelHeader}>
        <div className={styles.headerTitle}>
          <Switch>
            {props.features.map((ft) => {
              return <Route key={`panel-header-${ft.name}`} exact path={ft.path} render={() => {
                return <span className={styles.headerLabel}>{ft.title}</span>
              }} />
            })}
          </Switch>
        </div>

        <div className={styles.collapseIcon} onClick={onClick}>
          <ArrowLeftOutlined />
        </div>
      </div>

      <div className={styles.panelBody}>
        <Suspense fallback={<div>Загрузка...</div>}>
          <Switch>
            {props.features.map((ft) => {
              return <Route
                key={`panel-route-${ft.name}`}
                exact
                path={ft.path}
                component={ft.control}
              />
            })}
          </Switch>
        </Suspense>
      </div>
    </div> */}
  </>
}

export default withRouter(observer(MobileControlPanel))
