import { LatLng, Point, LatLngExpression, PointExpression, PointTuple } from 'leaflet'


export interface LatLngDir {
  lat: number
  lon: number
  dir?: number
}

interface TLatLng {
  coords: LatLngDir
}

export interface Projector {
  project(latlng: LatLngExpression, zoom?: number): Point;
  unproject(point: PointExpression, zoom?: number): LatLng;
}


export function vertexClusterReduction(projector: Projector, zoom: number, coords: TLatLng[], tol: number = 5): LatLngDir[] {
  const len = coords.length
  if (len === 0) {
    return []
  }

  const stol = tol * tol
  const decimated: LatLngDir[] = [{
    lat: coords[0].coords.lat,
    lon: coords[0].coords.lon,
    dir: coords[0].coords.dir,
  }]

  let p1 = projector.project([coords[0].coords.lat, coords[0].coords.lon], zoom)
  let p2: Point | null = null

  for (let i = 1; i < len - 1; i++) {
    p2 = projector.project([coords[i].coords.lat, coords[i].coords.lon], zoom)
    if (squaredDistance(p1, p2) < stol) {
      continue
    }

    decimated.push({
      lat: coords[i].coords.lat,
      lon: coords[i].coords.lon,
      dir: coords[i].coords.dir,
    })
    p1 = p2
  }
  return decimated
}

export function vertexClusterReductionSimple(projector: Projector, zoom: number, coords: TLatLng[], tol: number = 5): LatLngDir[] {
  if (coords == null) {
    return []
  }

  const len = coords.length
  if (len === 0) {
    return []
  }

  // const stol = tol * tol
  const decimated: LatLngDir[] = [{
    lat: coords[0].coords.lat,
    lon: coords[0].coords.lon,
    dir: coords[0].coords.dir,
  }]

  if (len === 1) {
    return decimated
  }

  let p1 = projector.project([coords[0].coords.lat, coords[0].coords.lon], zoom)
  let p2: Point | null = null
  const last = len - 1
  for (let i = 1; i < last - 1; i++) {
    p2 = projector.project([coords[i].coords.lat, coords[i].coords.lon], zoom)
    if ((p1.x + tol) > p2.x && (p1.x - tol) < p2.x && (p1.y + tol) > p2.y && (p1.y - tol) < p2.y) {
      continue
    }

    decimated.push({
      lat: coords[i].coords.lat,
      lon: coords[i].coords.lon,
      dir: coords[i].coords.dir,
    })
    p1 = p2
  }

  decimated.push({
    lat: coords[last].coords.lat,
    lon: coords[last].coords.lon,
    dir: coords[last].coords.dir,
  })
  return decimated
}

/**
 * Difference of two points
 */
export const pointDistance = (p1: Point, p2: Point): PointTuple => {
  return [p1.x - p2.x, p1.y - p2.y]
}

const vectorLength = (p1: PointTuple, p2: PointTuple): number => {
  return p1[0] * p2[0] + p1[1] * p2[1]
}

const vectorLengthSquared = (p: PointTuple) => vectorLength(p, p)

const squaredDistance = (p1: Point, p2: Point) => vectorLengthSquared(pointDistance(p1, p2))
