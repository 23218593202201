import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

import { getReadableDuration } from '../common'

export const ShiftHelperCard = ({ shift, asTableRow }) => {
  const start = Moment(shift.timeBegin)
  const end = Moment(shift.timeEnd)
  const delta = Moment.duration(end.diff(start))

  if (asTableRow) {
    return (
      <tr>
        <td>{shift.shiftType === 9 ? 'Итоговая смена' : 'Смена'}</td>

        <td>
          {start.format('Do MMMM YYYY, HH:mm:ss')}
        </td>

        <td>
          {end.format('Do MMMM YYYY, HH:mm:ss')}
        </td>

        <td>
          {getReadableDuration(delta)}
        </td>

        {shift.properties.map((prop, key) => {
          return (
            <td key={key}>{prop.sValue}</td>
          )
        })}
      </tr>
    )
  }

  return (
    <div>
      <h3>{shift.shiftType === 9 ? 'Итоговая смена' : `Смена ${shift.shiftType}`}</h3>

      <div>
        {'Начало: ' + start.format('Do MMMM YYYY, HH:mm:ss')}
      </div>

      <div>
        {'Конец: ' + end.format('Do MMMM YYYY, HH:mm:ss')}
      </div>

      <div>
        {'Продолжительность: ' + getReadableDuration(delta)}
      </div>

      {shift.properties.map((prop, key) => {
        return (
          <div key={key}>{prop.name + ' ' + prop.sValue}</div>
        )
      })}
    </div>
  )
}

ShiftHelperCard.propTypes = {
  shift: PropTypes.shape({
    shiftType: PropTypes.number.isRequired,
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      sValue: PropTypes.shape.isRequired,
    })).isRequired,
  }),
  asTableRow: PropTypes.bool,
}
