import React from 'react'
import { observer } from 'mobx-react'
import { LatLngBounds } from 'leaflet'
import { useLeaflet } from 'react-leaflet'
import { toJS } from 'mobx'


import { RotatedMarker } from '../RotatedMarker'
import { arrowDivIcon } from '../../icons'
import { useStore } from '../../stores/rootStore'
import { getArrowsBounded } from '../../tools/trackUtils'


const equals = (arr1, arr2) => {
  if (arr1 == null || arr2 == null) {
    return false
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

// TODO: Use webworker for heavy calculations
const useArrows = (delay) => {
  const store = useStore()
  const { map } = useLeaflet()

  const [arrows, setArrows] = React.useState([])
  const [zoom, setZoom] = React.useState(-1)
  const [mbounds, setBoudns] = React.useState([180, 90, -180, -90])
  // const firstDebounce = React.useRef(true);

  React.useEffect(() => {
    if (store.track.trackLine.length === 0) {
      setArrows([])
    }

    if (zoom !== store.map.currentZoom || !equals(store.map.currentBounds, mbounds)) {
      const handler = setTimeout(() => {
        const bounds = new LatLngBounds(
          [store.map.currentBounds[0], store.map.currentBounds[1]],
          [store.map.currentBounds[2], store.map.currentBounds[3]]
        )

        setArrows(getArrowsBounded(map, store.map.currentZoom, bounds, store.track.trackLine))
        setZoom(toJS(store.map.currentZoom))
        setBoudns(toJS(store.map.currentBounds))
      }, delay);

      return () => clearTimeout(handler);
    }
  }, [delay, map, mbounds, store.map.currentBounds, store.map.currentZoom, store.track.trackLine, zoom])

  return arrows
}

const Arrows = (props) => {
  // const store = useStore()
  // const { map } = useLeaflet()

  // const [arrows, setArrows] = React.useState([])
  // const [zoom, setZoom] = React.useState(-1)
  // const [mbounds, setBoudns] = React.useState([180, 90, -180, -90])

  // // FIXME use delayed effect
  // React.useEffect(() => {
  //   if (zoom !== store.map.currentZoom || !equals(store.map.currentBounds, mbounds)) {
  //     const bounds = new LatLngBounds(
  //       [store.map.currentBounds[0], store.map.currentBounds[1]],
  //       [store.map.currentBounds[2], store.map.currentBounds[3]]
  //     )

  //     setArrows(getArrowsBounded(map, store.map.currentZoom, bounds, store.track.trackLine))
  //     setZoom(toJS(store.map.currentZoom))
  //     setBoudns(toJS(store.map.currentBounds))
  //   }
  // }, [map, mbounds, store.map.currentBounds, store.map.currentZoom, store.track.trackLine, zoom])

  const arrows = useArrows(100)

  // if (map == null || arrows.length === 0) {
  //   return null
  // }

  // const bounds = new LatLngBounds(
  //   [store.map.currentBounds[0], store.map.currentBounds[1]],
  //   [store.map.currentBounds[2], store.map.currentBounds[3]]
  // )
  // const arrows = getArrowsBounded(map, store.map.currentZoom, bounds, store.track.trackLine)

  return <>
    {arrows.map((arw, key) => {
      return <RotatedMarker
        key={`arw-${key}`}
        position={[arw.lat, arw.lon]}
        icon={arrowDivIcon(16, 'blue', 1, 'blue', 6, false)}
        rotationAngle={arw.dir}
      />
    })}
  </>
}

export default observer(Arrows)