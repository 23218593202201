import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'

export const getReadableTimeFormat = (timestamp, formatString) => {
  if (typeof timestamp === 'string') {
    timestamp = parseISO(timestamp)
  }

  if (timestamp < 1000000000000) {
    timestamp = timestamp * 1000 // FIXME rude
  }
  return format(timestamp, formatString, { locale: ru })
}

export const getReadableTime = (timestamp) => {
  // return getReadableTimeFormat(timestamp, 'do MMMM yyyy, HH:mm:ss')
  return getReadableTimeFormat(timestamp, 'dd.MM.yyyy, HH:mm:ss')
}
