const earthRadius = 6371000 // Earth radius in meters
const earthRadiusKm = earthRadius / 1000 // Earth radius in kilometers
const HZ = 0.01745329251994329577

const earthRadiusR = toRadians(earthRadiusKm)

const trackDistance = (f) => (track) => {
  const l = track.length
  let ttl = 0
  for (let i = 0; i < l - 1; i++) {
    const point1 = track[i].coords
    const point2 = track[i + 1].coords
    ttl += f(point1, point2)
  }
  return Math.round(ttl)
}

export const rawTrackDistance = trackDistance(distanceFrom)
export const accurateTrackDistance = trackDistance(accurateDistanceFrom)

/**
 * Calculates redians from degrees
 * @param {number} degree
 * @return {number} in radians
 */
function toRadians(degree) {
  return degree * (Math.PI / 180)
}

/**
 * Calculates distance beween two points
 * @param {array} point1 first lat-lon point array
 * @param {array} point2 second lat-lon point array
 * @return {number} distance between point in kilometers
 */
export function distanceFrom(point1, point2) {
  if (!point1 || !point1.lat || !point1.lon) return 0 // throw new Error('Invalid first point')
  if (!point2 || !point2.lat || !point2.lon) return 0 // throw new Error('Invalid second point')

  const { lat: lat1, lon: lon1 } = point1
  const { lat: lat2, lon: lon2 } = point2

  let pxl = (lon1 - lon2) * earthRadiusR * Math.cos(lat1 * HZ)
  pxl = pxl * pxl

  let pyl = (lat1 - lat2) * earthRadiusR
  pyl = pyl * pyl

  const r = pxl + pyl

  return Math.sqrt(r)
}

/**
 * Accurately calculates distance beween two points
 * @param {array} point1 first lat-lon point array
 * @param {array} point2 second lat-lon point array
 * @return {number} distance between point in kilometers
 */
export function accurateDistanceFrom(point1, point2) {
  if (!point1 || point1.length !== 2) return 0 // throw new Error('Invalid first point')
  if (!point2 || point2.length !== 2) return 0 // throw new Error('Invalid second point')

  const { 0: lat1, 1: lon1 } = point1
  const { 0: lat2, 1: lon2 } = point2

  const dLat = toRadians(lat2 - lat1)
  const dLng = toRadians(lon2 - lon1)

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
       Math.sin(dLng / 2) * Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return (earthRadiusKm * c)
}

