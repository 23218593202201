import React from 'react'


import MapStore from './mapStore'
import TrackStore from './trackStore'
import UIStore from './uiStore'
import ProfileStore from './profileStore'
import GeozonesStore from './geozonesStore'
import GroupsStore from './groupsStore'
import { IGeozonesStore, IGroupsStore, IMapStore, IProfileStore, ITrackStore, IUIStore } from './interfaces'


export class RootStore {
  ui: IUIStore
  profile: IProfileStore
  map: IMapStore
  track: ITrackStore
  geo: IGeozonesStore
  groups: IGroupsStore

  constructor() {
    this.ui = new UIStore()
    this.profile = new ProfileStore(this)
    this.map = new MapStore(this)
    this.track = new TrackStore(this)
    this.geo = new GeozonesStore(this)
    this.groups = new GroupsStore(this)
  }
}

export const StoreContext = React.createContext<RootStore | null>(null)

export const useStore = () => {
  const store = React.useContext(StoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return store
}


// export default new RootStore()
