import L from 'leaflet'

// ${strokeColor}
export const lowBatteryDivIcon = (size, fillColor = '#ffbf00', strokeColor = '#000') => {
  return new L.DivIcon({
    html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
    <path fill="${strokeColor}" d="m202.5 33.64h-28.14c-14.07 0-14.07 21.15-14.\
49 21.15v6.993h-25.16s-30.7 0-30.7 29.62v222.2c0 29.38 30.7 29.62 30.7 29.\
62h107.4s30.7 0 30.7-29.62v-222.2c0-29.62-30.7-29.62-30.7-29.62h-25.58v-6.\
993s0-21.15-14.07-21.15zm29.7 56.29s12.51 0 12.51 13.24v198.7c0 13.24-12.51 13.\
24-12.51 13.24h-87.55s-12.51 0-12.51-13.24v-198.7c0-13.65 12.51-13.24 12.51-13.\
24z" stroke-width=".3909"/>
    <rect transform="rotate(45)" x="259.6" y="-171.2" width="35.13"
      height="359.2" ry="17.56" fill="#b40000"/>
  </svg>`,
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    className: '',
  })
}
