import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Map, TileLayer, MapComponent } from 'react-leaflet'
import { DomEvent } from 'leaflet'
import Spin from 'antd/es/spin'
import { UpOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import Moment from 'moment'


import { ClusterLayer, ControlList } from '../../components'
import api from '../../tools/api'
// import { Websocket } from '../../tools/wamp'


import './sharedLinksPage.css'


// TODO fix this component for maps v2 api

const calcBbox = (map) => {
  const bounds = map.getBounds()
  return [
    bounds.getWest(),
    bounds.getSouth(),
    bounds.getEast(),
    bounds.getNorth(),
  ]
}

/**
 * Parses shared link errors and outputs readable error string
 * @param {object} err inputed error
 * @return {string}
 */
const parseLinkError = (err) => {
  // TODO handle error
  let errStr = `${err}`
  if (err && err.name === 'NetworkError') {
    switch (err.status) {
      case 404: errStr = 'Временная ссылка не найдена'; break
      default: errStr = `Неизвестная сетевая ошибка. ${err}`
    }
  }
  return errStr
}

/**
 * TopBar for shared links
 */
class TopBar extends MapComponent {
  /**
   * @return {object}
   */
  render() {
    return (
      <div className='top-bar'>
        {this.props.children}
      </div>
    )
  }
}

/**
 * LeftBar for shared links
 */
class LeftBar extends MapComponent {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.state = {
      collapsed: this.props.mobile,
    }

    this.set = this.set.bind(this)
    this.collapseHandler = this.collapseHandler.bind(this)
  }

  /**
   * @param {string} name
   * @param {string} val
   */
  set(name, val) {
    this.setState((prevState) => ({ ...prevState, [name]: val }))
  }

  /**
   * Handles collapse event
   */
  collapseHandler() {
    this.set('collapsed', !this.state.collapsed)
  }

  /**
   * Std on mount hook
   */
  componentDidMount() {
    DomEvent
      .disableClickPropagation(this.container)
      .disableScrollPropagation(this.container)
  }

  /**
   * @return {object}
   */
  render() {
    return (
      <div className='left-bar' ref={(el) => { this.container = el }}>
        <div className='resizer' onClick={this.collapseHandler}>
          {this.state.collapsed ?
            <UpOutlined style={{ fontSize: '24px' }} /> :
            <DownOutlined style={{ fontSize: '24px' }} />
          }
        </div>
        <h3>{`Список устройств`}</h3>
        <div className={`scroll-wrapper${this.state.collapsed ? ' collapsed' : ''}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

/**
 * SharedLinksPage container
 */
class _SharedLinksPage extends Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: false,
      errorString: '',
      info: undefined,
      sid: undefined,
      ws: undefined,
    }

    this.map = props.rootStore.map
    this.showDepthLayer = false
    this.minZoom = 4
    this.maxZoom = 18
    this.wmTiles = 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
    this.mapRef = React.createRef()

    this.set = this.set.bind(this)
    this.handleMapLoadEnd = this.handleMapLoadEnd.bind(this)
    this.handleMoveend = this.handleMoveend.bind(this)
    this.handleZoomend = this.handleZoomend.bind(this)
    this.setMapRef = this.setMapRef.bind(this)
    this.onFind = this.onFind.bind(this)
  }

  /**
   * @param {string} key
   * @param {any} val
   */
  set(key, val) {
    this.setState((prevState) => ({
      ...prevState,
      [key]: val,
    }))
  }

  /**
   * @param {object} event
   */
  handleMapLoadEnd(event) {
    this.map.currentBounds = calcBbox(event.target)
  }

  /**
   * @param {object} event
   */
  handleMoveend(event) {
    this.map.setMapCenter(event.target.getCenter())
    this.map.currentBounds = calcBbox(event.target)
    // this.map.stopTracking()
  }

  /**
   * @param {object} event
   */
  handleZoomend(event) {
    this.map.setZoom(event.target.getZoom())
    this.map.currentBounds = calcBbox(event.target)
  }

  /**
   * Std lc hook
   */
  setMapRef() {
    this.props.rootStore.ui.mapState.ref = this.mapRef
  }

  /**
   * onFind callback
   * @param {string} id
   */
  onFind(id) {
    if (id === this.map.selected.id) {
      this.map.deselect()
    } else {
      this.map.findDevice(id)
    }
  }


  /**
   * LC after mount hook
   */
  componentDidMount() {
    const token = this.props.match.params.token
    if (!token && token.length === 0) {
      this.set('error', true)
      return
    }

    api.sharedInfo(token)
      .then((info) => {
        this.set('info', info.info)
        this.set('sid', info.session)

        // this.props.rootStore.map.startLoadingMessage()

        const devs = {}
        info.info.devices.forEach((device) => {
          devs[device.key] = device
        })

        this.map.setDevices(devs)

        const devices = []
        Object.keys(this.map.devices).forEach((key) => {
          const device = this.map.devices[key]
          devices.push({
            id: key,
            imei: device.imei,
            title: device.params.title,
            fullTitle: device.params.fullTitle,
            ref: React.createRef(),
          })
        })

        this.set('initialDevices', devices)
        this.set('devices', devices)

        // const ws = new Websocket({
        //   uid: token,
        //   sid: info.session,
        //   url: `/api/v1/ws/${token}/`,
        //   onPointsReceived: this.map.addPoints,
        //   onConnect: () => {
        //     this.props.rootStore.map.stopLoadingMessage()
        //     this.props.rootStore.map.showStartMessage()
        //   },
        // })
        // this.set('ws', ws)
      })
      .catch((error) => {
        this.set('error', true)
        this.set('errorString', parseLinkError(error))
      })
      .finally(() => {
        this.set('loading', false)
        this.props.rootStore.ui.mapState.ref = this.mapRef
        this.props.rootStore.ui.mapState.controlBox.collapsed = true
      })
  }

  /**
   * @return {object}
   */
  render() {
    if (this.state.loading) {
      const spinIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />
      return <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <Spin indicator={spinIcon} />
      </div>
    }

    if (this.state.error) {
      return (
        <div style={{ padding: '15px', textAlign: 'center', fontSize: '1.4em' }}>
          <h3>{`Ошибка`}</h3>
          <div>{this.state.errorString}</div>
          <div><Link to='/maps/login'>На страницу входа</Link></div>
        </div>
      )
    }

    const { companyName, expires } = this.state.info

    const selectedID = this.map.selected.id

    if (selectedID && selectedID !== this.map.selected.prev &&
      this.map.selected.outside) {
      setTimeout(() => {
        const dev = this.state.devices.find((d) => d.id === selectedID)

        if (dev !== undefined && dev !== null) {
          dev.ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    }

    return (
      <div>
        <Map
          className="leaflet-container"
          ref={this.mapRef}
          center={this.map.currentMapCenter}
          zoom={this.map.currentZoom}
          minZoom={this.minZoom}
          maxZoom={this.maxZoom}
          onMoveend={this.handleMoveend}
          onZoomend={this.handleZoomend}
          whenReady={this.handleMapLoadEnd}
        >

          <TileLayer
            url={this.wmTiles}
            attribution='&copy; <a target="_blank"
href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {this.props.rootStore.ui.mapState.navigation.visible &&
            <ClusterLayer
              devices={this.map.devices}
              points={this.map.drawPoints}
              clusters={this.map.clusters}
              index={this.map.index}
              map={this.map}
            />}

          <TopBar>
            <div>
              Ссылка предоставлена компанией {companyName}
            </div>
            <div>
              Действительна до {Moment(expires).toDate()}
            </div>
          </TopBar>

          <LeftBar mobile={this.props.rootStore.ui.window.mobile}>
            <ControlList
              loading={this.state.loading}
              empty={'Пусто'}
              items={this.state.devices}
              selected={selectedID}
              onFind={this.onFind}
              statuses={this.map.statuses}
            />
          </LeftBar>
        </Map>
      </div>
    )
  }
}

_SharedLinksPage.propTypes = {
  match: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
}

_SharedLinksPage.defaultProps = {}

export const SharedLinksPage = inject('rootStore')(withRouter(observer(_SharedLinksPage)))
