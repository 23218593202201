import { makeAutoObservable } from 'mobx'
import { isMobile } from 'react-device-detect'


import { isMobileDevice } from '../common'
import { AppWindow, MapState } from './interfaces'


const filterForm = 'filterFormCollapsed'

const version = process.env.REACT_APP_VERSION


// TODO refactor it


/**
 * Class implements storage for ui state manage
 */
class UIStore {
  version?: string
  leftPaneWidth: number
  bottomPaneHeight: number
  window: AppWindow
  mapState: MapState
  sideMenuActive: boolean

  constructor() {
    // Don't forget bump version!
    this.version = version

    this.leftPaneWidth = 300
    this.bottomPaneHeight = 300

    this.sideMenuActive = !isMobile

    const filterFormSaved = window.localStorage.getItem(filterForm)
    let ff = false
    if (filterFormSaved) {
      ff = filterFormSaved === 'true'
    }

    this.window = {
      mobile: isMobileDevice(),
      size: {
        width: 0,
        height: 0,
      },
    }

    this.mapState = {
      ref: null,
      size: {
        height: 0,
        width: 0,
      },
      controlBox: {
        collapsed: isMobileDevice(),
      },
      deviceTrack: {
        visible: false,
        selected: {
          type: null,
          index: null,
        },
      },
      activeTab: 'navigation',
      trackInfo: {
        allow: false,
        visible: false,
      },
      navigation: {
        visible: true,
        filterForm: ff,
      },
    }

    makeAutoObservable(this, {}, { autoBind: true })
  }

  setSize(width: number, height: number) {
    this.window.size.width = width
    this.window.size.height = height
  }

  setSideMenuActive(state: boolean) {
    this.sideMenuActive = state
  }

  /**
   * Toggles bottom pane state and recalc map height
   */
  toggleLeftPane() {
    const closed = this.mapState.controlBox.collapsed

    this.mapState.controlBox.collapsed = !closed
    if (closed) {
      this.mapState.size.width = this.mapState.size.width - this.leftPaneWidth
    } else {
      this.mapState.size.width = this.window.size.width
    }
  }

  /**
   * open left pane state and recalc map height
   */
  openLeftPane() {
    if (this.mapState.controlBox.collapsed) {
      this.toggleLeftPane()
    }
  }

  /**
   * Toggles bottom pane state and recalc map height
   */
  toggleBottomPane() {
    const open = this.mapState.trackInfo.visible

    this.mapState.trackInfo.visible = !open
    if (!open) {
      this.mapState.size.height = this.mapState.size.height -
        this.bottomPaneHeight
    } else {
      this.mapState.size.height = this.window.size.height
    }
  }

  /**
   * Only opens bottom pannel
   */
  openBottomPane() {
    if (!this.mapState.trackInfo.visible) {
      this.toggleBottomPane()
    }
  }

  /**
   * Toggle stop helpers visibility
   */
  toggleObjects() {
    this.mapState.navigation.visible = !this.mapState.navigation.visible
  }

  /**
   * Toggles filter form
   */
  toggleFilterForm() {
    const newState = !this.mapState.navigation.filterForm
    this.mapState.navigation.filterForm = newState
    window.localStorage.setItem(filterForm, '' + newState)
  }

  setVisible(entity: string, state: boolean) {
    switch (entity) {
      case 'navigation':
        this.mapState.navigation.visible = state
        break

      case 'trackInfo':
        this.mapState.trackInfo.visible = state
        break

      default:
    }
  }

  setActiveTab(tab: string) {
    this.mapState.activeTab = tab
  }
}

export default UIStore
