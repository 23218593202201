/**
 * @param {number} allowed
 * @param {number} num
 * @return {boolean}
 */
export function isWarningAllowed(allowed, num) {
  const mask = 1 << num
  return (allowed & mask) !== 0
}

/**
 * @param {number} allowed
 * @param {number} num
 * @return {number}
 */
export function setAllowed(allowed, num) {
  const mask = 1 << num
  allowed |= mask
  return allowed
}

