import React from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'

/**
 * Handles browser inner width and height size
 */
class SizeHandler extends React.Component {
  /**
   * Basic constructor
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.setSize = () => {
      const width = window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

      const height = window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

      props.rootStore.ui.setSize(width, height)
    }

    this.setSize()
  }

  /**
   * Std hook
   */
  componentDidMount() {
    window.addEventListener('resize', this.setSize)
  }

  /**
   * Std hook
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize)
  }

  /**
   * Std render
   * @return {object}
   */
  render() {
    return null
  }
}

SizeHandler.propTypes = {
  rootStore: PropTypes.object.isRequired,
}

export const WindowSizeHandler = inject('rootStore')(observer(SizeHandler))
WindowSizeHandler.displayName = 'WindowSizeHandler'
