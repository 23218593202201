import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import Tabs from 'antd/es/tabs'
import { MultiGrid } from 'react-virtualized'
import { parseJSON } from 'date-fns'
import { withLeaflet } from 'react-leaflet'
// import { LatLngBounds, Point } from 'leaflet'


import { getReadableDuration, getReadableTime } from '../common'
import { MapSidePanel } from '../components'
// import { FilterTab } from '../components/FilterTab'

// FIXME remake components, use react-window
// Split to more simple functional components


import 'react-virtualized/styles.css'


const TabPane = Tabs.TabPane

const cursor = { cursor: 'pointer' }

const cardStyle = {
  ...cursor,
  padding: '8px',
  border: '1px solid #c3c3c3',
  margin: '8px',
  fontSize: '1.1em',
}

const entryStyle = {
  paddingTop: '4px',
  paddingBottom: '4px',
}

const evenEntry = {
  ...entryStyle,
  backgroundColor: '#ffffff',
}

const oddEntry = {
  ...entryStyle,
  backgroundColor: '#eeeeee',
}

const selectedEntry = {
  boxShadow: '0 1px 2px rgba(0, 0, 0, .35)',
}

const isEven = (n) => {
  return n % 2 === 0
}

/**
 * Track helpers table
 */
class _TrackHelpersPanel extends React.Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '1',
      width: 0,
      height: 0,
    }

    this.tableOffset = 100 // 86
    this.colWidth = 200
    this.rowHeight = 38

    this.onStopClick = this.onStopClick.bind(this)
    this.onShiftClick = this.onShiftClick.bind(this)
    this.onWarnClick = this.onWarnClick.bind(this)
    this.getTableHeight = this.getTableHeight.bind(this)
    this.getColWidth = this.getColWidth.bind(this)
    this.setSize = this.setSize.bind(this)
    this.changeTab = this.changeTab.bind(this)

    this.stopsGrid = this.stopsGrid.bind(this)
    this.warningsGrid = this.warningsGrid.bind(this)
  }

  /**
   * @param {number} index
   * @param {bool} close
   */
  onStopClick(index, close = false) {
    this.props.rootStore.track.setStop(index)

    // TODO check borders
    // const stop = this.props.rootStore.track.trackHelpers.stops[index]

    // const bounds = [stop.coords.lat, stop.coords.lon, stop.coords.lat, stop.coords.lon]
    // // { x: 100, y: 500 }
    // const options = {
    //   paddingTopLeft: new Point(400, 100),
    //   paddingBottomRight: new Point(50, 500),
    // }

    // const corners = new LatLngBounds({ lat: bounds[0], lng: bounds[1] }, { lat: bounds[2], lng: bounds[3] })
    // this.props.leaflet.map.fitBounds(corners, options)
    // // this.props.rootStore.map.fitBounds(bounds, { x: 100, y: 500 })
    // TODO: find there?
    // close ?
    //   this.props.rootStore.map.unsetSelectedHelper() :
    //   this.props.rootStore.map.setSelectedHelper(0, index)
  }

  /**
   * @param {number} index
   * @param {bool} close
   */
  onShiftClick(index, close = false) {
    const shiftIndex = close ? null : index
    this.props.rootStore.track.setShift(shiftIndex)
  }

  /**
   * @param {number} index
   * @param {bool} close
   */
  onWarnClick(index, close = false) {
    this.props.rootStore.track.setWarning(index)

    // close ?
    //   this.props.rootStore.map.unsetSelectedHelper() :
    //   this.props.rootStore.map.setSelectedHelper(1, index)
  }

  /**
   * Returns current actual table height
   * @param {number} rows
   * @return {number}
   */
  getTableHeight(rows) {
    const stdTableHeight = rows * this.rowHeight + 42
    const y = this.state.height - this.tableOffset
    return stdTableHeight < y ? stdTableHeight : y
  }

  /**
   * Returns current actual row width
   * @param {number} cols
   * @param {number} width
   * @return {number}
   */
  getColWidth(cols, width) {
    const tableWidth = cols * this.colWidth + 4
    return tableWidth < width ? width / cols - 4 : this.colWidth
  }

  /**
   * @param {number} width
   * @param {number} height
   */
  setSize(width, height) {
    this.setState((ps) => ({
      ...ps,
      width: width,
      height: height,
    }))
  }

  /**
   * @param {number} active
   */
  changeTab(active) {
    if (active === 5) {
      return
    }

    this.setState((ps) => ({
      ...ps,
      activeTab: active,
    }))
  }

  /**
   * Compiles grid for stops
   * @param {Object} stops
   * @return {Object}
   */
  stopsGrid(stops) {
    const header = ['Начало', 'Конец', 'Продолжительность']

    const stop = stops[0]
    if (stop.properties) {
      stop.properties.forEach((prop) => {
        header.push(prop.name)
      })
    }

    const gridRows = [header]

    stops.forEach((stop) => {
      const start = parseJSON(stop.timeBegin)
      const end = parseJSON(stop.timeEnd)
      const delta = end - start
      const duration = getReadableDuration(delta)

      const row = [
        getReadableTime(start),
        getReadableTime(end),
        duration,
      ]

      if (stop.properties) {
        stop.properties.forEach((prop) => {
          row.push(prop.sValue)
        })
      }

      gridRows.push(row)
    })

    return gridRows
  }

  /**
   * Compile grid for shifts
   * @param {Object} shifts
   * @return {Object}
   */
  shiftsGrid(shifts) {
    const units = ' км'
    const header = ['Тип смены', 'Начало', 'Конец', 'Продолжительность', 'Пробег']

    const shift = shifts[0]
    if (shift.properties) {
      shift.properties.forEach((prop) => {
        header.push(prop.name)
      })
    }

    const gridRows = [header]
    const oneShift = shifts.length === 2 && shifts[1].shiftType === 9

    shifts.forEach((shift) => {
      if (oneShift && shift.shiftType !== 9) return
      const start = parseJSON(shift.timeBegin)
      const end = parseJSON(shift.timeEnd)
      const delta = end - start
      const duration = getReadableDuration(delta)
      const distance = shift.distance + units

      const row = [
        shift.shiftType === 9 ? 'Итоговая смена' : `Смена ${shift.shiftType}`,
        getReadableTime(start),
        getReadableTime(end),
        duration,
        distance,
      ]

      if (shift.properties) {
        shift.properties.forEach((prop) => {
          row.push(prop.sValue)
        })
      }

      gridRows.push(row)
    })

    return gridRows
  }

  /**
   * Compile grid for warnings
   * @param {Object} warnings
   * @return {Array}
   */
  warningsGrid(warnings) {
    const gridHeader = ['Начало', 'Конец', 'Продолжительность', 'Тип']
    const gridRows = [gridHeader]

    warnings.forEach((warning) => {
      const start = parseJSON(warning.timeBegin)
      const end = parseJSON(warning.timeEnd)
      const delta = end - start
      const duration = getReadableDuration(delta)

      gridRows.push([
        getReadableTime(start),
        getReadableTime(end),
        duration,
        warning.warningMessage,
      ])
    })

    return gridRows
  }

  /**
   * @return {Object}
   */
  render() {
    // const selectedType = this.props.rootStore.map.track.selected.type
    // const selectedIndex = this.props.rootStore.map.track.selected.index

    // const line = this.props.rootStore.map.trackLine

    const hasStops = this.props.rootStore.track.trackHelpers.stops.length > 0
    const hasWarns = this.props.rootStore.track.trackHelpers.warnings.length > 0
    const hasShifts = this.props.rootStore.track.trackHelpers.shifts.length > 0
    // const hasCheckpoints = helpers.checkpoints && helpers.checkpoints.length !== 0

    const minWidth = 768
    const thinner = this.props.rootStore.ui.window.size.width < minWidth
    const minimum = thinner || this.props.rootStore.ui.window.mobile

    const maximum = this.props.rootStore.ui.mapState.controlBox.collapsed ?
      this.props.rootStore.ui.window.size.width - 43 :
      this.props.rootStore.ui.window.size.width - 368

    const width = minimum ? this.props.rootStore.ui.window.size.width : maximum

    const height = this.props.rootStore.ui.window.size.height / 2
    const y = height - this.tableOffset

    const tableWidth = width - 16

    // const shiftSelected = this.props.rootStore.map.track.shiftSelected != null

    // TODO subscribing for selected device?

    if (this.props.rootStore.track.data.length === 0) {
      return null
    }

    return (
      <div ref={(el) => { this.rootElement = el }}>
        <MapSidePanel caption='Отчет по треку' width={width} onToggle={(visible) => {
          this.props.rootStore.ui.mapState.trackInfo.visible = visible
        }}>
          <Tabs activeKey={this.state.activeTab} style={{ height: '100%' }} onChange={this.changeTab}>

            {hasShifts && <TabPane tab="Смены" key="1">
              <HelpersTable
                gridRows={this.shiftsGrid(this.props.rootStore.track.trackHelpers.shifts)}
                width={tableWidth}
                colWidth={this.getColWidth(this.props.rootStore.track.trackHelpers.shifts[0].length, tableWidth)}
                height={y}
                rowHeight={this.rowHeight}
                selected={this.props.rootStore.track.selectedShift}
                onRowClick={this.onShiftClick}
              />
            </TabPane>}

            {hasStops && <TabPane tab="Остановки" key="2">
              <HelpersTable
                gridRows={this.stopsGrid(this.props.rootStore.track.trackHelpers.stops)}
                width={tableWidth}
                colWidth={this.getColWidth(this.props.rootStore.track.trackHelpers.stops[0].length, tableWidth)}
                height={y}
                rowHeight={this.rowHeight}
                // selected={selectedType === 0 ? selectedIndex : -99}
                selected={this.props.rootStore.track.selectedStop}
                onRowClick={this.onStopClick}
              />
            </TabPane>}

            {hasWarns &&<TabPane tab="Предупреждения" key="3" >
               <HelpersTable
                gridRows={this.warningsGrid(this.props.rootStore.track.trackHelpers.warnings)}
                width={tableWidth}
                colWidth={this.getColWidth(this.props.rootStore.track.trackHelpers.warnings[0].length, tableWidth)}
                height={y}
                rowHeight={this.rowHeight}
                // selected={selectedType === 1 ? selectedIndex : -99}
                selected={this.props.rootStore.track.selectedWarning}
                onRowClick={this.onWarnClick}
              />
            </TabPane>}

            {/* TODO geozone helper from server */}
            {/* {false && hasCheckpoints && <TabPane tab="Контрольные точки" key="4">Checkpoints</TabPane>} */}
          </Tabs>
        </MapSidePanel>
      </div>
    )
  }
}

_TrackHelpersPanel.propTypes = {
  rootStore: PropTypes.object.isRequired,
}

export const TrackHelpersPanel = inject('rootStore')(withLeaflet(observer(_TrackHelpersPanel)))

/**
 * Helpers Table
 */
class HelpersTable extends React.PureComponent {
  /**
   * Force redraw grid
   */
  redraw() {
    if (this.mgref) {
      this.mgref.recomputeGridSize()
      this.mgref.forceUpdateGrids()
    }
  }

  /**
   * After mount hook
   */
  componentDidMount() {
    this.redraw()
  }

  /**
   * After update
   */
  componentDidUpdate() {
    this.redraw()
  }

  /**
   * @return {Object}
   */
  render() {
    const thin = this.props.width < 768
    const captions = this.props.gridRows[0]

    // TODO selected ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' })

    if (thin) {
      return (
        <div style={{ height: this.props.height, overflowY: 'auto' }}>
          {this.props.gridRows.map((row, index) => {
            const i = index - 1
            if (i < 0) return null
            return <HelperCard
              key={i}
              captions={captions}
              data={row}
              selected={this.props.selected === i}
              onClick={() => {
                this.props.onRowClick(i, this.props.selected === i)
              }}
            />
          })}
        </div>
      )
    }

    const cols = this.props.gridRows[0].length
    const rows = this.props.gridRows.length
    const stdTableHeight = rows * this.props.rowHeight + 36
    const theight = stdTableHeight < this.props.height ? stdTableHeight : this.props.height

    const getRowHeight = ({ index }) => {
      let h = this.props.rowHeight
      let max = 0
      this.props.gridRows[index].forEach((col) => {
        if (col.length > max) max = col.length
      })
      if (max > 30) h += h / 2
      if (max > 60) h += h / 2
      return h
    }

    return (
      <MultiGrid
        ref={(el) => { this.mgref = el }}
        width={this.props.width}
        height={theight}
        columnCount={cols}
        columnWidth={this.props.colWidth}
        rowCount={rows}
        rowHeight={getRowHeight}
        // fixedColumnCount={1}
        fixedRowCount={1}
        cellRenderer={({ columnIndex, key, rowIndex, style }) => {
          const idx = rowIndex - 1

          if (idx < 0) {
            return (
              <div key={key} style={style} className={'grid-cell grid-header'}>
                {this.props.gridRows[rowIndex][columnIndex]}
              </div>
            )
          }

          let cn = 'grid-cell'
          if (!isEven(rowIndex)) cn += ' odd-cell'
          if (this.props.selected === idx) cn += ' grid-cell--active'

          return (
            <div
              key={key}
              style={style}
              className={cn}
              onClick={() => {
                this.props.onRowClick(idx, this.props.selected === idx)
                this.redraw()
              }}
            >
              {this.props.gridRows[rowIndex][columnIndex]}
            </div>
          )
        }}
      />
    )
  }
}

HelpersTable.propTypes = {
  gridRows: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  colWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  selected: PropTypes.number,
  onRowClick: PropTypes.func.isRequired,
}

const HelperCard = ({ captions, data, onClick, selected }) => {
  return (
    <div onClick={onClick} style={Object.assign({}, cardStyle, selected ? selectedEntry : {})}>
      {captions.map((cap, idx) => {
        return <div key={idx} style={isEven(idx) ? evenEntry : oddEntry}>{cap}: {data[idx]}</div>
      })}
    </div>
  )
}

HelperCard.propTypes = {
  captions: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

