import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";


import ListItem from './ListItem'
import { useStore } from '../../stores/rootStore'


import styles from './NavigationControl.module.css'


const ControlList = (props) => {
  const store = useStore()

  const listRef = React.createRef()

  React.useEffect(() => {
    // scrollToItem, scrollTo
    if (listRef.current != null && listRef.current.scrollToItem && store.map.selected.id != null) {
      const idx = store.map.devices.findIndex((g) => g.id === store.map.selected.id)
      if (idx !== -1) {
        // export type ScrollToAlign = 'auto' | 'smart' | 'center' | 'start' | 'end';
        listRef.current.scrollToItem(idx, 'smart')
      }
    }
  }, [listRef, store.map.devices, store.map.selected.id])

  return <div className={styles.listWrapper}>
        <AutoSizer>
          {(size) => {
            return <List
              ref={listRef}
              className="List"
              height={size.height}
              itemData={store.map.devices}
              itemCount={store.map.devices.length}
              itemSize={36}
              width={size.width}
            >
              {({ data, index, style }) => {
                if (data[index] == null) {
                  return null
                }

                let extendedStyle = Object.assign({}, style)
                if (store.map.selected.id === data[index].id) {
                  extendedStyle['backgroundColor'] = '#c7f984'
                }

                return <div style={extendedStyle} onClick={() => props.onFind(data[index].id)}>
                  <ListItem item={data[index]} />
                </div>
              }}
            </List>
          }}
        </AutoSizer>
      </div>
}

ControlList.propTypes = {
  loading: PropTypes.bool,
  empty: PropTypes.string,
  // rootStore: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    deviceID: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    imei: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
    fullTitle: PropTypes.string.isRequired,
    }).isRequired,
  })),
  selected: PropTypes.string,
  sortField: PropTypes.string,
  sortAsc: PropTypes.bool,
  onFind: PropTypes.func.isRequired,
}

ControlList.defaultProps = {
  loading: false,
  empty: '',
}

export default observer(ControlList)
