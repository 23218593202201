import React from 'react'
import { observer } from 'mobx-react'
import { Polyline, useLeaflet } from 'react-leaflet'
import { isMobile } from 'react-device-detect'


import { useStore } from '../../stores/rootStore'


const Track = (props) => {
  const store = useStore()
  const { map } = useLeaflet()

  const [currentShift, setCurrentShift] = React.useState(undefined)

  const xShift = isMobile ? -store.ui.window.size.width + (store.ui.window.size.width / 4) : 100
  const yShift = store.ui.window.size.height / 2

  React.useEffect(() => {
    if (store.track.trackLineLeaflet.length > 0 && store.track.selectedShift !== currentShift) {
      setCurrentShift(store.track.selectedShift)

      const bounds = store.track.shiftBounds.length > 0 ? store.track.shiftBounds : store.track.bounds
      store.map.fitBounds(bounds, { x: xShift, y: yShift })
    }

    // const bounds = poly.getBounds()
    // if (bounds.isValid()) {
    //   map.fitBounds(bounds, {
    //     paddingTopLeft: { x: 100, y: 100 },
    //     paddingBottomRight: { x: 50, y: 500 },
    //   })
    // }
  }, [currentShift, map, store.map, store.track.bounds, store.track.selectedShift, store.track.shiftBounds, store.track.trackLineLeaflet, xShift, yShift])

  return <>
    {store.track.trackLineLeaflet.length > 0 && <Polyline
      style={{ zIndex: '1900' }}
      color={'blue'}
      weight={4}
      lineCap={'round'}
      lineJoin={'round'}
      positions={store.track.trackLineLeaflet}
      smoothFactor={0.4}
    // onMouseOver={(event) => {
    //   // const index = line.findIndex(findIndex(event.latlng))
    //   const index = line.findIndex(f(event.latlng))

    //   line.forEach(f(event.latlng))
    //   // const index = line.findIndex(asdf(event.latlng))
    //   if (index !== -1 && map.track.data[index].properties) {
    //     map.setMarker({
    //       position: map.track.data[index].geometry.coordinates,
    //       speed: map.track.data[index].properties.speed,
    //       time: map.track.data[index].properties.time,
    //     })
    //   }
    // }}
    // onMouseOut={() => {map.setMarker({ position: [], speed: 0 })}}
    />}
  </>
}

export default observer(Track)