import React from 'react'
import { PropTypes } from 'prop-types'
import Checkbox from 'antd/es/checkbox'


import { isWarningAllowed } from '../common'


const checksboxes = {
  stops: 30,
  cp: 29,
}

const sensors = {
  reconnect: {
    bits: [0],
    title: 'Переподключение',
  },
  batterylow: {
    bits: [1],
    title: 'Потеря питания',
  },
  lostsignal: {
    bits: [2],
    title: 'Потеря навигации',
  },
  discret: {
    bits: [3, 4],
    title: 'Дискретный датчик',
  },
  fuelup: {
    bits: [8],
    title: 'Заправка',
  },
  fueldown: {
    bits: [9],
    title: 'Слив',
  },
  batteryon: {
    bits: [15],
    title: 'Работа от аккумулятора',
  },
  speed: {
    bits: [16],
    title: 'Превышение скорости',
  },
  other: {
    bits: [5, 6, 7, 10, 11, 12, 13, 14],
    title: 'Прочие предупреждения',
  },
}

// const leftPad = { paddingLeft: '10px', fontSize: '1.2em' }
// const listStyle = { paddingTop: '12px', paddingBottom: '12px' }


export const TrackFilterForm = (props) => {
  let filter = props.filter

  // const toggleObjects = () => {
  //   if (typeof props.onToggleObjects === 'function') props.onToggleObjects()
  // }

  const onTopChange = ({ target }) => {
    const bits = target.value

    // let filter = props.filter
    const mask = 1 << bits
    filter = filter ^ mask

    if (typeof props.onChange === 'function') props.onChange(filter)
  }

  const onChangeSensors = ({ target }) => {
    // let filter = props.filter
    Object.keys(sensors).forEach((id) => {
      const bits = sensors[id].bits
      for (let i = 0; i < bits.length; i++) {
        const mask = 1 << bits[i]
        filter = target.checked ? filter | mask : filter & ~mask
      }
    })
    if (typeof props.onChange === 'function') props.onChange(filter)
  }

  const onChange = ({ target }) => {
    const id = target.value
    const bits = sensors[id].bits

    // let filter = props.filter
    for (let i = 0; i < bits.length; i++) {
      const mask = 1 << bits[i]
      filter = filter ^ mask
    }
    if (typeof props.onChange === 'function') props.onChange(filter)
  }

  const checked = {}
  const indt = []
  let counter = 0

  const oneOf = (elems) => {
    for (let i = 0; i < elems.length; i++) {
      if (isWarningAllowed(props.filter, elems[i])) {
        return true
      }
    }
    return false
  }

  const sensorElements = Object.keys(sensors).map((id, key) => {
    const { bits, title } = sensors[id]
    checked[id] = oneOf(bits)

    if (checked[id]) {
      indt.push(id)
    }
    counter++

    return <li key={key}><Checkbox id={id} value={id} checked={checked[id]} onChange={onChange}>{title}</Checkbox></li>
  })

  const stops = isWarningAllowed(props.filter, checksboxes.stops)
  // const cp = isWarningAllowed(props.filter, checksboxes.cp)

  return (
    <div>
      {/* <div style={listStyle}>
        <Switch checked={props.navigation} onChange={toggleObjects} />
        <span style={leftPad}>Показывать объекты </span>
      </div> */}

      <div>
        <Checkbox id={`stops`} value={checksboxes.stops} checked={stops} onChange={onTopChange}>
          Остановки
        </Checkbox>
      </div>

      {/* <div>
        <Checkbox id={checksboxes.cp} checked={cp} onChange={onTopChange}>Контрольные точки</Checkbox>
      </div> */}

      <div>
        <Checkbox
          checked={indt.length === counter}
          indeterminate={indt.length > 0 && indt.length < counter}
          onClick={onChangeSensors}
        >
          Предупреждения
        </Checkbox>

        <ul style={{ listStyle: 'none' }}>
          {sensorElements}
        </ul>
      </div>
    </div>
  )
}

TrackFilterForm.propTypes = {
  filter: PropTypes.number,
  navigation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onToggleObjects: PropTypes.func.isRequired,
}

TrackFilterForm.defaultProps = {
  filter: 0,
}
