import axios from 'axios'

const prod = process.env.NODE_ENV === 'production'

const apiEndpoint = '/api'

const API = apiEndpoint // `${apiHost}${apiEndpoint}`

const instance = axios.create({
  withCredentials: !prod,
  crossdomain: !prod,
})

const getHeaders = {
  'Accept': 'application/json',
}

const postHeaders = {
  ...getHeaders,
  'Content-Type': 'application/json',
}

const parseResponse = (response) => {
  // if (response == null) return response
  // const hasPayload = response.headers['Content-Type']
  // return hasPayload ? response.json() : response
  return response.data
}

const parseError = (err) => {
  if (err && err.response && err.response.status) err.name = 'NetworkError'
  throw err
}

const request = {
  get: (url, params) => instance.get(`${API}${url}`, {
    headers: getHeaders,
    params: params,
  }).then(parseResponse).catch(parseError),
  post: (url, data) => instance.post(`${API}${url}`, data, {
    headers: postHeaders,
  }).then(parseResponse).catch(parseError),
  put: (url, data) => instance.put(`${API}${url}`, data, {
    headers: postHeaders,
  }).then(parseResponse).catch(parseError),
  delete: (url, params) => instance.delete(`${API}${url}`, {
    headers: getHeaders,
    params: params,
  }).then(parseResponse).catch(parseError),
}

const login = (body) => request.post('/signin', body)
const settings = () => request.get('/settings')
const sharedInfo = (token) => request.get(`/shared/${token}`)
const logout = () => request.get('/signout')

// const service = {
//   log: (level) => request.get(`/service/log?level=${level}`),
//   telematic: () => request.get('/service/telematic'),
// }

const devices = {
  track: (trackRequest) => request.get(`/track`, trackRequest),
  tail: (tailsRequest) => request.post('/tail', tailsRequest),
}

const geozones = {
  list: () => request.get(`/geozones`),
  save: (geozone) => request.post(`/geozones`, geozone),
  update: (geozone) => request.put(`/geozones/${geozone.id}`, geozone),
  delete: (id) => request.delete(`/geozones/${id}`),

  saveGroup: (group) => request.post(`/geozonegroups`, group),
  updateGroup: (group) => request.put(`/geozonegroups/${group.id}`, group),
  deleteGroup: (id) => request.delete(`/geozonegroups/${id}`),
  addLinks: (groupID, links) => request.post(`/geozonegroups/${groupID}/links`, { action: 'add', geozones: links }),
  delLinks: (groupID, links) => request.post(`/geozonegroups/${groupID}/links`, { action: 'del', geozones: links }),
}

const reports = {
  make: (params) => request.post(`/reports`, params),
  getTemplates: () => request.get(`/reports/templates`),
  getVars: (params) => request.get(`/reports/vars`, params),
}

const sensors = {
  getInfo: (params) => request.get(`/sensors`, params),
  getValues: (params) => request.get(`/sensors/values`, params),
}

const service = (method, payload) => request.post(`/service`, { method: method, params: payload })

const api = {
  login,
  settings,
  sharedInfo,
  logout,
  devices,
  geozones,
  reports,
  sensors,
  service,
}

export default api
