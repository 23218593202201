import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Collapse from 'antd/es/collapse'
import Button from 'antd/es/button'
import Card from 'antd/es/card'
import Divider from 'antd/es/divider'


import { getReadableTime, getReadableDuration, prod } from '../../common'


import styles from './AdminPanel.module.css'


const Panel = Collapse.Panel

const formatUserTitle = (user) => `${user.first_name} ${user.last_name} [ email: ${user.email} ]`

export const Reports = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [report, setReport] = useState(null)
  const [dataReport, setDataReport] = useState(null)

  useEffect(() => {
    props.onAction('server', { action: 'report' }, (resp, err) => {
      setLoading(false)
      if (err) {
        setError(err.message)
        return
      }
      !prod && console.info('report', resp)
      setReport(resp)

      props.onAction('storage', { action: 'report' }, (resp, err) => {
        setLoading(false)
        if (err) {
          setError(err.message)
          return
        }
        !prod && console.info('data report', resp)
        setDataReport(resp)
      })
    })
  }, [props])

  const onUpdate = () => {
    setLoading(true)
    props.onAction('server', { action: 'report' }, (resp, err) => {
      setLoading(false)
      if (err) {
        setError(err.message)
        return
      }
      // console.info('report update', resp)
      setReport(resp)

      props.onAction('storage', { action: 'report' }, (resp, err) => {
        setLoading(false)
        if (err) {
          setError(err.message)
          return
        }
        // console.info('report update', resp)
        setDataReport(resp)
      })
    })
  }

  if (loading) return <div>Loading...</div>

  if (error) return <div> Error: {error}  </div>

  if (!report || !dataReport) return <div className={styles.container}>No reports</div>

  const userTitleByID = (id) => {
    const client = report.clients.find(({ user }) => user.id === id)
    return client != null ? formatUserTitle(client.user) : 'No data?'
  }

  const clientDevices = []
  report.clients.forEach(({ devices }) => {
    clientDevices.push(...devices)
  })

  return <div className={styles.container}>
    <div>
      <Button onClick={onUpdate}>Update</Button>
    </div>

    <Divider />

    <div>Bunker Point Lifetime: {getReadableDuration(dataReport.pointLifetime / 1000000)}</div>

    <Divider />

    <Card title='Users'>
      <Collapse accordion>
        {report.clients.map(({ user, role }) => { // TODO device_groups, devices, features, account
          const title = formatUserTitle(user)
          return <Panel key={`user-${user.id}`} header={title}>
            <div className={styles.panelContainer}>
              <p>ID: {user.id}</p>
              <p>Role: {role}</p>
            </div>
          </Panel>
        })}
      </Collapse>
    </Card>

    <Divider />

    <Card title='Workers'>
      <Collapse accordion>
        {report.workers.map(({ account, connectedUsers }) => {
          const title = `${account.name} [ host: ${account.host} ]`
          const dr = dataReport.accounts.find((acc) => acc.id === account.id)
          return <Panel key={`wrk-${account.id}`} header={title}>
            <div className={styles.panelContainer}>
              <p>ID: {account.id}</p>
              <p>Login: {account.login}</p>

              <p>Connected Users:</p>
              <ul>
                {connectedUsers.map((userID, key) => <li key={`${account.id}${key}`}>{userTitleByID(userID)}</li>)}
              </ul>

              <p>Data:</p>
              <DataReport key={`dr-${account.id}`} devices={dr.devices} deviceData={clientDevices} />
            </div>
          </Panel>
        })}
      </Collapse>
    </Card>

    <Divider />

    <Card title='Devices'>
      <Collapse accordion>
        {report.deviceUsage.map(({ imei, users }) => {
          const title = `${imei}`
          return <Panel key={`dev-${imei}`} header={title}>
            <div className={styles.panelContainer}>
              <p>Connected Users:</p>
              <ul>
                {users.map((userID, key) => <li key={`${imei}${key}`}>{userTitleByID(userID)}</li>)}
              </ul>
            </div>
          </Panel>
        })}
      </Collapse>
    </Card>
  </div>
}

Reports.propTypes = {
  onAction: PropTypes.func.isRequired,
}

const deviceTitleByIMEI = (clientDevices, imei) => {
  const devices = clientDevices.filter((dev) => dev.imei === imei)

  let title = ``
  for (let i = 0, dlen = devices.length; i < dlen; i++) {
    title += `${devices[i].model} - ${devices[i].number} (imei: ${devices[i].imei})`
    if (i !== dlen - 1) title += ' | '
  }

  return title
}

const DataReport = ({ devices, deviceData }) => {
  return <div className={styles.panelContainer}>
    <Collapse accordion>
      {devices.map(({ imei, cell }) => {
        const title = deviceTitleByIMEI(deviceData, imei)
        return <Panel key={`dev-${imei}`} header={title}>
          <div className={styles.panelContainer}>
            <p>Data count: {cell.dataCount}</p>
            <p>Sensors count: {cell.sensorsCount}</p>
            <p>Last Time: {getReadableTime(cell.lastTime * 1000)}</p>
            {cell.status.length > 0 && <p>Status: {cell.status}</p>}
            {cell.description.length > 0 && <p>Description: {cell.description}</p>}

            <p>Last Point:</p>
            {cell.lastValid ? <LastValid point={cell.lastValid} /> : 'null'}

            <p>Sensors</p>
            <div className={styles.panelContainer}>
              {cell.sensors.map((sensor, key) => <Sensor key={key} sensor={sensor} />)}
            </div>
          </div>
        </Panel>
      })}
    </Collapse>
  </div>
}

DataReport.propTypes = {
  devices: PropTypes.array.isRequired,
  deviceData: PropTypes.array.isRequired,
}


// Coords Coords `json:"coords"`
// Flags  int64  `json:"flags"`
// Time   int64  `json:"time"`
// coords:
// Dir   float32 `json:"dir"`
// Lat   float32 `json:"lat"`
// Lon   float32 `json:"lon"`
// Speed float32 `json:"speed"`
// Valid bool    `json:"valid"`
const LastValid = ({ point }) => {
  return <div className={styles.panelContainer}>
    <p>{`${getReadableTime(point.time * 1000)} (${point.coords.valid ? 'Valid' : 'Invalid'})`}</p>
    <p>Flags: {point.flags}</p>
    <p>Speed: {point.coords.speed}</p>
    <p>{`Lat ${point.coords.lat}, Lng ${point.coords.lon}, Dir ${point.coords.dir}`}</p>
  </div>
}

LastValid.propTypes = {
  point: PropTypes.object.isRequired,
}

// Name    string `json:"name"`
// Value   string `json:"value"`
// Units   string `json:"units"`
// VarName string `json:"varName"`
// OoType  int32  `json:"ooType"`
const Sensor = ({ sensor }) => {
  return <p>{`${sensor.name} - ${sensor.value} (${sensor.units})`}</p>
}

Sensor.propTypes = {
  sensor: PropTypes.object.isRequired,
}
