import L from 'leaflet'

// ${strokeColor}
export const fuelUpDivIcon = (
  size,
  fillColor = '#ffbf00', // 'transparent',
  strokeColor = '#000'
) => new L.DivIcon({
//     html: `<?xml version="1.0" encoding="UTF-8"?>
//   <svg
//     width="${size}"
//     height="${size}"
//     version="1.1"
//     viewBox="0 0 376.8 376.8"
//     xml:space="preserve"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <rect width="376.8" height="376.8" ry="38.45" fill=${fillColor} />
//     <g fill="${strokeColor}" transform="matrix(.6737 0 0 .6737 -4.032 59.29)">
//       <path d="m273.2 106.9c-27.18-44.86-57.41-83.69-73.02-102.8-2.088-2.565-5.\
// 221-4.054-8.528-4.053-3.308 0-6.44 1.489-8.529 4.054-15.6 19.16-45.83 58-73.\
// 02 102.9-35.03 57.82-52.79 105.6-52.79 142.1 0 74.07 60.26 134.3 134.3 134.\
// 3s134.3-60.26 134.3-134.3c1e-3 -36.48-17.76-84.3-52.79-142.1zm-63.11 227c-7.\
// 844 2.006-15.99 3.022-24.2 3.022-50.19 0-91.02-37.93-91.02-84.55 0-11.26 2.\
// 97-24.4 8.825-39.08 0.989-2.48 3.807-3.895 6.585-3.295 2.776 0.598 4.64 3.\
// 018 4.354 5.65-0.342 3.148-0.516 6.223-0.516 9.136 0 50.74 40.88 93.22 95.\
// 09 98.82 2.698 0.279 4.803 2.297 5.018 4.812 0.216 2.515-1.522 4.817-4.\
// 139 5.487z"/>
//     </g>
//     <g fill="${strokeColor}" transform="matrix(0 .4176 -.4176 0 357 117.3)">
//       <path d="m145.8 290.2c-6.137 0-12.25-5.831-13.41-7.002-4.194-3.092-115.1-\
// 86.69-127.3-98.86-4.422-4.444-5.203-8.894-5.074-11.86 0.288-7.089 5.768-11.94 \
// 6.389-12.46l123.3-102.1c1.351-1.474 7.617-7.611 13.58-7.611 2.717 0 9.031 \
// 1.243 9.031 12.69v52.62h174.1c0.486-0.081 1.135-0.156 1.903-0.156 2.03 0 12.16 \
// 0.774 12.16 15.98v80.87c0 11.19-7.927 14.15-12.12 14.15h-172.4v48.23c0 13.47-6.\
// 419 15.48-10.24 15.48zm0-9.746v4.87z"/>
//     </g>
//   </svg>`,
    html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect width="376.8" height="376.8" ry="38.45" fill=${fillColor} />
      <g fill="${strokeColor}" transform="matrix(0 .4176 -.4176 0 379.4 117.3)">
        <path d="m145.8 290.2c-6.137 0-12.25-5.831-13.41-7.002-4.194-3.092-115.\
1-86.69-127.3-98.86-4.422-4.444-5.203-8.894-5.074-11.86 0.288-7.089 5.768-11.\
94 6.389-12.46l123.3-102.1c1.351-1.474 7.617-7.611 13.58-7.611 2.717 0 9.031 1.\
243 9.031 12.69v52.62h174.1c0.486-0.081 1.135-0.156 1.903-0.156 2.03 0 12.16 0.\
774 12.16 15.98v80.87c0 11.19-7.927 14.15-12.12 14.15h-172.4v48.23c0 13.47-6.\
419 15.48-10.24 15.48zm0-9.746v4.87z" fill="#00db00"/>
      </g>
      <g transform="matrix(1.417 0 0 1.417 -1701 -1065)"
        clip-rule="evenodd"
        fill="#000000"
        fill-rule="evenodd"
        image-rendering="optimizeQuality"
        shape-rendering="geometricPrecision"
      >
        <g fill="#000000">
          <path d="m1289 828h-15v29c-16 18-34 36-44 58-10 20-9 35 20 41 9 2 15-\
3 20-12v-78h19zm18 0h54v52h-54zm-18 51h-6v69c-8 13-18 25-36 21-76-18-10-89 14-\
117v-37h28v-8c0-5 3-8 8-8h70c5 0 9 3 9 8v152c0 4-4 8-9 8h-70c-5 0-8-4-8-8z"
            fill="#000000" stroke="#2b2a29" stroke-width=".01535"
          />
        </g>
      </g>
    </g>
  </svg>`,
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    className: '',
  })
