import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import Moment from 'moment'
import Card from 'antd/es/card'
import Tabs from 'antd/es/tabs'


import {
  StopsHelperTable,
  ShiftHelperCard,
  WarningHelperCard,
} from '../components'

const TabPane = Tabs.TabPane

const pannelStyle = {
  position: 'relative',
  // bottom: '0',
  // left: '0',
  // overflowY: 'hidden',
  width: '100%',
  // height: '300px',
}

const innerPannelStyle = {
  // height: '600px',
  marginLeft: '-12px',
  marginRight: '-12px',
}

// const tabStyle = {
//   // position: 'absolute',
//   // overflowY: 'hidden',
// }

const tabPaneStyle = {
  overflowY: 'auto',
}

/**
 * Track Info pannel
 */
class TrackInfoComponent extends React.Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
    this.onStopClick = this.onStopClick.bind(this)
    this.onWarnClick = this.onWarnClick.bind(this)
  }

  /**
   * @param {number} type
   * @param {number} index
   * @param {object} coords
   */
  onClick(type, index, coords) {
    const map = this.props.rootStore.map
    const ui = this.props.rootStore.ui

    map.setZoom(18)

    ui.mapState.deviceTrack.selected.type = type
    ui.mapState.deviceTrack.selected.index = index

    setTimeout(() => {
      // coords.lat = coords.lat - 0.0004
      // coords.lon = coords.lon - 0.0003
      map.setMapCenter(coords)
    }, 100)
  }

  /**
   * @param {number} index
   * @param {object} coords
   */
  onStopClick(index, coords) {
    this.onClick(0, index, coords)
  }

  /**
   * @param {number} index
   * @param {object} coords
   * @return {func}
   */
  onWarnClick(index, coords) {
    return this.onClick(0, index, coords)
  }

  /**
 * @return {object}
 */
  render() {
    // if (this.props.rootStore.ui.mapState.controlBox.collapsed) return null

    if (!this.props.rootStore.track.done) {
      return null
    }

    if (!this.props.rootStore.ui.mapState.trackInfo.visible) {
      return null
    }

    const map = this.props.rootStore.map
    const id = map.selected.id
    if (!id) {
      return null
    }

    // const closePannel = () => {
    //   this.props.rootStore.ui.mapState.trackInfo.visible = false
    // }

    const helpers = this.props.rootStore.track.helpers // map.track.helpers

    return (
      <Card
        className='z-depth-3'
        // title='Информация по треку'
        //   onClick={closePannel} />}
        style={pannelStyle}
      >
        <div style={innerPannelStyle}>
          <Tabs
            defaultActiveKey="1"
            size='small'
            renderTabBar={(props, DeafultTabs) => (
              <div>
                <h4>Информация по треку</h4>
                <DeafultTabs {...props} />
              </div>
            )}
          >
            {helpers.stops && helpers.stops.length !== 0 &&
              <TabPane tab="Остановки" key="1">
                {/* {map.track.helpers.stops.map((stop, key) => (
                  <StopHelperCard key={key} stop={stop}
                    onClick={this.onStopClick(key, stop.coords)}/>
                ))} */}
                <StopsHelperTable stops={helpers.stops}
                  onClick={this.onStopClick} />
              </TabPane>}

            {helpers.shifts && helpers.shifts.length !== 0 &&
              <TabPane tab="Смены" key="2" style={tabPaneStyle}>
                {this.props.rootStore.track.helpers.shifts.map((shift, key) => (
                  <ShiftHelperCard key={key} shift={shift} />
                ))}
              </TabPane>}

            {helpers.warnings && helpers.warnings.length !== 0 &&
              <TabPane tab="Предупреждения" key="3" style={tabPaneStyle}>
                {this.props.rootStore.track.helpers.warnings.map((warning, key) => (
                  <WarningHelperCard key={key} warning={warning} />
                ))}
              </TabPane>}

            {helpers.checkpoints && helpers.checkpoints.length !== 0 &&
              <TabPane tab="Контрольные точки" key="4" style={tabPaneStyle}>
                {this.props.rootStore.track.helpers.checkpoints.map((cp, key) => {
                  const start = Moment(cp.timeBegin)
                  const end = Moment(cp.timeEnd)
                  const delta = Moment.duration(end.diff(start))
                  return (
                    <div key={key}>
                      <h3>Контрольне точки</h3>

                      <div>
                        {'Начало: ' + start.format('Do MMMM YYYY, HH:mm:ss')}
                      </div>

                      <div>
                        {'Конец: ' + end.format('Do MMMM YYYY, HH:mm:ss')}
                      </div>

                      <div>
                        {'Продолжительность: ' + delta.hours() + ' ч ' +
                          delta.minutes() + ' м'}
                      </div>

                      <div>
                        {'Точка: ' + cp.CPName}
                      </div>

                      <div>
                        {'Адрес: ' + cp.CPAddress}
                      </div>
                    </div>
                  )
                })}
              </TabPane>}
          </Tabs>
        </div>
      </Card>
    )
  }
}

TrackInfoComponent.propTypes = {
  rootStore: PropTypes.object.isRequired,
}

export const TrackInfo = inject('rootStore')(observer(TrackInfoComponent))
TrackInfo.displayName = 'TrackInfo'
