import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'


import api from '../api'


const LogoutPage = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.signout()
      .then(() => {
        props.rootStore.profile.logout()
        setLoading(false)
      })
      .catch((err) => {
        console.info('err', err)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <div>Выходим...</div>
  }

  return <Redirect to='/maps/login' />
}

export default inject('rootStore')(observer(withRouter(LogoutPage)))


// const deleteCookie = (cname) => {
//   document.cookie = cname + '=;expires=0;path=/'
// }

// FIXME logout method of maps api v2

/**
 * Logout Page component
 */
// class _LogoutPage extends React.Component {
//   /**
//    * @param {object} props
//    */
//   constructor(props) {
//     super(props)

//     this.state = {
//       loading: true,
//       error: false,
//     }
//   }

//   /**
//    * Std after mount hook
//    */
//   componentDidMount() {
//     api.logout().then(() => {
//       this.props.rootStore.profile.logout()
//     }).catch((err) => {
//       console.log(err)
//       this.setState((ps) => ({ ...ps, error: true }))
//     }).finally(() => {
//       this.setState((ps) => ({ ...ps, loading: false }))
//       this.props.rootStore.profile.logout()
//     })
//   }

//   /**
//    * @return {Object}
//    */
//   render() {
//     if (this.state.loading) {
//       return <div>Loading...</div>
//     }

//     if (this.state.error) {
//       return <div>Ошибка при выходе, попробуйте повторить позже</div>
//     }

//     return <Redirect to='/login' />
//   }
// }

// _LogoutPage.propTypes = {
//   rootStore: PropTypes.object.isRequired,
// }

// export const LogoutPage = inject('rootStore')(observer(withRouter(_LogoutPage)))

