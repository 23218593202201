import { makeAutoObservable, toJS } from "mobx"


import { IDeviceDataCell, IPointData, ISensor } from "./interfaces"


// type TField = {[]}


const pointLifeTime = 300 // Seconds

const sortBy = (field: string) => (a: any, b: any) => {
  if (field && field.length > 0) {
    a = a[field]
    b = b[field]
  }

  if (a < b) return -1
  if (a > b) return 1
  return 0
}


export class DeviceDataCell implements IDeviceDataCell {
  id: string
  description: string
  lastValid: IPointData | null
  points: IPointData[]
  sensors: ISensor[]

  constructor(deviceID: string) {
    this.id = deviceID
    this.description = ''
    this.lastValid = null
    this.points = []
    this.sensors = []

    makeAutoObservable(this, {}, { autoBind: true })
  }

  get status(): string {
    if (this.lastValid == null) {
      return 'offline'
    }

    const now = +(Date.now())
    const duration = now - this.lastValid.time
    const hours = Math.round(duration / 3600000)

    switch (true) {
      case !this.lastValid.coords.valid:
        return 'invalid'

      case hours < 2:
        return 'online'

      case hours >= 2 && hours < 6:
        return 'old'

      case hours >= 6:
        return 'rotten'

      default:
        return 'offline'
    }
  }

  addData(points: IPointData[], sensors: ISensor[], description: string) {
    this.description = description

    if (points.length > 0) {

      const validPoints: IPointData[] = []
      let lastValid = this.lastValid
      points.forEach((point) => {
        if (point.coords.valid) {
          validPoints.push(point)
          lastValid = point
        }
      })

      if (this.lastValid == null && validPoints.length === 0 && this.lastValid != null) {
        const prevValid: IPointData = this.lastValid
        console.info('save invalid', this.id)
        lastValid = points[points.length - 1]
        // Copying last valid coords into invalid point
        lastValid.coords = prevValid.coords
      }

      this.lastValid = lastValid
      this.points.push(...validPoints)
    }

    // Checking sensors update
    const lastSensors = toJS(this.sensors)
    const nowFor = +(new Date())
    for (let i = 0; i < sensors.length; i++) {
      let match = false
      let matchIndex = -1

      for (let j = 0; j < lastSensors.length; j++) {
        if (lastSensors[j]?.lastUpdate || 0 < nowFor) {
          lastSensors[j].isNew = false
        }

        if (sensors[i].name === lastSensors[j].name) {
          match = true
          matchIndex = j
        }
      }

      if (!match && sensors[i].value.length > 0) {
        sensors[i].isNew = true
        sensors[i].lastUpdate = nowFor
        lastSensors.push(sensors[i])
        continue
      }

      if (matchIndex !== -1 && sensors[i].value.length > 0 && sensors[i].value !== lastSensors[matchIndex].value) {
        lastSensors[matchIndex].isNew = true
        lastSensors[matchIndex].lastUpdate = nowFor
        lastSensors[matchIndex].value = sensors[i].value
      }
    }

    lastSensors.sort(sortBy('name'))
    this.sensors = lastSensors

    const now = Math.floor(Date.now() / 1000)
    let sanIndex = 1
    const len = this.points.length
    if (len === 0) return

    for (let i = 0; i < len; i++) {
      if (this.points[i].time + pointLifeTime > now) {
        sanIndex = i
        break
      }
    }

    // Keep last point
    if (sanIndex === len - 1) sanIndex = len - 2
    if (sanIndex < 0) return
    this.points = this.points.slice(sanIndex, len)
  }
}
