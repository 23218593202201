import { useState, useEffect } from 'react';

function getWidth() {
  return {
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth,
  };
}

export function useWindowWidth() {
  let [windowSize, setWindowSize] = useState(getWidth());

  function handleResize() {
    setWindowSize(getWidth());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
