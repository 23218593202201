export const colorUtils = {
  toHex: (numColor: number) => {
    const hex = numColor.toString(16)
    let hexStr = String(hex)
    if (hexStr.length < 8) {
      hexStr = hexStr.padStart(8, '0')
    }

    const opInt = parseInt(hexStr.substr(6, 2), 16) / 255
    return {
      color: '#' + hexStr.substr(0, 6),
      opacity: round(opInt, 0.1),
    }
  },

  toColor: (rgb: string, opacity: number) => {
    const op = Math.round(opacity * 255)
    rgb = rgb.replace(/#/g, '')
    const num = shift(parseInt(rgb, 16), 8) + op
    return num
  },

  opacityToTransparency: (opacity: number): number => {
    // const decOp = round((100 - opacity) / 100, 0.1)
    const decOp = 100 - (opacity * 100)
    return Math.round(decOp)
  },
}

export const round = (value: number, step: number) => {
  step || (step = 1.0)
  const inv = 1.0 / step
  return Math.round(value * inv) / inv
}

function shift(number: number, shift: number) {
  return number * Math.pow(2, shift);
}

const rgx = new RegExp(/.{1,2}/, 'g')
const repRgx = new RegExp(/#/, 'g')

export const hexToRGB = (hexColor: string, opacity: number) => {
  let aRgbHex = hexColor.replace(repRgx, '').match(rgx)
  if (aRgbHex == null) {
    aRgbHex = ['00', '00', '00']
  }

  return {
    r: parseInt(aRgbHex[0], 16),
    g: parseInt(aRgbHex[1], 16),
    b: parseInt(aRgbHex[2], 16),
    a: opacity,
  }
}
