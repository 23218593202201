import React, { Suspense, useEffect, useState } from 'react'
import { Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'


import { AppRoot, LoginPage, LogoutPage } from './pages'
import { WindowSizeHandler } from './components'
import { useStore } from './stores/rootStore'


// const mapsURL = process.env.REACT_APP_MAPS_URL

const App = (props) => {
  const store = useStore()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  

  useEffect(() => {
    store.profile.getInvoker(true)
      // .then(props.rootStore.profile.getSettings)
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
        // window.location.href = loginPath
        setLoading(false)

        const target = window.location.href// params.get('redirect') || `${mapsURL}/0`
        const loginPath = `/maps/login?redirect=${encodeURIComponent(target)}`
        history.push(loginPath)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <div>Загрузка...</div>
  }

  return (
    <Suspense fallback={<div>Загрузка...</div>}>
      <WindowSizeHandler />
      <Switch>
        <Route exact path='/maps/login' component={LoginPage} />
        <Route exact path='/maps/logout' component={LogoutPage} />
        {/* FIXME <Route exact path='/shares/:token' component={SharedLinksPage} /> */}
        <Route path='/maps/:userID' component={AppRoot} />
        <Redirect to='/maps/0/' />
      </Switch>
    </Suspense>
  )
}

export default withRouter(observer(App))
