import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { parseISO } from 'date-fns'
import { LatLngLiteral } from 'leaflet'
import { isMobile } from 'react-device-detect'
import message from 'antd/es/message'


import { ICoords, IHelper, IHelpers, IPointData, IRootStore, IShift, ITrack } from './interfaces'
import { trackWorker } from '../workers'
import { getReadableDuration, isWarningAllowed, prod } from '../common'
import { LatLngDir, vertexClusterReduction } from '../tools/downsampling'
// import { getArrows } from '../tools/trackUtils'
import api from '../api'


// TODO: Think about - calc all zooms for all shifts? Maybe cache tracks after calc
// TODO: Calc track for all zooms


const fullBounds = [90, 180, -90, -180]

const updateBounds = (bounds: number[], point: ICoords): void => {
  if (bounds[0] > point.lat) {
    bounds[0] = point.lat
  }
  if (bounds[1] > point.lon) {
    bounds[1] = point.lon
  }
  if (bounds[2] < point.lat) {
    bounds[2] = point.lat
  }
  if (bounds[3] < point.lon) {
    bounds[3] = point.lon
  }
}


export default class TrackStore {
  private rootStore: IRootStore
  private trackWorker: Worker

  bounds: number[] | null
  requesting: boolean
  done: boolean
  // length: number
  startTime: number // Object | null // FIXME proper type
  endTime: number // Object | null // FIXME proper type

  // marker: {
  //   position: any[] // FIXME proper type
  //   speed: number
  // }

  selectedShift: number | null
  selectedStop: number | null
  selectedWarning: number | null

  data: IPointData[]
  private preparedTrack: LatLngDir[][]

  helpers: IHelpers
  // bounds: number[]
  line: ICoords[]

  shiftData: IPointData[]
  shiftHelpers: IHelpers
  shiftBounds: number[]
  shiftLine: ICoords[]

  private metrics: {
    now: number
    delta: number
  }

  trackFilters: number

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore

    // Web worker for track processing
    this.trackWorker = new Worker(trackWorker, { type: 'module' })
    this.trackWorker.onmessage = this.processTrackResult(this)

    this.bounds = null
    this.requesting = false
    this.done = false
    // this.length = 0
    this.startTime = 0
    this.endTime = 0

    this.selectedShift = null
    this.selectedStop = null
    this.selectedWarning = null

    this.data = []
    this.preparedTrack = []
    this.helpers = {
      shifts: [],
      stops: [],
      warnings: [],
    }
    this.bounds = []
    this.line = []

    this.shiftData = []
    this.shiftHelpers = {
      shifts: [],
      stops: [],
      warnings: [],
    }
    this.shiftBounds = []
    this.shiftLine = []

    this.trackFilters = (() => {
      const savedFilters = window.localStorage.getItem('trackFilters')
      return savedFilters != null ? parseInt(savedFilters) : 1610743807
    })()

    this.metrics = {
      delta: 0,
      now: 0,
    }

    window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
      if (!e.isTrusted) {
        return
      }

      e.preventDefault()
      window.localStorage.setItem('trackFilters', this.trackFilters.toString())
    })

    makeAutoObservable(this, {}, { autoBind: true })
  }

  get trackData(): IPointData[] {
    if (this.helpers == null || this.selectedShift == null) {
      return this.data
    }

    const shift = this.helpers.shifts[this.selectedShift]
    if (shift.shiftType === 9) {
      return this.data
    }
    return this.shiftData
  }

  get trackHelpers(): IHelpers {
    if (this.helpers == null || this.selectedShift == null) {
      return this.helpers
    }

    const shift = this.helpers.shifts[this.selectedShift]
    if (shift.shiftType === 9) {
      return this.helpers
    }
    return this.shiftHelpers
  }

  get currentShift(): IShift | null {
    if (this.selectedShift == null) {
      return null
    }
    return this.helpers.shifts[this.selectedShift]
  }

  get trackLine(): LatLngDir[] {
    if (this.rootStore.ui.mapState.ref?.current?.leafletElement == null) {
      return []
    }

    // const zoom = this.rootStore.map.currentZoom
    // if (this.selectedShift == null || this.helpers == null || this.helpers.shifts[this.selectedShift].shiftType === 9) {
    //   return vertexClusterReduction(this.rootStore.ui.mapState.ref?.current?.leafletElement, zoom, this.data)
    // }
    // return vertexClusterReduction(this.rootStore.ui.mapState.ref?.current?.leafletElement, zoom, this.shiftData)

    if (this.preparedTrack.length === 0) {
      return []
    }

    if (this.selectedShift == null || this.helpers == null || this.helpers.shifts[this.selectedShift].shiftType === 9) {
      return this.preparedTrack[this.rootStore.map.currentZoom - 3]
    }
    return vertexClusterReduction(this.rootStore.ui.mapState.ref?.current?.leafletElement, this.rootStore.map.currentZoom, this.shiftData)
  }

  get trackLineLeaflet(): LatLngLiteral[] {
    return this.trackLine.map((p) => ({
      lat: p.lat,
      lng: p.lon,
    }))
  }

  // get trackArrows(): LatLngDir[] {
  //   if (this.rootStore.ui.mapState.ref?.current?.leafletElement == null) {
  //     return []
  //   }

  //   const zoom = this.rootStore.map.currentZoom
  //   return getArrows(this.rootStore.ui.mapState.ref?.current?.leafletElement, zoom, this.trackLine)
  // }

  get trackBounds(): number[] {
    if (this.helpers == null || this.selectedShift == null) {
      return this.bounds || fullBounds
    }

    const shift = this.helpers.shifts[this.selectedShift]
    if (shift.shiftType === 9) {
      return this.bounds || fullBounds
    }
    return this.shiftBounds
  }

  get isStopsAllowed(): boolean {
    const stops = 30
    return this.isWarningAllowed(stops)
  }

  get isGeozonesAllowed(): boolean {
    const cp = 29
    return this.isWarningAllowed(cp)
  }

  isWarningAllowed(warning: number): boolean {
    if (!warning || typeof warning !== 'number') {
      return false
    }
    return isWarningAllowed(this.trackFilters, warning)
  }

  setFilters(filter: number) {
    this.trackFilters = filter
  }

  dropTrack() {
    this.requesting = false
    this.done = false
    // this.length = 0
    this.data = []
    this.helpers = {
      shifts: [],
      stops: [],
      warnings: [],
    }
    this.preparedTrack = []
  }

  setShift(index: number | null) {
    if (index === this.selectedShift) {
      return
    }

    this.selectedShift = index
    this.selectedStop = null
    this.selectedWarning = null

    if (index == null) {
      // if (this.line.length > 0) {
      //   this.rootStore.map.fitBounds(this.trackBounds, { x: 150, y: 500 })
      // }
      return
    }

    const hasCoords = this.line.length > 0
    if (!hasCoords) {
      this.shiftData = []
      this.shiftLine = []
      this.shiftBounds = fullBounds.slice()
      return
    }

    const currentShift = this.helpers.shifts[index]
    const start = +parseISO(currentShift.timeBegin)
    const end = +parseISO(currentShift.timeEnd)

    const bounds = fullBounds.slice()
    const shiftLine: ICoords[] = []
    const shiftTrack: IPointData[] = []

    this.data.forEach((point) => {
      if (point.time >= start && point.time <= end) {
        // recalcBounds(bounds, point.coords)
        updateBounds(bounds, point.coords)
        shiftLine.push(point.coords)
        shiftTrack.push(point)
      }
    })

    const filterFunc = (entity: IHelper) => {
      if (entity.timeBegin >= currentShift.timeBegin && entity.timeEnd <= currentShift.timeEnd) {
        return entity
      }
    }

    const rawStops = toJS(this.helpers.stops)
    const rawWarnings = toJS(this.helpers.warnings)

    this.shiftData = shiftTrack
    this.shiftLine = shiftLine
    this.shiftBounds = bounds
    this.shiftHelpers.stops = rawStops.filter(filterFunc)
    this.shiftHelpers.warnings = rawWarnings.filter(filterFunc)

    // if (shiftLine.length > 0) {
    //   this.rootStore.map.fitBounds(bounds, { x: 150, y: 500 })
    // }
  }

  setStop(index: number | null) {
    !prod && console.info('stop selected', index)

    if (index == null || this.selectedStop === index) {
      this.selectedStop = null
      return
    }

    if (this.selectedStop == null) {
      // Disabling other helpers
      this.selectedWarning = null
    }

    this.selectedStop = index

    let coords = null
    if (this.trackHelpers.stops.length >= index + 1) {
      coords = this.trackHelpers.stops[index].coords
    }

    if (coords == null) {
      return
    }

    // !prod && console.info('stop located', toJS(coords))

    // this.setMapCenter([coords.lat, coords.lon])

    // this.centerWithBounds(coords)
    // const bounds = fullBounds.slice()
    // updateBounds(bounds, coords)
    // this.rootStore.map.fitBounds(bounds, { x: 100, y: 500 })
    // FIXME: dramatical lag! // this.centerWithBounds([coords.lat, coords.lon])
  }

  setWarning(index: number | null) {
    !prod && console.info('warning selected', index)

    if (index == null || this.selectedWarning === index) {
      this.selectedWarning = null
      return
    }

    if (this.selectedWarning == null) {
      // Disabling other helpers
      this.selectedStop = null
    }

    this.selectedWarning = index

    // let coords = null
    // if (this.trackHelpers.warnings.length >= index + 1) {
    //   coords = this.trackHelpers.warnings[index].coords
    // }

    // if (coords == null) {
    //   return
    // }

    // !prod && console.info('warning located', toJS(coords))

    // this.setMapCenter([coords.lat, coords.lon])

    // this.centerWithBounds(coords)
    // const bounds = fullBounds.slice()
    // updateBounds(bounds, coords)
    // this.rootStore.map.fitBounds(bounds, { x: 100, y: 500 })
    // // FIXME: dramatical lag! // this.centerWithBounds([coords.lat, coords.lon])
  }

  requestTrack(deviceID: string, from: string, to: string) {
    this.rootStore.map.stopTracking()
    this.dropTrack()
    message.destroy()

    const request = {
      deviceID: deviceID,
      from: from,
      to: to,
      shiftVars: 'Distance', // TODO соединить через запятую
    }

    this.startTime = +(new Date())
    message.loading('Загружаем трек...', 0)
    this.requesting = true
    this.selectedShift = null
    this.selectedStop = null
    this.selectedWarning = null

    api.track(request, this.rootStore.profile.user?.userID)
      .then(this.receiveTrack)
      .catch((err) => {
        !prod && console.info('Track request err', err)
        // if (err.name === 'NetworkError' && err.response.status === 401) this.rootStore.profile.logout()

        this.requesting = false
        this.done = true
        this.rootStore.ui.mapState.trackInfo.allow = true
        this.rootStore.ui.mapState.trackInfo.visible = true
        message.destroy()
        message.error('Ошибка загрузки трека')
      })
  }

  receiveTrack(track: ITrack) {
    if (!track || track.data.length === 0 || !track.helpers) {
      message.destroy()
      this.requesting = false
      this.done = true
      this.rootStore.ui.mapState.trackInfo.allow = true
      this.rootStore.ui.mapState.trackInfo.visible = true
      message.warning('Нет данных за выбранный период')
      return
    }

    message.destroy()
    message.loading('Трек загружен, обрабатываем...', 0)

    this.metrics.now = +(new Date())
    this.metrics.delta = +(new Date()) - this.startTime

    this.trackWorker.postMessage({ track: track })
  }

  processTrackResult(self: TrackStore) {
    return ({ data }: any) => {
      runInAction(() => {
        if (data.error) {
          message.destroy()
          self.requesting = false
          self.done = true

          switch (data.error.name) {
            case 'ErrNoValidPoints':
              message.warning('Нет валидных точек')
              break

            default:
              console.info('Track processing error', data.error)
              message.warning('Ошибка обработки трека')
          }
          return
        }

        message.destroy()

        self.dropTrack()

        const bounds = fullBounds.slice()
        const trackCoords = data.points.map((point: IPointData) => {
          updateBounds(bounds, point.coords)
          return point.coords
        })

        self.bounds = bounds
        self.data = data.points
        self.line = trackCoords
        self.helpers = data.helpers
        self.shiftHelpers = Object.assign({}, data.helpers)

        if (self.rootStore.ui.mapState.ref?.current?.leafletElement != null) {
          for (let i = 3; i < 18; i++) {
            self.preparedTrack.push(
              vertexClusterReduction(self.rootStore.ui.mapState.ref.current.leafletElement, i, data.points)
            )
          }
        }

        console.info('stored tracks', self.preparedTrack)

        const d2 = +(new Date()) - self.metrics.now
        const dur1 = getReadableDuration(self.metrics.delta)
        const dur2 = getReadableDuration(d2)
        const durTtl = getReadableDuration(self.metrics.delta + d2)

        message.success('Трек загружен', 5)
        console.info(`Трек загружен за ${dur1}, обработан за ${dur2}. Всего за ${durTtl}`)

        self.requesting = false
        self.done = true
        // self.length = data.points.length
        self.selectedShift = null
        self.selectedStop = null
        self.selectedWarning = null

        if (isMobile) {
          this.rootStore.ui.setSideMenuActive(false)
        }

        // TODO: move to render section
        // if (trackCoords.length > 0) {
        //   const xShift = isMobile ? -self.rootStore.ui.window.size.width + (self.rootStore.ui.window.size.width / 4) : 100
        //   const yShift = self.rootStore.ui.window.size.height / 2

        //   self.rootStore.map.fitBounds(bounds, { x: xShift, y: yShift })
        // }
      })
    }
  }
}
