import React from 'react'
import { observer } from 'mobx-react'
import { StopOutlined, WarningOutlined } from '@ant-design/icons'


import { getAccountStatus, getServerStatus } from '../../common'
import { IDevice } from '../../stores/interfaces';


import styles from './NavigationControl.module.css'


interface StatusMarkerProps {
  status: string
  blacklisted: boolean
}


const StatusMarker: React.FC<StatusMarkerProps> = ({ status, blacklisted }) => {
  let title = ''
  let indicator = ''
  switch (status) {
    case 'online':
      title = 'Актуальные данные'
      indicator = 'Online'
      break

    case 'old':
      title = 'Устаревшие данные'
      indicator = 'Old'
      break

    case 'rotten':
      title = 'Данные давно не обновлялись'
      indicator = 'Rotten'
      break

    case 'invalid':
    case 'error':
      title = 'Ошибка'
      indicator = 'Invalid'
      break

    case 'offline':
    default:
      title = 'Нет данных'
      indicator = 'Offline'
  }

  if (blacklisted) {
    return <StopOutlined className={styles.blacklisted} title='В черном списке' />
  }

  return (
    <div className={`${styles.indicator} ${styles['status' + indicator]}`} title={title} />
  )
}


interface TelematicStatusProps {
  server: number
  account: number
}


const TelematicStatus: React.FC<TelematicStatusProps> = ({ server, account }) => {
  let title = ''
  if (account !== 0) {
    title = getAccountStatus(account)
  }

  if (server !== 0) {
    title = getServerStatus(server)
  }

  return <WarningOutlined className={styles.telematicStatus} title={title} />
}

interface Props {
  item: IDevice
}

const ListItem: React.FC<Props> = ({ item }) => {
  const blacklisted = item.data.description.toLowerCase() === 'deviceinblacklistexception'

  return <div className={styles.listItem}>
    <StatusMarker status={item.data.status} blacklisted={blacklisted} />

    <span /*ref={item.ref}*/ id={item.deviceID} className={`${styles.itemTitle} ${blacklisted && styles.blacklisted}`}>
      {item.params.title}

      {(item.serverStatus !== 0 || item.accountStatus !== 0) &&
        <TelematicStatus server={item.serverStatus} account={item.accountStatus} />}
    </span>
  </div>
}

export default observer(ListItem)
