import { makeAutoObservable, observable, reaction, toJS, runInAction } from 'mobx'
import { LatLngBounds, LatLngExpression, LatLngLiteral, Point } from 'leaflet'
import { parseISO } from 'date-fns'
import message, { MessageType } from 'antd/es/message'
import { PointFeature } from 'supercluster'


import { getReadableDuration, isWarningAllowed, prod, getServerStatus, getAccountStatus, byTags } from '../common'
import api from '../api'
import { trackWorker } from '../workers'
import { getDescriptionText } from '../common/getDescriptionText'
import { vertexClusterReduction } from '../tools/downsampling'
import { getArrows } from '../tools/trackUtils'
import { DeviceDataCell } from './DeviceDataCell'
import { ICoords, IDevice, IDeviceSystem, IHelper, IHelpers, IPointData, IRootStore, ITail, ITrack, TLastPoint } from './interfaces'
import { SystemDevice } from './SystemDevices'


// TODO geocoding
// Nominatim:
// Docs: https://wiki.openstreetmap.org/wiki/Nominatim
// https://nominatim.org/release-docs/develop/api/
// https://nominatim.openstreetmap.org/search?q=[54.065407,38.257668]&accept-language=ru_RU&format=json
//
// Yandex:
// Docs: https://tech.yandex.ru/maps/doc/geocoder/desc/concepts/input_params-docpage/
// https://geocode-maps.yandex.ru/1.x/
// ?geocode=28.8116703033447,49.5487442016602 # координаты
// &results=1   # количество
// &sco=latlong # порядок координат
// &format=json # формат ответа
// &lang=ru_RU  # язык ответа
// &kind=[house|street|district|locality] # лучше не указывать


const filterFunc = (filter: string) => (device: IDevice) => {
  return device.imei.toLowerCase().indexOf(filter) !== -1 ||
    device.model.toLowerCase().indexOf(filter) !== -1 ||
    device.number.toLowerCase().indexOf(filter) !== -1
}

const filterState = 'trackFilters'

const fullBounds = [90, 180, -90, -180]

const updateBounds = (bounds: number[], point: ICoords): void => {
  if (bounds[0] > point.lat) {
    bounds[0] = point.lat
  }
  if (bounds[1] > point.lon) {
    bounds[1] = point.lon
  }
  if (bounds[2] < point.lat) {
    bounds[2] = point.lat
  }
  if (bounds[3] < point.lon) {
    bounds[3] = point.lon
  }
}


// interface TrackParams {
//   status: {
//     bounds: number[] | null
//     requesting: boolean
//     done: boolean
//     length: number
//     startTime: number // Object | null // FIXME proper type
//     endTime: number // Object | null // FIXME proper type
//   }

//   marker: {
//     position: any[] // FIXME proper type
//     speed: number
//   }

//   selected: {
//     type: number
//     index: number
//   }

//   shiftSelected: number | null

//   data: IPointData[]
//   helpers: IHelpers
//   bounds: number[]
//   line: ICoords[]

//   shiftData: IPointData[]
//   shiftHelpers: IHelpers
//   shiftBounds: number[]
//   shiftLine: ICoords[]

//   metrics: {
//     now: number
//     delta: number
//   }
// }

/**
 * Manages points
 */
class MapStore {
  private rootStore: IRootStore
  currentMapCenter: LatLngExpression
  currentZoom: number
  currentBounds: number[]
  // trackFilters: number
  selectedTags: number[]
  search: string
  private devicesList: IDevice[]
  lastTime: { [key: string]: number } // Map<string, number>
  tickerRef: any // FIXME
  private trackingProc: any // FIXME
  top: number
  floor: number
  selected: { id: string | null, prev: string | null, outside: boolean, } // FIXME
  // private trackWorker: Worker
  // track: TrackParams
  private message: MessageType | null
  private prevTrackStatus: boolean
  private allowTails: boolean

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore

    this.currentMapCenter = this.loadMapCenter()
    this.currentZoom = this.loadMapZoom()
    this.currentBounds = fullBounds.slice()

    window.addEventListener("beforeunload", (e: BeforeUnloadEvent) => {
      if (!e.isTrusted) {
        return
      }

      e.preventDefault()
      window.localStorage.setItem('mapCenter', JSON.stringify(this.currentMapCenter))
      window.localStorage.setItem('zoom', JSON.stringify(this.currentZoom))
      // window.localStorage.setItem(filterState, this.trackFilters.toString())
    })

    // TODO filtering
    this.selectedTags = []
    this.search = ''

    this.devicesList = []
    this.lastTime = {} // new Map<string, number>()
    this.tickerRef = null

    this.message = null
    this.prevTrackStatus = false
    this.allowTails = false

    this.trackingProc = undefined

    this.top = 99999
    this.floor = 200

    this.selected = {
      id: null,
      prev: null,
      outside: false,
    }

    // this.trackFilters = (() => {
    //   const savedFilters = window.localStorage.getItem(filterState)
    //   return savedFilters != null ? parseInt(savedFilters) : 1610743807
    // })()

    // Web worker for track processing
    // this.trackWorker = new Worker(trackWorker, { type: 'module' })
    // this.trackWorker.onmessage = this.processTrackResult(this)

    // this.track = {
    //   status: observable({
    //     bounds: null,
    //     requesting: false,
    //     done: false,
    //     length: 0,
    //     startTime: 0, // null,
    //     endTime: 0, // null,
    //   }),
    //   marker: observable({
    //     position: [],
    //     speed: 0,
    //   }),
    //   selected: observable({
    //     type: -99,
    //     index: -99,
    //   }),

    //   shiftSelected: null,

    //   data: [],
    //   helpers: { shifts: [], stops: [], warnings: [] },
    //   bounds: fullBounds,
    //   line: [],

    //   shiftData: [],
    //   shiftHelpers: { shifts: [], stops: [], warnings: [] },
    //   shiftBounds: fullBounds,
    //   shiftLine: [],

    //   metrics: {
    //     now: 0,
    //     delta: 0,
    //   },
    // }

    makeAutoObservable(this, {}, { autoBind: true })

    this.restartMapData()
  }

  setSearch(newSearch: string) {
    this.search = newSearch.toLowerCase()
  }

  setTags(tags: number[]) {
    this.selectedTags = tags
  }

  get hasValid(): boolean {
    for (let i = 0; i < this.devices.length; i++) {
      if (this.devices[i].data.lastValid != null) {
        return true
      }
    }
    return false
  }

  get devicesGeoJSON(): TLastPoint[] {
    const lastPoints: TLastPoint[] = []
    this.devices.forEach((d) => {
      if (d.data.lastValid != null && d.id !== this.selected.id) {
        lastPoints.push({
          id: d.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [d.data.lastValid.coords.lon, d.data.lastValid.coords.lat],
          },
          properties: {
            entityID: d.id,
            lastPoint: toJS(d.data.lastValid)
          },
        })
      }
    })
    return lastPoints
  }

  // FIXME
  // showErrorNotification(message: string, title: string = 'Error') {
  //   // notification['error']
  //   // this.notification =
  //   notification.open({
  //     message: title,
  //     description: typeof message !== 'string' ? message.toString() : message,
  //     duration: 0,
  //     icon: <ExclamationCircleOutlined style={{ fontSize: '1.2em', color: '#ec0e0e' }} />,
  //   })
  // }

  // closeNotification() {
  //   if (this.notification && typeof this.notification === 'function') {
  //     this.notification()
  //   }
  // }

  /**
   * Loads map center
   */
  private loadMapCenter(): LatLngExpression {
    const rawCoords = window.localStorage.getItem('mapCenter')
    if (rawCoords == null) {
      console.warn('Cannot load map center from storage')
      return { lat: 0, lng: 0 }
    }

    let center = JSON.parse(rawCoords) as LatLngLiteral
    if (!center.lat || !center.lng) {
      // return { lat: 0, lon: 0 }
      center = this.findUserLocation()
    }

    return center
  }

  private loadMapZoom(): number {
    const rawZoom = window.localStorage.getItem('zoom')
    if (rawZoom == null) {
      return 14 // Default zoom
    }

    const zoom = Number.parseInt(rawZoom)
    if (zoom <= 0 && zoom > 18) {
      return 14
    }
    return zoom
  }

  setMapCenter(loc: LatLngExpression) {
    this.currentMapCenter = loc
  }

  setZoom(zoom: number) {
    this.currentZoom = zoom
  }

  setBounds(bounds: number[]) {
    this.currentBounds = bounds
  }

  setSelected(id: string, outside: boolean = false) {
    this.selected.id = id
    this.selected.outside = outside
  }

  findUserLocation(): LatLngLiteral {
    const position = { lat: 55.75222, lng: 37.61556 }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentMapCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    }
    return position
  }

  get devices(): IDevice[] {
    return this.devicesList
      .filter(byTags(
        this.rootStore.groups.devicesIndex,
        this.rootStore.groups.categories,
        this.rootStore.groups.attributes,
        this.selectedTags,
      ))
      .filter(filterFunc(this.search))
  }

  findDevice(id: string, outside: boolean = false) {
    // TODO other messages

    message.destroy()

    if (id.length === 0) {
      console.error('Cannot find device, empty id')
      message.warning('Нет данных по объекту', 5)
      return
    }

    const device = this.devicesList.find((d) => d.deviceID === id)
    if (device == null) {
      console.error('Device not found')
      message.warning('Нет данных по объекту', 5)
      return
    }

    const cell = device.data
    // if (cell == null) {
    //   console.error('Device was not being initialized')
    //   message.warning('Нет данных по объекту', 5)
    //   return
    // }

    if (device.serverStatus !== 0) {
      message.error(getServerStatus(device.serverStatus))
      return
    }

    if (device.accountStatus !== 0) {
      message.error(getAccountStatus(device.accountStatus))
      return
    }

    if (cell.description.length > 0) {
      message.warn(getDescriptionText(cell.description))
      return
    }

    // || !cell.lastValid.coords.valid
    if (cell.lastValid == null) {
      console.error('Cannot find at least one point')
      message.warning('Нет данных по объекту', 5)
      return
    }

    this.selected.prev = this.selected.id
    this.selected.id = id
    this.selected.outside = outside

    this.trackingDevice(cell)

    if (this.selected.outside) {
      return
    }

    const center = cell.lastValid.coords
    let x = 0
    const width = this.rootStore.ui.window.size.width
    if (!this.rootStore.ui.mapState.controlBox.collapsed && width != null) {
      const overlay = 340
      x = width / 2 - (width - overlay) / 2
    }

    let y = 0
    const height = this.rootStore.ui.window.size.height
    if (this.rootStore.ui.mapState.trackInfo.visible && height != null) {
      const panel = height / 2
      const po = panel / 2
      y = po / 2
    }

    const offset = {
      x: x,
      y: -y,
    }

    this.centerWithOffset({ lat: center.lat, lng: center.lon }, offset)

    setTimeout(() => {
      const device = this.devicesList.find((dev) => dev.deviceID === id)
      if (!device || !device.params || !device.params.ref) return

      const ref = device.params.ref
      if (!ref && !ref.current && !ref.leafletElement) return

      try {
        // Move device on top
        // ref.current.leafletElement.setZIndexOffset(this.top)
        // ref.current.leafletElement.bringToFront()

        if (typeof ref.current.leafletElement._bringToFront === 'function') {
          ref.current.leafletElement._bringToFront()
        }

        if (typeof ref.current.leafletElement.bringToFront === 'function') {
          ref.current.leafletElement.bringToFront()
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('findDevice ref error', err, ref.current.leafletElement)
        }
      }
    }, 50)
  }

  centerWithOffset(loc: LatLngExpression, offset = { x: 0, y: 0 }) {
    const map = this.rootStore.ui.mapState.ref?.current?.leafletElement
    if (map == null) {
      return
    }

    const targetZoom = this.currentZoom

    // const withOffset = (offset) => this.rootStore.ui.window.mobile ? 0 : offset

    const offsetX = this.rootStore.ui.window.mobile ? 0 : offset.x
    const offsetY = offset.y

    const targetPoint = map.project(loc, targetZoom)

    // const n = map.unproject(targetPoint, targetZoom)
    const p = map.unproject(targetPoint.subtract([offsetX, offsetY]), targetZoom)

    const options = {
      // animate: false,
      animate: true,
      // duration: 1,
      // easeLinearity: 1,
      noMoveStart: true,
    }

    // map.panTo(n, options)
    setTimeout(() => {
      map.panTo(p, options)
    }, 30)

    // map.setView(p, targetZoom)
  }

  centerWithBounds(loc: LatLngExpression) {
    const map = this.rootStore.ui.mapState.ref?.current?.leafletElement
    if (map == null) {
      return
    }

    const targetZoom = 16
    const targetPoint = map.project(loc, targetZoom)
    const p = map.unproject(targetPoint.subtract([150, -500]), targetZoom)
    map.setView(p, 16, { animate: false })
  }

  /**
   * Remove selection from device
   */
  deselect() {
    this.selected.prev = this.selected.id
    this.selected.id = null
    this.stopTracking()

    const device = this.devicesList.find((dev) => dev.deviceID === this.selected.prev)
    if (!device || !device.params || !device.params.ref) return

    const ref = device.params.ref
    if (ref?.current == null) return

    if (ref.current.leafletElement.setZIndexOffset) {
      ref.current.leafletElement.setZIndexOffset(this.floor)
    }

    if (ref.current.leafletElement.bringToBack) {
      ref.current.leafletElement.bringToBack()
    }
  }

  trackingDevice(cell: DeviceDataCell) {
    this.stopTracking()

    // this.rootStore.ui.mapState.deviceTracking.visible = true
    // FIXME tracking move out reaction
    this.trackingProc = reaction(
      () => {
        return cell.lastValid
      },
      (lastValid) => {
        if (this.rootStore.track.data.length === 0 && lastValid != null) {
          const coords = lastValid.coords
          let x = 0
          if (!this.rootStore.ui.mapState.controlBox.collapsed) {
            const width = this.rootStore.ui.window.size.width
            const overlay = 340
            x = width / 2 - (width - overlay) / 2
          }

          let y = 0
          if (this.rootStore.ui.mapState.trackInfo.visible) {
            const height = this.rootStore.ui.window.size.height
            const panel = height / 2
            const po = panel / 2
            y = po / 2
          }

          const offset = {
            x: x,
            y: -y,
          }

          this.centerWithOffset({ lat: coords.lat, lng: coords.lon }, offset)
        } else {
          this.stopTracking()
        }
      },
    )
  }

  /**
   * Stopping tracking process on current device
   */
  stopTracking() {
    if (this.trackingProc) {
      // this.rootStore.ui.mapState.deviceTracking.visible = false
      this.trackingProc()
      this.trackingProc = undefined
    }
  }

  /**
   * req tails
   */
  private async processTails() {
    // this.stopTails()

    // TODO remake to long polling?
    const chunkSize = 50

    let lastTime: { deviceID: string, lastTime: number }[] = []
    let newPoints = []

    const entries = Object.entries(this.lastTime)
    try {


      for (let [deviceID, last] of entries) {
        lastTime.push({
          deviceID: deviceID,
          lastTime: last,
        })

        if (lastTime.length >= chunkSize) {
          // !prod && console.info('Request fired')
          newPoints = await api.tails(lastTime, this.rootStore.profile.user?.userID)
          this.setNewPoint(newPoints)
          lastTime.splice(0, lastTime.length)

          // Kinda sleep
          await new Promise((r) => setTimeout(r, 2000))
        }
      }

      if (lastTime.length > 0) {
        newPoints = await api.tails(lastTime, this.rootStore.profile.user?.userID)
        this.setNewPoint(newPoints)
      }
    } catch (err) {
      !prod && console.info('tails err:', err)
      if (err.name === 'NetworkError' && err.response.status === 401) {
        this.rootStore.profile.logout()
      }
    }

    this.tickerRef = setTimeout(() => {
      if (this.allowTails) {
        this.processTails()
      }
    }, 5000)
  }

  /**
   * Stop tails reguests
   */
  private stopTails() {
    // FIXME sometimes it doesn't work
    this.allowTails = false
    if (this.tickerRef) {
      clearTimeout(this.tickerRef)
      this.tickerRef = null
      !prod && console.info('Tails was stopped')
    }
  }

  /**
   * Restart tracking if was being on and tails
   */
  restartMapData() {
    this.stopTails()
    this.allowTails = true
    this.processTails()
    !prod && console.info('Tails was started')
  }

  /**
   * Stops tracking if was being on and tails
   */
  stopMapData() {
    this.stopTails()
    this.stopTracking()
  }

  private setNewPoint(newPoints: ITail[]) {
    if (newPoints == null || !Array.isArray(newPoints)) {
      console.info('At set new points, given is not an array', newPoints)
      return
    }

    newPoints.forEach((np) => {
      // if (np.imei === '9L01143833') {
      //   console.info('New points: len', np.data.length)
      //   if (np.data.length > 0) console.info('last time', np.data[np.data.length - 1].time)
      // }

      const device = this.devicesList.find((dev) => dev.deviceID === np.deviceID)
      if (device == null) {
        // FIXME add device into list
      }

      device?.data.addData(np.data, np.sensors, np.description)

      const len = np.data.length
      if (len !== 0) {
        const last = np.data[len - 1].time
        this.lastTime[np.deviceID] = last
      }
    })
  }

  // settings.devices, settings.attributes, index
  setDevices(devices: IDeviceSystem[]) {
    this.lastTime = {} // new Map()

    const storeDevices: IDevice[] = []
    devices.forEach((device) => {
      this.lastTime[device.deviceID] = 0
      storeDevices.push(new SystemDevice(device))
    })

    this.devicesList = storeDevices
  }

  // lastTimeNew() {
  //   const lastTime: { deviceID: string, lastTime: number }[] = []
  //   Object.entries(this.lastTime).forEach(([deviceID, last]) => {
  //     lastTime.push({
  //       deviceID: deviceID,
  //       lastTime: last,
  //     })
  //   })
  //   return lastTime
  // }

  // toggleVisibility(id: string) {
  //   const device = this.devicesList.find((dev) => dev.deviceID === id)
  //   if (!device) return
  //   device.params.visible = !device.params.visible
  // }

  // setSelectedHelper(type: number, index: number) {
  //   console.info('Helper selected. type %s, index %s', type, index)
  //   const sameType = this.track.selected.type === type
  //   const sameIndex = this.track.selected.index === index

  //   if (sameType && sameIndex) {
  //     return
  //   }

  //   if (!sameType) {
  //     this.track.selected.type = type
  //   }

  //   if (!sameIndex) {
  //     this.track.selected.index = index
  //   }

  //   let coords = null
  //   switch (type) {
  //     case 0:
  //       if (this.trackHelpers.stops == null || this.trackHelpers.stops.length < index + 1) {
  //         return
  //       }
  //       coords = this.trackHelpers.stops[index].coords
  //       break

  //     case 1:
  //       if (this.trackHelpers.warnings == null || this.trackHelpers.warnings.length < index + 1) {
  //         return
  //       }
  //       coords = this.trackHelpers.warnings[index].coords
  //       break

  //     default:
  //       coords = null
  //   }

  //   if (coords == null) {
  //     return
  //   }

  //   // this.setMapCenter([coords.lat, coords.lon])

  //   // this.centerWithBounds(coords)
  //   const bounds = fullBounds.slice()
  //   updateBounds(bounds, coords)
  //   this.fitBounds(bounds, { x: 100, y: 500 })
  //   // FIXME: dramatical lag! // this.centerWithBounds([coords.lat, coords.lon])
  // }

  /**
   * Unset selected marker
   */
  // unsetSelectedHelper() {
  //   this.track.selected.type = -99
  //   this.track.selected.index = -99
  // }

  // setShift(index: number) {
  //   if (index === this.track.shiftSelected) {
  //     return
  //   }

  //   this.track.shiftSelected = index

  //   if (index == null) {
  //     this.unsetSelectedHelper()

  //     if (this.track.line.length > 0) {
  //       this.fitBounds(this.trackBounds, { x: 150, y: 500 })
  //     }
  //     return
  //   }

  //   const hasCoords = this.track.line.length > 0
  //   if (!hasCoords) {
  //     this.track.shiftData = []
  //     this.track.shiftLine = []
  //     this.track.shiftBounds = fullBounds.slice()
  //     return
  //   }

  //   const currentShift = this.track.helpers.shifts[index]
  //   const start = +parseISO(currentShift.timeBegin)
  //   const end = +parseISO(currentShift.timeEnd)

  //   const bounds = fullBounds.slice()
  //   const shiftLine: ICoords[] = []
  //   const shiftTrack: IPointData[] = []

  //   this.track.data.forEach((point) => {
  //     if (point.time >= start && point.time <= end) {
  //       // recalcBounds(bounds, point.coords)
  //       updateBounds(bounds, point.coords)
  //       shiftLine.push(point.coords)
  //       shiftTrack.push(point)
  //     }
  //   })

  //   const filterFunc = (entity: IHelper) => {
  //     if (entity.timeBegin >= currentShift.timeBegin && entity.timeEnd <= currentShift.timeEnd) {
  //       return entity
  //     }
  //   }

  //   const rawStops = toJS(this.track.helpers.stops)
  //   const rawWarnings = toJS(this.track.helpers.warnings)

  //   this.track.shiftData = shiftTrack
  //   this.track.shiftLine = shiftLine
  //   this.track.shiftBounds = bounds
  //   this.track.shiftHelpers.stops = rawStops.filter(filterFunc)
  //   this.track.shiftHelpers.warnings = rawWarnings.filter(filterFunc)

  //   if (shiftLine.length > 0) {
  //     this.fitBounds(bounds, { x: 150, y: 500 })
  //   }
  // }

  // showDevice(id: string) {
  //   // TODO Change color?
  //   this.selected.id = id
  // }

  // tracking(id: string) {
  //   const device = this.devicesList.find((d) => d.deviceID === id)
  //   if (device == null) {
  //     return
  //   }

  //   const coords = device.data.points[device.data.points.length - 1].coords
  //   this.setMapCenter({ lat: coords.lat, lng: coords.lon })
  //   this.trackingProc = reaction(
  //     () => {
  //       return {
  //         len: device.data.points.length,
  //         points: device.data.points,
  //       }
  //     },
  //     ({ len, points }) => {
  //       this.setMapCenter({ lat: points[len - 1].coords.lat, lng: points[len - 1].coords.lon })
  //     },
  //   )
  // }

  /**
   * Closeing message
   */
  private closeMessage() {
    if (typeof this.message === 'function') {
      this.message()
    }
  }

  // requestTrack(deviceID: string, from: string, to: string) {
  //   this.stopTracking()
  //   this.dropTrack()
  //   this.closeMessage()

  //   const request = {
  //     deviceID: deviceID,
  //     from: from,
  //     to: to,
  //     shiftVars: 'Distance', // TODO соединить через запятую
  //   }

  //   this.track.status.startTime = +(new Date())
  //   this.message = message.loading('Загружаем трек...', 0)
  //   this.track.status.requesting = true
  //   this.track.shiftSelected = null
  //   this.track.selected.index = -99
  //   this.track.selected.type = -99

  //   api.track(request, this.rootStore.profile.user.userID)
  //     .then(this.receiveTrack)
  //     .catch((err) => {
  //       if (!prod) console.info('Track request err', err)
  //       if (err.name === 'NetworkError' && err.response.status === 401) this.rootStore.profile.logout()

  //       this.track.status.requesting = false
  //       this.track.status.done = true
  //       this.rootStore.ui.mapState.trackInfo.allow = true
  //       this.rootStore.ui.mapState.trackInfo.visible = true
  //       this.closeMessage()
  //       this.message = message.error('Ошибка загрузки трека')
  //     })
  // }

  // receiveTrack(track: ITrack) {
  //   if (!track || track.data.length === 0 || !track.helpers) {
  //     this.closeMessage()
  //     this.track.status.requesting = false
  //     this.track.status.done = true
  //     this.rootStore.ui.mapState.trackInfo.allow = true
  //     this.rootStore.ui.mapState.trackInfo.visible = true
  //     this.message = message.warning('Нет данных за выбранный период')
  //     return
  //   }

  //   this.closeMessage()
  //   this.message = message.loading('Трек загружен, обрабатываем...', 0)

  //   this.track.metrics.now = +(new Date())
  //   this.track.metrics.delta = +(new Date()) - this.track.status.startTime

  //   this.trackWorker.postMessage({ track: track })
  // }

  // processTrackResult(self: MapStore) {
  //   return ({ data }: any) => {
  //     runInAction(() => {
  //       if (data.error) {
  //         self.closeMessage()
  //         self.track.status.requesting = false
  //         self.track.status.done = true

  //         switch (data.error.name) {
  //           case 'ErrNoValidPoints':
  //             self.message = message.warning('Нет валидных точек')
  //             return

  //           default:
  //             console.info('Track processing error', data.error)
  //             self.message = message.warning('Ошибка обработки трека')
  //         }
  //       }

  //       self.closeMessage()

  //       const bounds = fullBounds.slice()
  //       const trackCoords = data.points.map((point: IPointData) => {
  //         updateBounds(bounds, point.coords)
  //         return point.coords
  //       })

  //       self.track.bounds = bounds
  //       self.track.data = data.points
  //       self.track.line = trackCoords
  //       self.track.helpers = data.helpers
  //       self.track.shiftHelpers = Object.assign({}, data.helpers)

  //       const d2 = +(new Date()) - self.track.metrics.now
  //       const dur1 = getReadableDuration(self.track.metrics.delta)
  //       const dur2 = getReadableDuration(d2)
  //       const durTtl = getReadableDuration(self.track.metrics.delta + d2)

  //       self.message = message.success('Трек загружен', 5)
  //       console.info(`Трек загружен за ${dur1}, обработан за ${dur2}. Всего за ${durTtl}`)

  //       self.track.status.requesting = false
  //       self.track.status.done = true
  //       self.track.status.length = data.points.length
  //       self.track.selected.index = -99
  //       self.track.selected.type = -99

  //       if (trackCoords.length > 0) {
  //         self.fitBounds(bounds, { x: 150, y: 500 })
  //       }
  //     })
  //   }
  // }

  // get trackData() {
  //   if (this.track.shiftSelected == null) return this.track.data
  //   const shift = this.track.helpers.shifts[this.track.shiftSelected]
  //   if (shift.shiftType === 9) return this.track.data
  //   return this.track.shiftData
  // }

  // get trackHelpers() {
  //   if (this.track.shiftSelected == null) return this.track.helpers
  //   const shift = this.track.helpers.shifts[this.track.shiftSelected]
  //   if (shift.shiftType === 9) return this.track.helpers
  //   return this.track.shiftHelpers
  // }

  // get trackLine() {
  //   if (this.rootStore.ui.mapState.ref?.current?.leafletElement == null) {
  //     return []
  //   }

  //   if (this.track.shiftSelected == null || this.track.helpers.shifts[this.track.shiftSelected].shiftType === 9) {
  //     return vertexClusterReduction(this.rootStore.ui.mapState.ref?.current?.leafletElement, this.currentZoom, this.track.data)
  //   }
  //   return vertexClusterReduction(this.rootStore.ui.mapState.ref?.current?.leafletElement, this.currentZoom, this.track.shiftData)
  // }

  // get trackArrows() {
  //   if (this.rootStore.ui.mapState.ref?.current?.leafletElement == null) {
  //     return []
  //   }

  //   return getArrows(this.rootStore.ui.mapState.ref?.current?.leafletElement, this.currentZoom, this.trackLine)
  // }

  // get trackBounds() {
  //   if (this.track.shiftSelected == null) return this.track.bounds
  //   const shift = this.track.helpers.shifts[this.track.shiftSelected]
  //   if (shift.shiftType === 9) return this.track.bounds
  //   return this.track.shiftBounds
  // }

  fitBounds(bounds: number[], offset = { x: 0, y: 0 }) {
    // const corner1 = { lat: bounds[0], lng: bounds[1] }
    // const corner2 = { lat: bounds[2], lng: bounds[3] }
    // FIXME maybe flip?
    // const corner1 = [bounds[0], bounds[1]]
    // const corner2 = [bounds[2], bounds[3]]

    if (this.rootStore.ui.mapState.ref?.current?.leafletElement == null) {
      return
    }

    const targetOffset = Object.assign({}, { x: 0, y: 0 }, offset)

    const options = {
      paddingTopLeft: new Point(300 + targetOffset.x, 100),
      paddingBottomRight: new Point(50, 0 + targetOffset.y),
    }

    // map.panTo(n, options)
    setTimeout(() => {
      const corners = new LatLngBounds({ lat: bounds[0], lng: bounds[1] }, { lat: bounds[2], lng: bounds[3] })
      this.rootStore.ui.mapState.ref?.current?.leafletElement.fitBounds(corners, options)
    }, 30)
  }

  // dropTrack() {
  //   this.track.status.requesting = false
  //   this.track.status.done = false
  //   this.track.status.length = 0
  //   this.track.data = []
  //   this.track.helpers = { shifts: [], stops: [], warnings: [] }
  // }

  // FIXME proper type or move into separate track store
  // setMarker(marker: any) {
  //   this.track.marker.position = marker.position
  //   this.track.marker.speed = marker.speed
  // }

  // setFilters(filter: number) {
  //   this.trackFilters = filter
  //   // window.localStorage.setItem(filterState, filter)
  // }

  // isStopsAllowed(): boolean {
  //   const stops = 30
  //   return this.isWarningAllowed(stops)
  // }

  // isCPAllowed(): boolean {
  //   const cp = 29
  //   return this.isWarningAllowed(cp)
  // }

  // isWarningAllowed(warning: number): boolean {
  //   if (!warning || typeof warning !== 'number') return false

  //   return isWarningAllowed(this.trackFilters, warning)
  // }
}

export default MapStore
