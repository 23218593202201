import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Tabs from 'antd/es/tabs'
import Card from 'antd/es/card'


import { LogConfig } from './LogConfig'
import { Reports } from './Reports'
import api from '../../tools/api'


import styles from './AdminPanel.module.css'


const performRequest = (method, payload, callback) => {
  api.service(method, payload)
    .then((resp) => {
      if (resp.error) {
        callback(null, resp.error)
        return
      }
      callback(resp.result, null)
    })
    .catch((err) => {
      let code = -1
      let message = ''

      if (err.response && err.response.data) {
        code = err.response.data.code
        message = err.response.data.message
      } else {
        if (err.response && err.response.status) {
          code = err.response.status
        }
        message = `${err.name}: ${err.message}`
      }

      callback(null, { code: code, message: message })
    })
}

const AdminPanel = (props) => {
  return (
    <div className={styles.container}>
      <h3>Admin Panel [ <Link to='/logout'>logout</Link> ]</h3>

      <Tabs defaultActiveKey="1" animated={false}>
        <Tabs.TabPane tab="Config" key="1">
          <div className={styles.container}>
            <Card title={'Log level'}>
              <LogConfig onAction={performRequest} />
            </Card>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Reports" key="2">
          <Reports onAction={performRequest} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

/**
 * AdminPanel component
 */
// export class OldAdminPanel extends React.Component {
//   /**
//    * @param {Object} props
//    */
//   constructor(props) {
//     super(props)

//     this.state = {
//       pageLoading: true,
//       loading: false,
//       error: false,
//       logLevel: '',
//       telematic: {},
//     }

//     this.onLogLevelChange = this.onLogLevelChange.bind(this)
//     this.showTelematicStatus = this.showTelematicStatus.bind(this)
//   }

//   /**
//    * @param {string} value
//    */
//   onLogLevelChange(value) {
//     this.setState({ loading: true })

//     api.service.log(value)
//         .then((resp) => {
//           this.setState({ logLevel: resp.log_level })
//         })
//         .catch((err) => {
//           console.log('Error on log level update request', err)
//           this.setState({ error: true })
//         })
//         .finally(() => {
//           this.setState({ loading: false })
//         })
//   }

//   /**
//    * @return {Object}
//    */
//   showTelematicStatus() {
//     if (!this.state.telematic) return 'Nothing to show'

//     const servers = this.state.telematic.Servers
//     const clients = this.state.telematic.Clients
//     const registry = this.state.telematic.Registry

//     const serversList = []
//     servers.forEach((s) => {
//       const devices = []
//       s.Devices.forEach((d) => {
//         devices.push(`${d.imei} (ID: ${d.id}), Account: ${d.account_id}`)
//       })

//       serversList.push({
//         text: `${s.Name} (${s.Login}@${s.Host}:${s.Port})`,
//         id: s.ID,
//         devices: devices,
//       })
//     })

//     const clientsList = []
//     registry.forEach((entry) => {
//       const uid = entry.user.id
//       const sessions = clients[uid]

//       const stext = sessions ? `Active sessions: ${sessions.length}` : 'No active sessions'

//       const accounts = []
//       entry.accounts.forEach((acc) => {
//         accounts.push(`${acc.title} (${acc.login}@${acc.host}:${acc.http_port})`)
//       })

//       clientsList.push({
//         text: `${entry.user.first_name} ${entry.user.last_name}`, //  (${entry.role})
//         sessions: stext,
//         accounts: accounts,
//       })
//     })

//     const serversCount = serversList.length
//     const clientsCount = clientsList.length
//     const sharedCount = 0

//     if (serversCount === 0 && clientsCount === 0 && sharedCount === 0) {
//       return 'Nothing to show'
//     }

//     const gridParams = { gutter: 16, column: 2 }

//     return (
//       <Tabs defaultActiveKey="1">
//         {serversCount && <TabPane tab="Servers" key="1">
//           <List
//             size="small"
//             grid={gridParams}
//             dataSource={serversList}
//             renderItem={({ text, id, devices }) => <List.Item>
//               <Card title={text}>
//                 <div>ID: {id}</div>
//                 <div>Devices:</div>
//                 <ul>{devices.map((d, key) => <li key={key}>{d}</li>)}</ul>
//               </Card>
//             </List.Item>}
//           />
//         </TabPane>}

//         {clientsCount && <TabPane tab="Clients" key="2">
//           <List
//             size="small"
//             grid={gridParams}
//             dataSource={clientsList}
//             renderItem={({ text, sessions, accounts }) => <List.Item>
//               <Card title={text}>
//                 <div>{sessions}</div>
//                 <div>Accounts:</div>
//                 <ul>{accounts.map((a, key) => <li key={key}>{a}</li>)}</ul>
//               </Card>
//             </List.Item>}
//           />
//         </TabPane>}

//         {sharedCount && <TabPane tab="Shared" key="3">
//           Shared link clients TODO
//         </TabPane>}
//       </Tabs>
//     )
//   }

//   /**
//    * Std after mount hook
//    */
//   componentDidMount() {
//     api.service.log()
//         .then((resp) => {
//           this.setState({ logLevel: resp.log_level })
//         })
//         .then(api.service.telematic)
//         .then(({ info }) => {
//           this.setState({ telematic: info })
//         })
//         .catch((err) => {
//           console.log('Error on log level request', err)
//           this.setState({ error: true })
//         })
//         .finally(() => {
//           this.setState({ pageLoading: false })
//         })
//   }

//   /**
//    * @return {Object}
//    */
//   render() {
//     if (this.state.pageLoading) {
//       return <div>Loading...</div>
//     }

//     return (
//       <div style={pageStyle}>
//         <h3>Dummy Admin Panel <Link to='/logout'>logout</Link></h3>

//         <div>Log level: <Select
//           style={selectStyle}
//           defaultValue={this.state.logLevel}
//           onChange={this.onLogLevelChange}
//           loading={this.state.loading}
//         >
//           {logLevels.map((level, key) => (
//             <Select.Option key={key} value={level.value}>
//               {level.text}
//             </Select.Option>
//           ))}
//         </Select>
//         </div>

//         <div style={telematicStyle}>
//           <h4>Telematic status</h4>
//           {this.showTelematicStatus()}
//         </div>
//       </div>
//     )
//   }
// }

AdminPanel.propTypes = {
  rootStore: PropTypes.object.isRequired,
}

export default inject('rootStore')(observer(withRouter(AdminPanel)))
