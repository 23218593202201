import L from 'leaflet'

// ${strokeColor}
export const reloadDivIcon = (
    size,
    fillColor = '#ffbf00',
    strokeColor = '#000'
) => new L.DivIcon({
    html: `<?xml version="1.0" encoding="UTF-8"?>
    <svg
        width="${size}"
        height="${size}"
        version="1.1"
        viewBox="0 0 376.8 376.8"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
        <g fill="${strokeColor}" transform="matrix(.3481 0 0 .3481 29.64 29.64)">
            <path d="m807.2 170.1v83.97c-6.033-10.46-12.53-20.67-19.51-30.61-\
24.44-34.76-54.04-65.36-87.98-90.94-34.35-25.88-72.34-46.01-112.9-59.83-41.96-\
14.29-85.89-21.53-130.6-21.53-46.66 0-92.43 7.883-136 23.43-42.14 15.02-81.\
3 36.85-116.4 64.86-34.75 27.74-64.54 60.75-88.53 98.12-24.44 38.07-42.19 79.\
62-52.75 123.5-6.783 28.19 10.57 56.54 38.76 63.32 4.128 0.993 8.259 1.469 12.\
32 1.469 23.7 0 45.21-16.17 51-40.23 15.47-64.29 52.65-122.6 104.7-164.1 26-20.\
75 54.99-36.91 86.16-48.02 32.25-11.5 66.15-17.33 100.8-17.33 65.67 0 128 20.\
82 180.3 60.21 35.38 26.66 64.54 61.16 85.14 100.1h-58.17c-28.99 0-52.5 23.5-\
52.5 52.5s23.51 52.5 52.5 52.5h196.2c29 0 52.5-23.5 52.5-52.5v-198.9c0-29-23.5-\
52.5-52.5-52.5-28.99 0-52.5 23.5-52.5 52.5z"/>
            <path d="m52.5 794.6c29 0 52.5-23.5 52.5-52.5v-84.33c31.28 54.\
44 74.82 101 127.7 136 66.25 43.94 143.4 67.19 223.2 67.25 0.\
044 0 0.087 4e-3 0.13 4e-3 0.035 0 0.071-2e-3 0.106-2e-3 0.\
041 0 0.083 2e-3 0.124 2e-3 0.056 0 0.109-4e-3 0.166-4e-3 46.52-\
0.045 92.16-7.924 135.6-23.43 42.14-15.02 81.3-36.85 116.4-64.\
86 34.75-27.73 64.54-60.75 88.54-98.12 24.44-38.07 42.19-79.62 52.\
75-123.5 6.783-28.19-10.57-56.54-38.76-63.32s-56.54 10.57-63.32 38.\
76c-15.47 64.29-52.65 122.6-104.7 164.1-26 20.75-54.99 36.91-86.\
16 48.02-32.22 11.49-66.08 17.32-100.7 17.33-59.15-0.023-116.3-17.\
23-165.4-49.76-42.3-28.05-76.56-66.01-100-110.5h58.03c29 0 52.5-23.\
51 52.5-52.5 0-29-23.5-52.5-52.5-52.5h-196.2c-29 0-52.5 23.5-52.\
5 52.5v198.9c0 29 23.5 52.5 52.5 52.5z"/>
        </g>
    </svg>`,
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    className: '',
  })
