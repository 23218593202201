import ky from 'ky';


import { debugTails, prod } from '../common'
import {
  AttributeEntity,
  IReport,
  IReportRequest,
  IReportTemplate,
  IReportVars,
  ISensorsRequest,
  ITail,
  ISensor,
  ITailRequest,
  ITrack,
  ITrackRequest,
  IUser,
  IGeozone,
  IAttribute,
  ICategory,
  IAttributeIndex,
  IDevice,
  RPCResponse,
} from '../stores/interfaces';


interface DeviceAttach {
  userID: string
  deviceID: string
}

interface GeozoneAttach {
  geozoneID: string
}

type AttributeAttach = GeozoneAttach | DeviceAttach


const generateRequestID = (): string => {
  return Math.floor(Math.random() * 1000000).toString()
}

const mapsURL = process.env.REACT_APP_MAPS_URL

export const call = async (method: string, params?: any, userID?: string, noRedirect?: boolean) => {
  const requestOptions = {
    method: 'post',
    timeout: 120000,
    json: {
      jsonrpc: '2.0',
      id: generateRequestID(),
      method: method,
      ...(params != null && { params: params }),
    },
    headers: {
      ...(userID != null && userID.length > 0 && { 'X-Current-User-Id': userID }),
    },
  }

  try {
    const resp: RPCResponse = await ky('/api/', requestOptions).json()

    if (resp.error) {
      throw resp.error
    }

    if (!prod) {
      if ((debugTails && method === 'tail.get') || method !== 'tail.get') {
        console.info(method + ' call result:', resp.result)
      }
    }
    return resp.result
  } catch (err) {
    if (err.code === -32001 && method !== 'signout' && !noRedirect) { // TODO change to errors.unauthorized
      window.location.href = `${mapsURL}/login?redirect=` + encodeURIComponent(window.location.href)
    }

    !prod && console.info(method + ' call error:', err)
    throw err
  }
}

const getUsers = (ownerID: string, isStaff: boolean): Promise<IUser[]> =>
  call('users.get', { userID: ownerID, isStaff: isStaff }) as Promise<IUser[]>

const api = {
  signout: () => call('signout'),
  currentUser: (noRedirect?: boolean): Promise<IUser> => call('users.current', null, '', noRedirect) as Promise<IUser>,
  user: (userID: string): Promise<IUser> => call('users.get', { userID: userID }) as Promise<IUser>,
  clients: (ownerID: string) => getUsers(ownerID, false),
  staff: (ownerID: string) => getUsers(ownerID, true),
  devices: (ownerID: string): Promise<IDevice[]> => call('devices.list', { userID: ownerID }) as Promise<IDevice[]>,
  tails: (req: ITailRequest[], userID?: string): Promise<ITail[]> => call('tail.get', req, userID) as Promise<ITail[]>,
  track: (req: ITrackRequest, userID?: string): Promise<ITrack> => call('track.get', req, userID) as Promise<ITrack>,

  // TODO categories?
  categories: {
    list: (userID: string): Promise<ICategory[]> =>
      call('categories.list', { userID: userID }) as Promise<ICategory[]>,

    count: (userID: string) => call('categories.count', { userID: userID }),

    delete: (categoryID: string) => call('categories.delete', { categoryID: +categoryID }),

    create: (userID: string, label: string): Promise<ICategory> =>
      call('categories.create', { userID: userID, label: label }) as Promise<ICategory>,

    update: (categoryID: number, label: string): Promise<ICategory> =>
      call('categories.update', { categoryID: categoryID, label: label }) as Promise<ICategory>,
  },

  attributes: {
    list: (userID: string): Promise<IAttribute[]> =>
      call('attributes.list', { userID: userID }) as Promise<IAttribute[]>,

    count: (userID: string) => call('attributes.count', { userID: userID }),
    delete: (attributeID: number) => call('attributes.delete', { attributeID: attributeID }),

    create: (categoryID: number, label: string): Promise<IAttribute> =>
      call('attributes.create', { categoryID: categoryID, label: label }) as Promise<IAttribute>,

    update: (attributeID: number, label: string): Promise<IAttribute> =>
      call('attributes.update', { attributeID: attributeID, label: label }) as Promise<IAttribute>,

    index: (userID: string, entity: AttributeEntity): Promise<IAttributeIndex> =>
      call('attributes.index', { userID: userID, entity: entity }) as Promise<IAttributeIndex>,

    attach: (entity: AttributeEntity, attributeID: number, entityID: AttributeAttach[]) => call('attributes.attach', {
      entity: entity,
      attributeID: attributeID,
      entityID: entityID,
    }),
    detach: (entity: AttributeEntity, attributeID: number, entityID: AttributeAttach[]) => call('attributes.detach', {
      entity: entity,
      attributeID: attributeID,
      entityID: entityID,
    }),
  },

  reports: {
    get: (req: IReportRequest, userID?: string): Promise<IReport> => call('reports.get', req, userID) as Promise<IReport>,
    templates: (userID?: string): Promise<IReportTemplate> => call('reports.templates', null, userID) as Promise<IReportTemplate>,
    vars: (deviceID: string, userID?: string): Promise<IReportVars> => call('reports.vars', { deviceID: deviceID }, userID) as Promise<IReportVars>,
  },

  sensors: {
    get: (deviceID: string, userID?: string): Promise<ISensor[]> => call('sensors.get', deviceID, userID) as Promise<ISensor[]>,
    values: (req: ISensorsRequest, userID?: string): Promise<{ [index: string]: any }> => call('sensors.values', req, userID) as Promise<{ [index: string]: any }>,
  },

  geozones: {
    // TODO
    list: (userID: string): Promise<IGeozone[]> => call('geozones.list', { userID: userID }) as Promise<IGeozone[]>,
    create: (userID: string, geo: IGeozone): Promise<IGeozone> => call('geozones.create', {
      userID: userID,
      geozone: geo,
    }) as Promise<IGeozone>,
    update: (geo: IGeozone): Promise<IGeozone> => call('geozones.update', { geozone: geo }) as Promise<IGeozone>,
    delete: (geozoneID: string): Promise<any> => call('geozones.delete', { geozoneID: geozoneID }) as Promise<any>,
  },
}

export default api
