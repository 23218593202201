import { makeAutoObservable, runInAction } from 'mobx'
import api from '../api'


import { IRootStore, IUser } from './interfaces'


const geozonesFeature = 'geozones'

/**
 * Stores user profile data
 */
class ProfileStore {
  private rootStore: IRootStore
  loading: boolean
  features: string[]
  invoker: IUser | null
  user: IUser | null

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore

    this.loading = false
    this.features = []

    this.invoker = null
    this.user = null

    makeAutoObservable(this, {}, { autoBind: true })
  }

  setInvoker(invoker: IUser) {
    this.invoker = invoker
  }

  async getInvoker(redirect: boolean) {
    const user = await api.currentUser(redirect)
    runInAction(() => {
      this.setInvoker(user)
    })
  }

  async getSettings(user: IUser) {
    const devices = await api.devices(user.userID)
    // const categories = await api.categories.list(user.userID)
    // const attributes = await api.attributes.list(user.userID)

    // const index = await api.attributes.index(user.userID, 'devices')

    runInAction(() => {
      // if (this.invoker == null) {
      //   this.setInvoker(user)
      // }

      this.user = user
      this.rootStore.map.setDevices(devices)
      this.features = featuresFromPermissions(user.softwarePermissions)

      this.rootStore.geo.clear()
    })

    await new Promise((r) => setTimeout(r, 100))
    return 'ok'
  }

  get allowGeozones(): boolean {
    return this.features.includes(geozonesFeature)
  }

  allowFeature(feature: string): boolean {
    return this.features.includes(feature)
  }

  logout() {
    this.invoker = null
    this.user = null
  }
}

export default ProfileStore


const featureFlags = [
  0x01,
  0x02,
  0x04,
  0x08,
  0x10,
  0x20,
  0x40,
  1 << 16,
  1 << (16 + 1),
  1 << (16 + 2),
  1 << (16 + 3),
  1 << (16 + 4),
  1 << (16 + 5),
]

const featureNames = [
  'informer',
  'dbloader',
  'liconfig',
  'tsconfig',
  'geozones',
  'reports',
  'plugins',
  'reportsVisibility',
  'tracks',
  'charts',
  'sharedLinks',
  'telematic',
  'tags',
]

const featuresFromPermissions = (softwarePermissions: number) => {
  let aflags = []
  const len = featureFlags.length
  for (let i = 0; i < len; i++) {
    if ((softwarePermissions & featureFlags[i]) === 0) continue
    aflags.push(featureNames[i])
  }
  return aflags
}

