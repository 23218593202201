import React from 'react'
import PropTypes from 'prop-types'
import { parseJSON } from 'date-fns'

import { getReadableDuration, getReadableTime } from '../common'

const cardStyle = { padding: '10px', minWidth: '250px' }

export const WarningHelperCard = ({ warning, onClick, asTableRow, id }) => {
  const start = parseJSON(warning.timeBegin)
  const end = parseJSON(warning.timeEnd)
  const delta = end - start

  const startString = getReadableTime(start)
  const endString = getReadableTime(end)
  const durationString = getReadableDuration(delta)

  const onCardClick = () => {
    onClick(id, warning.coords)
  }

  if (asTableRow) {
    return (
      <tr onClick={onCardClick} style={onClick ? { cursor: 'pointer' } : {}}>
        <td>
          {startString}
        </td>

        <td>
          {endString}
        </td>

        <td>
          {durationString}
        </td>

        <td>
          {warning.warningMessage}
        </td>
      </tr>
    )
  }

  return (
    <div style={cardStyle}>
      <h3 onClick={onCardClick} style={onClick ? { cursor: 'pointer' } : {}}>Предупреждение</h3>

      <div>
        {'Начало: ' + startString}
      </div>

      <div>
        {'Конец: ' + endString}
      </div>

      <div>
        {'Продолжительность: ' + durationString}
      </div>

      <div>
        {warning.warningMessage}
      </div>
    </div>
  )
}

WarningHelperCard.propTypes = {
  warning: PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    warningType: PropTypes.number.isRequired,
    warningMessage: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      dir: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }),
  }),
  asTableRow: PropTypes.bool,
}

