import React from 'react'
import { isMobile } from 'react-device-detect'


import MobileControlPanel from './MobileControlPanel'
import SideControlPanel from './SideControlPanel'


interface Props {
  features: any[] // TODO type MapFeature[]
}


const Panel: React.FC<Props> = (props) => {
  if (isMobile) {
    return <MobileControlPanel features={props.features} />
  }
  return <SideControlPanel features={props.features} />
}

export default Panel
