import { LatLngTuple } from "leaflet"
import { makeAutoObservable, toJS } from "mobx"


import { colorUtils, geodesicArea, polygonCircleRadius } from "../tools"
import {
  IGeozone,
  IGeozoneAdditionalParams,
  IGeozoneExtended,
  IGeozoneProps,
  IGeozoneShape,
  IGeozoneUpdate,
  IVisualProps,
} from "./interfaces"


const defaultGeozoneParams = {
  title: 'Без названия',
  description: '',
  address: '',
  divide_track: false,
  fix_time: 0,
}

const defaultVisual = {
  fill: 4290268570,
  stroke: 3867803878,
  stroke_weight: 3,
}

const defaultGeozoneVisualProps = {
  color: '#000000',
  opacity: 1,
  fillColor: '#ffffff',
  fillOpacity: 0.5,
  weight: 3,
}


export class SystemGeozone implements IGeozoneExtended { // TODO class label?
  id: string
  readonly shape: IGeozoneShape
  title: string
  description: string
  address: string
  center: LatLngTuple
  bbox: number[]
  radius: number
  coordinates: LatLngTuple[]
  area: number
  divideTrack: boolean
  fixTime: number
  private innerProps: IGeozoneProps
  fillColor: string
  fillOpacity: number
  color: string
  opacity: number
  weight: number

  constructor(g: IGeozone) {
    this.id = g.id
    this.shape = g.shape
    this.title = g.title
    this.description = g.description
    this.address = g.address
    this.center = g.center
    this.bbox = g.bbox
    this.radius = g.radius
    this.coordinates = g.coordinates
    this.area = g.area
    this.divideTrack = g.divide_track
    this.fixTime = g.fix_time
    this.innerProps = g.props

    const v = getValidVisuals(g.visual_props)
    this.fillColor = v.fillColor
    this.fillOpacity = v.fillOpacity
    this.color = v.color
    this.opacity = v.opacity
    this.weight = v.weight

    makeAutoObservable(this, {}, { autoBind: true })
  }

  setID(id: string): void {
    this.id = id
  }

  setTitle(title: string): void {
    this.title = title
  }

  setDescription(description: string): void {
    this.description = description
  }

  setAddress(address: string): void {
    this.address = address
  }

  setFillColor(color: string): void {
    this.fillColor = color
  }

  setFillOpacity(opacity: number): void {
    this.fillOpacity = opacity
  }

  setColor(color: string): void {
    this.color = color
  }

  setOpacity(opacity: number): void {
    this.opacity = opacity
  }

  setWeight(weight: number): void {
    this.weight = weight
  }

  addProp(key: string, value: any): void {
    if (this.innerProps == null) {
      this.innerProps = {}
    }

    this.innerProps[String(key)] = value
  }

  delProp(key: string): void {
    if (this.innerProps == null) {
      return
    }

    delete this.innerProps[String(key)]
  }

  updateFromLayer(updated: IGeozoneUpdate): void {
    if (updated.shape !== this.shape) {
      throw new Error('invalid shape. Was ' + this.shape + ', got ' + updated.shape)
    }

    const aparams = SystemGeozone.calculateParams(updated)

    this.center = aparams.center
    this.bbox = aparams.bbox
    this.radius = aparams.radius
    this.area = aparams.area
    this.coordinates = aparams.coordinates
  }

  get props() {
    if (this.innerProps == null) {
      return []
    }

    return Object.keys(this.innerProps).map((key) => ({
      key: key,
      value: this.innerProps[key],
    })).sort((a, b) => {
      if (a.key > b.key) {
        return 1
      }
      if (a.key < b.key) {
        return -1
      }
      return 0
    })
  }

  get idGeozone(): IGeozone {
    const visualProps: IVisualProps = {
      fill: colorUtils.toColor(this.fillColor, this.fillOpacity),
      stroke: colorUtils.toColor(this.color, this.opacity),
      stroke_weight: this.weight,
    }

    return {
      id: this.id,
      shape: this.shape,
      title: this.title,
      description: this.description,
      address: this.address,
      center: this.center,
      bbox: this.bbox,
      radius: this.radius,
      coordinates: this.coordinates,
      area: this.area,
      divide_track: this.divideTrack,
      fix_time: this.fixTime,
      props: toJS(this.innerProps),
      visual_props: visualProps,
    }
  }

  equals(target: IGeozoneExtended): boolean {
    const c = this.idGeozone
    const t = target.idGeozone

    return c.id === t.id &&
      c.shape === t.shape &&
      c.title === t.title &&
      c.description === t.description &&
      c.address === t.address &&
      this.equalsCenter(t.center) &&
      this.equalsBounds(t.bbox) &&
      c.radius === t.radius &&
      this.equalsCoordinates(t.coordinates) &&
      this.equalArea(t.area) &&
      c.divide_track === t.divide_track &&
      c.fix_time === t.fix_time &&
      this.equalProps(t.props) &&
      this.equalVisualProps(c.visual_props, t.visual_props)
  }

  // FIXME maybe add threshold?
  private equalsPoint(current: LatLngTuple, target: LatLngTuple): boolean {
    return current[0] === target[0] && current[1] === target[1]
  }

  private equalsCenter(target: LatLngTuple): boolean {
    return this.equalsPoint(this.center, target)
  }

  // FIXME maybe add threshold?
  private equalsBounds(target: number[]): boolean {
    return this.bbox[0] === target[0] && this.bbox[1] === target[1] &&
      this.bbox[2] === target[2] && this.bbox[3] === target[3]
  }

  private equalsCoordinates(target: LatLngTuple[]): boolean {
    if (this.coordinates.length !== target.length) {
      return false
    }

    for (let i = 0; i < target.length; i++) {
      if (!this.equalsPoint(this.coordinates[0], target[0])) {
        return false
      }
    }
    return true
  }

  private equalArea(targetArea: number): boolean {
    // return this.area === targetArea
    return Math.round(this.area) === Math.round(targetArea)
  }

  private equalProps(target: IGeozoneProps): boolean {
    for (let key in target) {
      if (this.innerProps[String(key)] == null) {
        return false
      }

      if (this.innerProps[String(key)] !== target[String(key)]) {
        return false
      }
    }

    for (let key in this.innerProps) {
      if (target[String(key)] == null) {
        return false
      }
    }
    return true
  }

  private equalVisualProps(current: IVisualProps, target: IVisualProps): boolean {
    return current.fill === target.fill && current.stroke === target.stroke
      && current.stroke_weight === target.stroke_weight
  }

  copy(): IGeozoneExtended {
    return SystemGeozone.copy(this)
  }

  static calculateParams(updated: IGeozoneUpdate): IGeozoneAdditionalParams {
    const aparams: IGeozoneAdditionalParams = {
      center: updated.center,
      bbox: updated.bbox,
      radius: -1,
      area: -1,
      coordinates: [],
    }

    switch (updated.shape) {
      case 'checkpoint':
        const radius = Math.round(updated.radius)

        aparams.radius = radius
        aparams.area = Math.PI * radius * radius
        break

      case 'polygon':
        aparams.coordinates = updated.coordinates
        aparams.radius = polygonCircleRadius(updated.center, updated.coordinates)
        aparams.area = geodesicArea(updated.coordinates)
        break

      default:
        throw new Error('Unknown shape ' + updated.shape)
    }

    return aparams
  }

  static makeFromUpdated(updated: IGeozoneUpdate): IGeozoneExtended {
    const aparams = SystemGeozone.calculateParams(updated)
    const fullGeozone = Object.assign({}, updated, defaultGeozoneParams, aparams, { visual_props: defaultVisual })
    return new SystemGeozone(fullGeozone)
  }

  static copy(target: IGeozoneExtended): IGeozoneExtended {
    return new SystemGeozone(target.idGeozone)
  }
}

const getValidVisuals = (v: IVisualProps) => {
  const fill = colorUtils.toHex(v.fill)
  const stroke = colorUtils.toHex(v.stroke)

  if ((stroke.opacity === 0 || v.stroke_weight === 0) && fill.opacity === 0) {
    return defaultGeozoneVisualProps
  }

  return {
    color: stroke.color,
    opacity: stroke.opacity,
    fillColor: fill.color,
    fillOpacity: fill.opacity,
    weight: v.stroke_weight
  }
}
