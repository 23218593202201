import { IAttribute, ICategory, IDAttrIndex, Identifiable } from "../stores/interfaces"


export const byTags = (
  index: IDAttrIndex | null,
  categories: ICategory[] | null,
  attributes: IAttribute[] | null,
  selected: number[],
) => (entity: Identifiable): boolean => {

  if (index == null) {
    return true
  }

  if (categories == null || categories.length === 0) {
    return true
  }

  if (attributes == null || attributes.length === 0) {
    return true
  }

  const noSelected = Object.keys(selected).length === 0
  if (noSelected) {
    return true
  }

  const deviceAttributes = index[entity.id]
  if (deviceAttributes == null) {
    if (!noSelected) {
      return false
    }
    return true
  }

  // Expanding selected into cat-attrs map
  const sIndex: IDAttrIndex = {}

  selected.forEach((aid) => {
    const a = attributes.find((attr) => attr.attributeID === aid)
    if (a == null) {
      return
    }

    const c = categories.find((cat) => cat.categoryID === a.categoryID)
    if (c == null) {
      return
    }

    if (sIndex[c.categoryID] == null) {
      sIndex[c.categoryID] = []
    }

    sIndex[c.categoryID].push(a.attributeID)
  })

  for (let [, attrs] of Object.entries(sIndex)) {
    let match = false
    for (let i = 0; i < deviceAttributes.length; i++) {
      if (attrs.includes(deviceAttributes[i])) {
        match = true
        break
      }
    }

    if (!match) {
      return false
    }
  }
  return true
}