import styles from './Editable.module.css'

(function(factory, window) {
  // define an AMD module that relies on 'leaflet'
  // if (typeof define === 'function' && define.amd) {
  //   define(['leaflet'], factory)


  //   // define a Common JS module that relies on 'leaflet'
  // } else
  if (typeof exports === 'object') {
    module.exports = factory(require('leaflet'))
  }

  // attach your plugin to the global 'L' variable
  if (typeof window !== 'undefined' && window.L) {
    factory(window.L)
  }
}(function(L) {
  L.Editable = L.Evented.extend({
    statics: {
      FORWARD: 1,
      BACKWARD: -1,
    },

    options: {
      zIndex: 1000,
      // polylineClass: L.Polyline,
      // markerClass: L.Marker,
      // rectangleClass: L.Rectangle,
      circleClass: L.Circle,
      polygonClass: L.Polygon,

      drawingCSSClass: 'leaflet-editable-drawing',
      drawingCursor: 'crosshair',

      editLayer: undefined,
      featuresLayer: undefined,

      // polylineEditorClass: undefined,
      polygonEditorClass: undefined,
      // markerEditorClass: undefined,
      // rectangleEditorClass: undefined,
      circleEditorClass: undefined,

      lineGuideOptions: {},
      skipMiddleMarkers: false,

    },

    initialize: function(map, options) {
      L.setOptions(this, options)
      this._lastZIndex = this.options.zIndex
      this.map = map
      this.editLayer = this.createEditLayer()
      this.featuresLayer = this.createFeaturesLayer()
      this.forwardLineGuide = this.createLineGuide()
      this.backwardLineGuide = this.createLineGuide()
    },

    fireAndForward: function(type, e) {
      e = e || {}
      e.editTools = this
      this.fire(type, e)
      this.map.fire(type, e)
    },

    createLineGuide: function() {
      const options = L.extend({ dashArray: '5,10', weight: 1, interactive: false }, this.options.lineGuideOptions)
      return L.polyline([], options)
    },

    createVertexIcon: function(options) {
      return L.Browser.mobile && L.Browser.touch ?
        new L.Editable.TouchVertexIcon(options) :
        new L.Editable.VertexIcon(options)
    },

    createEditLayer: function() {
      return this.options.editLayer || new L.LayerGroup().addTo(this.map)
    },

    createFeaturesLayer: function() {
      return this.options.featuresLayer || new L.LayerGroup().addTo(this.map)
    },

    moveForwardLineGuide: function(latlng) {
      if (this.forwardLineGuide._latlngs.length) {
        this.forwardLineGuide._latlngs[1] = latlng
        this.forwardLineGuide._bounds.extend(latlng)
        this.forwardLineGuide.redraw()
      }
    },

    moveBackwardLineGuide: function(latlng) {
      if (this.backwardLineGuide._latlngs.length) {
        this.backwardLineGuide._latlngs[1] = latlng
        this.backwardLineGuide._bounds.extend(latlng)
        this.backwardLineGuide.redraw()
      }
    },

    anchorForwardLineGuide: function(latlng) {
      this.forwardLineGuide._latlngs[0] = latlng
      this.forwardLineGuide._bounds.extend(latlng)
      this.forwardLineGuide.redraw()
    },

    anchorBackwardLineGuide: function(latlng) {
      this.backwardLineGuide._latlngs[0] = latlng
      this.backwardLineGuide._bounds.extend(latlng)
      this.backwardLineGuide.redraw()
    },

    attachForwardLineGuide: function() {
      this.editLayer.addLayer(this.forwardLineGuide)
    },

    attachBackwardLineGuide: function() {
      this.editLayer.addLayer(this.backwardLineGuide)
    },

    detachForwardLineGuide: function() {
      this.forwardLineGuide.setLatLngs([])
      this.editLayer.removeLayer(this.forwardLineGuide)
    },

    detachBackwardLineGuide: function() {
      this.backwardLineGuide.setLatLngs([])
      this.editLayer.removeLayer(this.backwardLineGuide)
    },

    blockEvents: function() {
      // Hack: force map not to listen to other layers events while drawing.
      if (!this._oldTargets) {
        this._oldTargets = this.map._targets
        this.map._targets = {}
      }
    },

    unblockEvents: function() {
      if (this._oldTargets) {
        // Reset, but keep targets created while drawing.
        this.map._targets = L.extend(this.map._targets, this._oldTargets)
        delete this._oldTargets
      }
    },

    registerForDrawing: function(editor) {
      if (this._drawingEditor) this.unregisterForDrawing(this._drawingEditor)
      this.blockEvents()
      editor.reset() // Make sure editor tools still receive events.
      this._drawingEditor = editor
      this.map.on('mousemove touchmove', editor.onDrawingMouseMove, editor)
      this.map.on('mousedown', this.onMousedown, this)
      this.map.on('mouseup', this.onMouseup, this)
      L.DomUtil.addClass(this.map._container, this.options.drawingCSSClass)
      this.defaultMapCursor = this.map._container.style.cursor
      this.map._container.style.cursor = this.options.drawingCursor
    },

    unregisterForDrawing: function(editor) {
      this.unblockEvents()
      L.DomUtil.removeClass(this.map._container, this.options.drawingCSSClass)
      this.map._container.style.cursor = this.defaultMapCursor
      editor = editor || this._drawingEditor
      if (!editor) return
      this.map.off('mousemove touchmove', editor.onDrawingMouseMove, editor)
      this.map.off('mousedown', this.onMousedown, this)
      this.map.off('mouseup', this.onMouseup, this)
      if (editor !== this._drawingEditor) return
      delete this._drawingEditor
      if (editor._drawing) editor.cancelDrawing()
    },

    onMousedown: function(e) {
      if (e.originalEvent.which !== 1) return
      this._mouseDown = e
      this._drawingEditor.onDrawingMouseDown(e)
    },

    onMouseup: function(e) {
      if (this._mouseDown) {
        const editor = this._drawingEditor
        const mouseDown = this._mouseDown
        this._mouseDown = null
        editor.onDrawingMouseUp(e)
        if (this._drawingEditor !== editor) return // onDrawingMouseUp may call unregisterFromDrawing.
        const origin = L.point(mouseDown.originalEvent.clientX, mouseDown.originalEvent.clientY)
        const distance = L.point(e.originalEvent.clientX, e.originalEvent.clientY).distanceTo(origin)
        if (Math.abs(distance) < 9 * (window.devicePixelRatio || 1)) this._drawingEditor.onDrawingClick(e)
      }
    },

    drawing: function() {
      return this._drawingEditor && this._drawingEditor.drawing()
    },

    // 🍂method stopDrawing()
    // When you need to stop any ongoing drawing, without needing to know which editor is active.
    stopDrawing: function() {
      this.unregisterForDrawing()
    },

    // 🍂method commitDrawing()
    // When you need to commit any ongoing drawing, without needing to know which editor is active.
    commitDrawing: function(e) {
      if (!this._drawingEditor) return
      this._drawingEditor.commitDrawing(e)
    },

    connectCreatedToMap: function(layer) {
      return this.featuresLayer.addLayer(layer)
    },

    // 🍂method startCircle(latlng: L.LatLng, options: hash): L.Circle
    // Start drawing a Circle. If `latlng` is given, the Circle anchor will be added.
    // In any case, continuing on user drag.
    // If `options` is given, it will be passed to the Circle class constructor.
    startCircle: function(latlng, options) {
      latlng = latlng || this.map.getCenter().clone()
      const circle = this.createCircle(latlng, options)
      circle.enableEdit(this.map).startDrawing()
      return circle
    },

    startPolygon: function(latlng, options) {
      const polygon = this.createPolygon([], options)
      polygon.enableEdit(this.map).newShape(latlng)
      return polygon
    },

    startHole: function(editor, latlng) {
      editor.newHole(latlng)
    },

    createLayer: function(Klass, latlngs, options) {
      options = L.Util.extend({ editOptions: { editTools: this } }, options)
      const layer = new Klass(latlngs, options)
      // 🍂namespace Editable
      // 🍂event editable:created: LayerEvent
      // Fired when a new feature (Marker, Polyline…) is created.
      this.fireAndForward('editable:created', { layer: layer })
      return layer
    },

    createCircle: function(latlng, options) {
      if (options) {
        options.shape = 'checkpoint'
      } else {
        options = { shape: 'checkpoint' }
      }
      return this.createLayer((options && options.circleClass) || this.options.circleClass, latlng, options)
    },

    createPolygon: function(latlngs, options) {
      if (options) {
        options.shape = 'polygon'
      } else {
        options = { shape: 'polygon' }
      }
      return this.createLayer((options && options.polygonClass) || this.options.polygonClass, latlngs, options)
    },
  })

  L.extend(L.Editable, {
    makeCancellable: function(e) {
      e.cancel = function() {
        e._cancelled = true
      }
    },
  })

  L.ExtendedTooltip = L.Class.extend({
    initialize: function(map) {
      const innerMap = (map.leaflet && map.leaflet.map) ? map.leaflet.map : map
      this._map = innerMap
      this._popupPane = this._map._panes.popupPane

      this._container = this._map.options.drawTooltips ? L.DomUtil.create('div', styles.tooltip, this._popupPane) : null
      this._singleLineLabel = false
    },

    dispose: function() {
      if (this._container) {
        this._popupPane.removeChild(this._container)
        this._container = null
      }
    },

    updateContent: function(labelText) {
      if (!this._container) {
        return this
      }
      labelText.subtext = labelText.subtext || ''

      // update the vertical position (only if changed)
      if (labelText.subtext.length === 0 && !this._singleLineLabel) {
        L.DomUtil.addClass(this._container, styles.tooltipSingle)
        this._singleLineLabel = true
      } else if (labelText.subtext.length > 0 && this._singleLineLabel) {
        L.DomUtil.removeClass(this._container, styles.tooltipSingle)
        this._singleLineLabel = false
      }

      const subtextInner = labelText.subtext.length > 0 ?
      `<span class="${styles.tooltipSubtext}">` + labelText.subtext + '</span><br />' :
      ''

      this._container.innerHTML = subtextInner + '<span>' + labelText.text + '</span>'
      return this
    },

    updatePosition: function(latlng) {
      const pos = this._map.latLngToLayerPoint(latlng)
      const tooltipContainer = this._container

      if (this._container) {
        tooltipContainer.style.visibility = 'inherit'
        L.DomUtil.setPosition(tooltipContainer, pos)
      }

      return this
    },

    showAsError: function() {
      if (this._container) {
        L.DomUtil.addClass(this._container, styles.tooltipError)
      }
      return this
    },

    removeError: function() {
      if (this._container) {
        L.DomUtil.removeClass(this._container, styles.tooltipError)
      }
      return this
    },
  })

  L.Editable.VertexIcon = L.DivIcon.extend({
    options: {
      iconSize: new L.Point(12, 12),
      className: styles.vertex,
    },
  })

  L.Editable.TouchVertexIcon = L.Editable.VertexIcon.extend({
    options: {
      iconSize: new L.Point(20, 20),
      className: styles.vertex,
    },
  })

  // 🍂namespace Editable; 🍂class VertexMarker; Handler for dragging path vertices.
  L.Editable.VertexMarker = L.Marker.extend({
    options: {
      draggable: true,
      className: 'leaflet-div-icon leaflet-vertex-icon ' + styles.vertex,
    },

    // 🍂section Public methods
    // The marker used to handle path vertex. You will usually interact with a `VertexMarker`
    // instance when listening for events like `editable:vertex:ctrlclick`.

    initialize: function(latlng, latlngs, editor, options) {
      // We don't use this._latlng, because on drag Leaflet replace it while
      // we want to keep reference.
      this.latlng = latlng
      this.latlngs = latlngs
      this.editor = editor
      L.Marker.prototype.initialize.call(this, latlng, options)
      this.options.icon = this.editor.tools.createVertexIcon({ className: this.options.className })
      this.latlng.__vertex = this
      this.editor.editLayer.addLayer(this)
      this.setZIndexOffset(editor.tools._lastZIndex + 1)
    },

    onAdd: function(map) {
      L.Marker.prototype.onAdd.call(this, map)
      this.on('drag', this.onDrag)
      this.on('dragstart', this.onDragStart)
      this.on('dragend', this.onDragEnd)
      this.on('mouseup', this.onMouseup)
      this.on('click', this.onClick)
      this.on('contextmenu', this.onContextMenu)
      this.on('mousedown touchstart', this.onMouseDown)
      this.on('mouseover', this.onMouseOver)
      this.on('mouseout', this.onMouseOut)
      this.addMiddleMarkers()
    },

    onRemove: function(map) {
      if (this.middleMarker) this.middleMarker.delete()
      delete this.latlng.__vertex
      this.off('drag', this.onDrag)
      this.off('dragstart', this.onDragStart)
      this.off('dragend', this.onDragEnd)
      this.off('mouseup', this.onMouseup)
      this.off('click', this.onClick)
      this.off('contextmenu', this.onContextMenu)
      this.off('mousedown touchstart', this.onMouseDown)
      this.off('mouseover', this.onMouseOver)
      this.off('mouseout', this.onMouseOut)
      L.Marker.prototype.onRemove.call(this, map)
    },

    onDrag: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerDrag(e)
      const iconPos = L.DomUtil.getPosition(this._icon)
      const latlng = this._map.layerPointToLatLng(iconPos)
      this.latlng.update(latlng)
      this._latlng = this.latlng // Push back to Leaflet our reference.
      this.editor.refresh()
      if (this.middleMarker) this.middleMarker.updateLatLng()
      const next = this.getNext()
      if (next && next.middleMarker) next.middleMarker.updateLatLng()
    },

    onDragStart: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerDragStart(e)
    },

    onDragEnd: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerDragEnd(e)
    },

    onClick: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerClick(e)
    },

    onMouseup: function(e) {
      L.DomEvent.stop(e)
      e.vertex = this
      this.editor.map.fire('mouseup', e)
    },

    onContextMenu: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerContextMenu(e)
    },

    onMouseDown: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerMouseDown(e)
    },

    onMouseOver: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerMouseOver(e)
    },

    onMouseOut: function(e) {
      e.vertex = this
      this.editor.onVertexMarkerMouseOut(e)
    },

    // 🍂method delete()
    // Delete a vertex and the related LatLng.
    delete: function() {
      const next = this.getNext() // Compute before changing latlng
      this.latlngs.splice(this.getIndex(), 1)
      this.editor.editLayer.removeLayer(this)
      this.editor.onVertexDeleted({ latlng: this.latlng, vertex: this })
      if (!this.latlngs.length) this.editor.deleteShape(this.latlngs)
      if (next) next.resetMiddleMarker()
      this.editor.refresh()
    },

    // 🍂method getIndex(): int
    // Get the index of the current vertex among others of the same LatLngs group.
    getIndex: function() {
      return this.latlngs.indexOf(this.latlng)
    },

    // 🍂method getLastIndex(): int
    // Get last vertex index of the LatLngs group of the current vertex.
    getLastIndex: function() {
      return this.latlngs.length - 1
    },

    // 🍂method getPrevious(): VertexMarker
    // Get the previous VertexMarker in the same LatLngs group.
    getPrevious: function() {
      if (this.latlngs.length < 2) return
      const index = this.getIndex()
      let previousIndex = index - 1
      if (index === 0 && this.editor.CLOSED) previousIndex = this.getLastIndex()
      const previous = this.latlngs[previousIndex]
      if (previous) return previous.__vertex
    },

    // 🍂method getNext(): VertexMarker
    // Get the next VertexMarker in the same LatLngs group.
    getNext: function() {
      if (this.latlngs.length < 2) return
      const index = this.getIndex()
      let nextIndex = index + 1
      if (index === this.getLastIndex() && this.editor.CLOSED) nextIndex = 0
      const next = this.latlngs[nextIndex]
      if (next) return next.__vertex
    },

    addMiddleMarker: function(previous) {
      if (!this.editor.hasMiddleMarkers()) return
      previous = previous || this.getPrevious()
      if (previous && !this.middleMarker) {
        this.middleMarker = this.editor.addMiddleMarker(previous, this, this.latlngs, this.editor)
      }
    },

    addMiddleMarkers: function() {
      if (!this.editor.hasMiddleMarkers()) return
      const previous = this.getPrevious()
      if (previous) this.addMiddleMarker(previous)
      const next = this.getNext()
      if (next) next.resetMiddleMarker()
    },

    resetMiddleMarker: function() {
      if (this.middleMarker) this.middleMarker.delete()
      this.addMiddleMarker()
    },

    // 🍂method split()
    // Split the vertex LatLngs group at its index, if possible.
    split: function() {
      if (!this.editor.splitShape) return // Only for PolylineEditor
      this.editor.splitShape(this.latlngs, this.getIndex())
    },

    // 🍂method continue()
    // Continue the vertex LatLngs from this vertex. Only active for first and last vertices of a Polyline.
    continue: function() {
      if (!this.editor.continueBackward) return // Only for PolylineEditor
      const index = this.getIndex()
      if (index === 0) this.editor.continueBackward(this.latlngs)
      else if (index === this.getLastIndex()) this.editor.continueForward(this.latlngs)
    },
  })

  L.Editable.mergeOptions({
    // 🍂namespace Editable
    // 🍂option vertexMarkerClass: class = VertexMarker
    // Class to be used as vertex, for path editing.
    vertexMarkerClass: L.Editable.VertexMarker,
  })

  L.Editable.MiddleMarker = L.Marker.extend({
    options: {
      opacity: 0.5,
      className: 'leaflet-div-icon leaflet-middle-icon ' + styles.vertex,
      draggable: true,
    },

    initialize: function(left, right, latlngs, editor, options) {
      this.left = left
      this.right = right
      this.editor = editor
      this.latlngs = latlngs
      L.Marker.prototype.initialize.call(this, this.computeLatLng(), options)
      this._opacity = this.options.opacity
      this.options.icon = this.editor.tools.createVertexIcon({ className: this.options.className })
      this.editor.editLayer.addLayer(this)
      this.setVisibility()
    },

    setVisibility: function() {
      const leftPoint = this._map.latLngToContainerPoint(this.left.latlng)
      const rightPoint = this._map.latLngToContainerPoint(this.right.latlng)
      const size = L.point(this.options.icon.options.iconSize)
      if (leftPoint.distanceTo(rightPoint) < size.x * 3) this.hide()
      else this.show()
    },

    show: function() {
      this.setOpacity(this._opacity)
    },

    hide: function() {
      this.setOpacity(0)
    },

    updateLatLng: function() {
      this.setLatLng(this.computeLatLng())
      this.setVisibility()
    },

    computeLatLng: function() {
      const leftPoint = this.editor.map.latLngToContainerPoint(this.left.latlng)
      const rightPoint = this.editor.map.latLngToContainerPoint(this.right.latlng)
      const y = (leftPoint.y + rightPoint.y) / 2
      const x = (leftPoint.x + rightPoint.x) / 2
      return this.editor.map.containerPointToLatLng([x, y])
    },

    onAdd: function(map) {
      L.Marker.prototype.onAdd.call(this, map)
      L.DomEvent.on(this._icon, 'mousedown touchstart', this.onMouseDown, this)
      map.on('zoomend', this.setVisibility, this)
    },

    onRemove: function(map) {
      delete this.right.middleMarker
      L.DomEvent.off(this._icon, 'mousedown touchstart', this.onMouseDown, this)
      map.off('zoomend', this.setVisibility, this)
      L.Marker.prototype.onRemove.call(this, map)
    },

    onMouseDown: function(e) {
      const iconPos = L.DomUtil.getPosition(this._icon)
      const latlng = this.editor.map.layerPointToLatLng(iconPos)
      e = {
        originalEvent: e,
        latlng: latlng,
      }
      if (this.options.opacity === 0) return
      L.Editable.makeCancellable(e)
      this.editor.onMiddleMarkerMouseDown(e)
      if (e._cancelled) return
      this.latlngs.splice(this.index(), 0, e.latlng)
      this.editor.refresh()
      const icon = this._icon
      const marker = this.editor.addVertexMarker(e.latlng, this.latlngs)
      this.editor.onNewVertex(marker)
      /* Hack to workaround browser not firing touchend when element is no more on DOM */
      const parent = marker._icon.parentNode
      parent.removeChild(marker._icon)
      marker._icon = icon
      parent.appendChild(marker._icon)
      marker._initIcon()
      marker._initInteraction()
      marker.setOpacity(1)
      /* End hack */
      // Transfer ongoing dragging to real marker
      L.Draggable._dragging = false
      marker.dragging._draggable._onDown(e.originalEvent)
      this.delete()
    },

    delete: function() {
      this.editor.editLayer.removeLayer(this)
    },

    index: function() {
      return this.latlngs.indexOf(this.right.latlng)
    },
  })

  L.Editable.mergeOptions({
    middleMarkerClass: L.Editable.MiddleMarker,
  })

  L.Editable.BaseEditor = L.Handler.extend({
    initialize: function(map, feature, options) {
      L.setOptions(this, options)
      this.map = map
      this.feature = feature
      this.feature.editor = this
      this.editLayer = new L.LayerGroup()
      this.tools = this.options.editTools || map.editTools
    },

    enable: function() {
      if (this._enabled) {
        this.disable()
        // TODO handle shape completion
        return
      }

      L.Handler.prototype.enable.call(this)

      this.fire('enabled', { handler: this.type })

      // this._map.fire('draw:drawstart', { layerType: this.type });
    },

    disable: function() {
      if (!this._enabled) {return}

      L.Handler.prototype.disable.call(this)

      // this._map.fire('draw:drawstop', { layerType: this.type });

      this.fire('disabled', { handler: this.type })
    },

    // addHooks: function () {
    //     var map = this._map;

    //     if (map) {
    //         L.DomUtil.disableTextSelection();

    //         map.getContainer().focus();

    //         this._tooltip = new L.Tooltip(this._map);

    //         L.DomEvent.on(this._container, 'keyup', this._cancelDrawing, this);
    //     }
    // },

    // removeHooks: function () {
    //     if (this._map) {
    //         L.DomUtil.enableTextSelection();

    //         this._tooltip.dispose();
    //         this._tooltip = null;

    //         L.DomEvent.off(this._container, 'keyup', this._cancelDrawing, this);
    //     }
    // },

    // 🍂method enable(): this
    // Set up the drawing tools for the feature to be editable.
    addHooks: function() {
      if (this.isConnected()) this.onFeatureAdd()
      else this.feature.once('add', this.onFeatureAdd, this)
      this.onEnable()
      this.feature.on(this._getEvents(), this)

      this._tooltip = new L.ExtendedTooltip(this.map)
    },

    // 🍂method disable(): this
    // Remove the drawing tools for the feature.
    removeHooks: function() {
      this.feature.off(this._getEvents(), this)
      if (this.feature.dragging) this.feature.dragging.disable()
      this.editLayer.clearLayers()
      this.tools.editLayer.removeLayer(this.editLayer)
      this.onDisable()
      if (this._drawing) this.cancelDrawing()

      this._removeTooltip()
    },

    // 🍂method drawing(): boolean
    // Return true if any drawing action is ongoing with this editor.
    drawing: function() {
      return !!this._drawing
    },

    reset: function() {},

    onFeatureAdd: function() {
      this.tools.editLayer.addLayer(this.editLayer)
      if (this.feature.dragging) this.feature.dragging.enable()
    },

    hasMiddleMarkers: function() {
      return !this.options.skipMiddleMarkers && !this.tools.options.skipMiddleMarkers
    },

    fireAndForward: function(type, e) {
      e = e || {}
      e.layer = this.feature
      this.feature.fire(type, e)
      this.tools.fireAndForward(type, e)
    },

    onEnable: function() {
      // 🍂namespace Editable
      // 🍂event editable:enable: Event
      // Fired when an existing feature is ready to be edited.
      this.fireAndForward('editable:enable')
    },

    onDisable: function() {
      // 🍂namespace Editable
      // 🍂event editable:disable: Event
      // Fired when an existing feature is not ready anymore to be edited.
      this.fireAndForward('editable:disable')
    },

    onEditing: function() {
      // 🍂namespace Editable
      // 🍂event editable:editing: Event
      // Fired as soon as any change is made to the feature geometry.
      this.fireAndForward('editable:editing')
    },

    onStartDrawing: function() {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:start: Event
      // Fired when a feature is to be drawn.
      this.fireAndForward('editable:drawing:start')
    },

    onEndDrawing: function() {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:end: Event
      // Fired when a feature is not drawn anymore.
      this.fireAndForward('editable:drawing:end')
    },

    onCancelDrawing: function() {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:cancel: Event
      // Fired when user cancel drawing while a feature is being drawn.
      this.fireAndForward('editable:drawing:cancel')
    },

    onCommitDrawing: function(e) {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:commit: Event
      // Fired when user finish drawing a feature.
      this.fireAndForward('editable:drawing:commit', e)

      // TODO listen outside
      this.disable()
    },

    onDrawingMouseDown: function(e) {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:mousedown: Event
      // Fired when user `mousedown` while drawing.
      this.fireAndForward('editable:drawing:mousedown', e)
    },

    onDrawingMouseUp: function(e) {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:mouseup: Event
      // Fired when user `mouseup` while drawing.
      this.fireAndForward('editable:drawing:mouseup', e)
    },

    startDrawing: function() {
      if (!this._drawing) this._drawing = L.Editable.FORWARD
      this.tools.registerForDrawing(this)
      this.onStartDrawing()
    },

    commitDrawing: function(e) {
      this.onCommitDrawing(e)
      this.endDrawing()
    },

    cancelDrawing: function() {
      // If called during a vertex drag, the vertex will be removed before
      // the mouseup fires on it. This is a workaround. Maybe better fix is
      // To have L.Draggable reset it's status on disable (Leaflet side).
      L.Draggable._dragging = false
      this.onCancelDrawing()
      this.endDrawing()

      this._removeTooltip()
    },

    endDrawing: function() {
      this._drawing = false
      this.tools.unregisterForDrawing(this)
      this.onEndDrawing()
    },

    onDrawingClick: function(e) {
      if (!this.drawing()) return
      L.Editable.makeCancellable(e)
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:click: CancelableEvent
      // Fired when user `click` while drawing, before any internal action is being processed.
      this.fireAndForward('editable:drawing:click', e)
      if (e._cancelled) return
      if (!this.isConnected()) this.connect(e)
      this.processDrawingClick(e)
    },

    isConnected: function() {
      return this.map.hasLayer(this.feature)
    },

    connect: function() {
      this.tools.connectCreatedToMap(this.feature)
      this.tools.editLayer.addLayer(this.editLayer)
    },

    onMove: function(e) {
      // 🍂namespace Editable
      // 🍂section Drawing events
      // 🍂event editable:drawing:move: Event
      // Fired when `move` mouse while drawing, while dragging a marker, and while dragging a vertex.
      this.fireAndForward('editable:drawing:move', e)
    },

    onDrawingMouseMove: function(e) {
      this.onMove(e)
    },

    _getEvents: function() {
      return {
        dragstart: this.onDragStart,
        drag: this.onDrag,
        dragend: this.onDragEnd,
        remove: this.disable,
      }
    },

    onDragStart: function(e) {
      this.onEditing()
      // 🍂namespace Editable
      // 🍂event editable:dragstart: Event
      // Fired before a path feature is dragged.
      this.fireAndForward('editable:dragstart', e)
    },

    onDrag: function(e) {
      this.onMove(e)
      // 🍂namespace Editable
      // 🍂event editable:drag: Event
      // Fired when a path feature is being dragged.
      this.fireAndForward('editable:drag', e)
    },

    onDragEnd: function(e) {
      // 🍂namespace Editable
      // 🍂event editable:dragend: Event
      // Fired after a path feature has been dragged.
      this.fireAndForward('editable:dragend', e)
    },

    _removeTooltip: function() {
      if (this.map) {
        this._tooltip && this._tooltip.dispose()
        this._tooltip = null
      }
    },
  })

  L.Editable.BaseEditor.include(L.Evented.prototype)

  L.Editable.PathEditor = L.Editable.BaseEditor.extend({
    CLOSED: false,
    MIN_VERTEX: 2,

    _isFlat: L.LineUtil.isFlat || L.LineUtil._flat || L.Polyline._flat, // <=> 1.1 compat.

    addHooks: function() {
      L.Editable.BaseEditor.prototype.addHooks.call(this)
      if (this.feature) this.initVertexMarkers()

      // console.info('ed hooks', this)
      this.map.on('keydown', this._keyListener, this)
      return this
    },

    removeHooks: function() {
      L.Editable.BaseEditor.prototype.removeHooks.call(this)
      this.map.off('keydown', this._keyListener, this)
    },

    initVertexMarkers: function(latlngs) {
      if (!this.enabled()) return
      latlngs = latlngs || this.getLatLngs()
      if (this._isFlat(latlngs)) this.addVertexMarkers(latlngs)
      else for (let i = 0; i < latlngs.length; i++) this.initVertexMarkers(latlngs[i])
    },

    getLatLngs: function() {
      return this.feature.getLatLngs()
    },

    // 🍂method reset()
    // Rebuild edit elements (Vertex, MiddleMarker, etc.).
    reset: function() {
      this.editLayer.clearLayers()
      this.initVertexMarkers()
    },

    addVertexMarker: function(latlng, latlngs) {
      // eslint-disable-next-line new-cap
      return new this.tools.options.vertexMarkerClass(latlng, latlngs, this)
    },

    onNewVertex: function(vertex) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:new: VertexEvent
      // Fired when a new vertex is created.
      this.fireAndForward('editable:vertex:new', { latlng: vertex.latlng, vertex: vertex })
    },

    addVertexMarkers: function(latlngs) {
      for (let i = 0; i < latlngs.length; i++) {
        this.addVertexMarker(latlngs[i], latlngs)
      }
    },

    refreshVertexMarkers: function(latlngs) {
      latlngs = latlngs || this.getDefaultLatLngs()
      for (let i = 0; i < latlngs.length; i++) {
        latlngs[i].__vertex.update()
      }
    },

    addMiddleMarker: function(left, right, latlngs) {
      // eslint-disable-next-line new-cap
      return new this.tools.options.middleMarkerClass(left, right, latlngs, this)
    },

    // TODO handle vertex click if polygon selected
    onVertexMarkerClick: function(e) {
      L.Editable.makeCancellable(e)
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:click: CancelableVertexEvent
      // Fired when a `click` is issued on a vertex, before any internal action is being processed.
      this.fireAndForward('editable:vertex:click', e)
      if (e._cancelled) return
      if (this.tools.drawing() && this.tools._drawingEditor !== this) return
      const index = e.vertex.getIndex(); let commit
      if (e.originalEvent.ctrlKey) {
        this.onVertexMarkerCtrlClick(e)
      } else if (e.originalEvent.altKey) {
        this.onVertexMarkerAltClick(e)
      } else if (e.originalEvent.shiftKey) {
        this.onVertexMarkerShiftClick(e)
      } else if (e.originalEvent.metaKey) {
        this.onVertexMarkerMetaKeyClick(e)
      } else if (index === e.vertex.getLastIndex() && this._drawing === L.Editable.FORWARD) {
        if (index >= this.MIN_VERTEX - 1) commit = true
      } else if (index === 0 && this._drawing === L.Editable.BACKWARD && this._drawnLatLngs.length >= this.MIN_VERTEX) {
        commit = true
      } else if (index === 0 && this._drawing === L.Editable.FORWARD &&
          this._drawnLatLngs.length >= this.MIN_VERTEX && this.CLOSED) {
        commit = true // Allow to close on first point also for polygons
      } else {
        this.onVertexRawMarkerClick(e)
      }
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:clicked: VertexEvent
      // Fired when a `click` is issued on a vertex, after all internal actions.
      this.fireAndForward('editable:vertex:clicked', e)
      if (commit) this.commitDrawing(e)
    },

    // FIXME on enter fires another event too
    _keyListener: function(e) {
      if (!this.options.editTools) return
      if (e.originalEvent.key === 'Enter') {
        if (this._drawnLatLngs && this._drawnLatLngs.length >= this.MIN_VERTEX && this.CLOSED) {
          this.commitDrawing(e)
        }
      } else if (e.originalEvent.key === 'Escape') {
        this.map.editTools.stopDrawing()
        this.map.editTools.editLayer.clearLayers()
        this.map.editTools.featuresLayer.clearLayers()
      }
    },

    onVertexRawMarkerClick: function(e) {
      // TODO vertex context menu console.info('Vertex raw click')
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:rawclick: CancelableVertexEvent
      // Fired when a `click` is issued on a vertex without any special key and without being in drawing mode.
      this.fireAndForward('editable:vertex:rawclick', e)
      if (e._cancelled) return
      if (!this.vertexCanBeDeleted(e.vertex)) return
      e.vertex.delete()
    },

    vertexCanBeDeleted: function(vertex) {
      return vertex.latlngs.length > this.MIN_VERTEX
    },

    onVertexDeleted: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:deleted: VertexEvent
      // Fired after a vertex has been deleted by user.
      this.fireAndForward('editable:vertex:deleted', e)
    },

    onVertexMarkerCtrlClick: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:ctrlclick: VertexEvent
      // Fired when a `click` with `ctrlKey` is issued on a vertex.
      this.fireAndForward('editable:vertex:ctrlclick', e)
    },

    onVertexMarkerShiftClick: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:shiftclick: VertexEvent
      // Fired when a `click` with `shiftKey` is issued on a vertex.
      this.fireAndForward('editable:vertex:shiftclick', e)
    },

    onVertexMarkerMetaKeyClick: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:metakeyclick: VertexEvent
      // Fired when a `click` with `metaKey` is issued on a vertex.
      this.fireAndForward('editable:vertex:metakeyclick', e)
    },

    onVertexMarkerAltClick: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:altclick: VertexEvent
      // Fired when a `click` with `altKey` is issued on a vertex.
      this.fireAndForward('editable:vertex:altclick', e)
    },

    onVertexMarkerContextMenu: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:contextmenu: VertexEvent
      // Fired when a `contextmenu` is issued on a vertex.
      this.fireAndForward('editable:vertex:contextmenu', e)
    },

    onVertexMarkerMouseDown: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:mousedown: VertexEvent
      // Fired when user `mousedown` a vertex.
      this.fireAndForward('editable:vertex:mousedown', e)
    },

    onVertexMarkerMouseOver: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:mouseover: VertexEvent
      // Fired when a user's mouse enters the vertex
      this.fireAndForward('editable:vertex:mouseover', e)
    },

    onVertexMarkerMouseOut: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:mouseout: VertexEvent
      // Fired when a user's mouse leaves the vertex
      this.fireAndForward('editable:vertex:mouseout', e)
    },

    onMiddleMarkerMouseDown: function(e) {
      // 🍂namespace Editable
      // 🍂section MiddleMarker events
      // 🍂event editable:middlemarker:mousedown: VertexEvent
      // Fired when user `mousedown` a middle marker.
      this.fireAndForward('editable:middlemarker:mousedown', e)
    },

    onVertexMarkerDrag: function(e) {
      this.onMove(e)
      if (this.feature._bounds) this.extendBounds(e)
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:drag: VertexEvent
      // Fired when a vertex is dragged by user.
      this.fireAndForward('editable:vertex:drag', e)
    },

    onVertexMarkerDragStart: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:dragstart: VertexEvent
      // Fired before a vertex is dragged by user.
      this.fireAndForward('editable:vertex:dragstart', e)
    },

    onVertexMarkerDragEnd: function(e) {
      // 🍂namespace Editable
      // 🍂section Vertex events
      // 🍂event editable:vertex:dragend: VertexEvent
      // Fired after a vertex is dragged by user.
      this.fireAndForward('editable:vertex:dragend', e)
    },

    setDrawnLatLngs: function(latlngs) {
      this._drawnLatLngs = latlngs || this.getDefaultLatLngs()
    },

    startDrawing: function() {
      if (!this._drawnLatLngs) this.setDrawnLatLngs()
      L.Editable.BaseEditor.prototype.startDrawing.call(this)
    },

    startDrawingForward: function() {
      this.startDrawing()
    },

    endDrawing: function() {
      this.tools.detachForwardLineGuide()
      this.tools.detachBackwardLineGuide()
      if (this._drawnLatLngs && this._drawnLatLngs.length < this.MIN_VERTEX) this.deleteShape(this._drawnLatLngs)
      L.Editable.BaseEditor.prototype.endDrawing.call(this)
      delete this._drawnLatLngs
    },

    addLatLng: function(latlng) {
      if (this._drawing === L.Editable.FORWARD) this._drawnLatLngs.push(latlng)
      else this._drawnLatLngs.unshift(latlng)
      this.feature._bounds.extend(latlng)
      const vertex = this.addVertexMarker(latlng, this._drawnLatLngs)
      this.onNewVertex(vertex)
      this.refresh()
    },

    newPointForward: function(latlng) {
      this.addLatLng(latlng)
      this.tools.attachForwardLineGuide()
      this.tools.anchorForwardLineGuide(latlng)
    },

    newPointBackward: function(latlng) {
      this.addLatLng(latlng)
      this.tools.anchorBackwardLineGuide(latlng)
    },

    // 🍂namespace PathEditor
    // 🍂method push()
    // Programmatically add a point while drawing.
    push: function(latlng) {
      if (!latlng) return console.error('L.Editable.PathEditor.push expect a valid latlng as parameter')
      if (this._drawing === L.Editable.FORWARD) this.newPointForward(latlng)
      else this.newPointBackward(latlng)
    },

    removeLatLng: function(latlng) {
      latlng.__vertex.delete()
      this.refresh()
    },

    // 🍂method pop(): L.LatLng or null
    // Programmatically remove last point (if any) while drawing.
    pop: function() {
      if (this._drawnLatLngs.length <= 1) return
      let latlng
      if (this._drawing === L.Editable.FORWARD) latlng = this._drawnLatLngs[this._drawnLatLngs.length - 1]
      else latlng = this._drawnLatLngs[0]
      this.removeLatLng(latlng)
      if (this._drawing === L.Editable.FORWARD) {
        this.tools.anchorForwardLineGuide(this._drawnLatLngs[this._drawnLatLngs.length - 1])
      } else {
        this.tools.anchorForwardLineGuide(this._drawnLatLngs[0])
      }
      return latlng
    },

    processDrawingClick: function(e) {
      if (e.vertex && e.vertex.editor === this) return
      if (this._drawing === L.Editable.FORWARD) this.newPointForward(e.latlng)
      else this.newPointBackward(e.latlng)
      this.fireAndForward('editable:drawing:clicked', e)
    },

    onDrawingMouseMove: function(e) {
      L.Editable.BaseEditor.prototype.onDrawingMouseMove.call(this, e)
      if (this._drawing) {
        this.tools.moveForwardLineGuide(e.latlng)
        this.tools.moveBackwardLineGuide(e.latlng)

        // TODO calc area
        // this._tooltip.updateContent({
        //   text: this._endLabelText,
        //   subtext: 'Area!',
        // })
      }
    },

    refresh: function() {
      this.feature.redraw()
      this.onEditing()
    },

    // 🍂namespace PathEditor
    // 🍂method newShape(latlng?: L.LatLng)
    // Add a new shape (Polyline, Polygon) in a multi, and setup up drawing tools to draw it;
    // if optional `latlng` is given, start a path at this point.
    newShape: function(latlng) {
      const shape = this.addNewEmptyShape()
      if (!shape) return
      this.setDrawnLatLngs(shape[0] || shape) // Polygon or polyline
      this.startDrawingForward()
      // 🍂namespace Editable
      // 🍂section Shape events
      // 🍂event editable:shape:new: ShapeEvent
      // Fired when a new shape is created in a multi (Polygon or Polyline).
      this.fireAndForward('editable:shape:new', { shape: shape })
      if (latlng) this.newPointForward(latlng)
    },

    deleteShape: function(shape, latlngs) {
      const e = { shape: shape }
      L.Editable.makeCancellable(e)
      // 🍂namespace Editable
      // 🍂section Shape events
      // 🍂event editable:shape:delete: CancelableShapeEvent
      // Fired before a new shape is deleted in a multi (Polygon or Polyline).
      this.fireAndForward('editable:shape:delete', e)
      if (e._cancelled) return
      shape = this._deleteShape(shape, latlngs)
      if (this.ensureNotFlat) this.ensureNotFlat() // Polygon.
      this.feature.setLatLngs(this.getLatLngs()) // Force bounds reset.
      this.refresh()
      this.reset()
      // 🍂namespace Editable
      // 🍂section Shape events
      // 🍂event editable:shape:deleted: ShapeEvent
      // Fired after a new shape is deleted in a multi (Polygon or Polyline).
      this.fireAndForward('editable:shape:deleted', { shape: shape })
      return shape
    },

    _deleteShape: function(shape, latlngs) {
      latlngs = latlngs || this.getLatLngs()
      if (!latlngs.length) return
      const self = this
      const inplaceDelete = function(latlngs, shape) {
        // Called when deleting a flat latlngs
        shape = latlngs.splice(0, Number.MAX_VALUE)
        return shape
      }
      const spliceDelete = function(latlngs, shape) {
        // Called when removing a latlngs inside an array
        latlngs.splice(latlngs.indexOf(shape), 1)
        if (!latlngs.length) self._deleteShape(latlngs)
        return shape
      }
      if (latlngs === shape) return inplaceDelete(latlngs, shape)
      for (let i = 0; i < latlngs.length; i++) {
        if (latlngs[i] === shape) return spliceDelete(latlngs, shape)
        else if (latlngs[i].indexOf(shape) !== -1) return spliceDelete(latlngs[i], shape)
      }
    },

    // 🍂namespace PathEditor
    // 🍂method deleteShapeAt(latlng: L.LatLng): Array
    // Remove a path shape at the given `latlng`.
    deleteShapeAt: function(latlng) {
      const shape = this.feature.shapeAt(latlng)
      if (shape) return this.deleteShape(shape)
    },

    // 🍂method appendShape(shape: Array)
    // Append a new shape to the Polygon or Polyline.
    appendShape: function(shape) {
      this.insertShape(shape)
    },

    // 🍂method prependShape(shape: Array)
    // Prepend a new shape to the Polygon or Polyline.
    prependShape: function(shape) {
      this.insertShape(shape, 0)
    },

    // 🍂method insertShape(shape: Array, index: int)
    // Insert a new shape to the Polygon or Polyline at given index (default is to append).
    insertShape: function(shape, index) {
      this.ensureMulti()
      shape = this.formatShape(shape)
      if (typeof index === 'undefined') index = this.feature._latlngs.length
      this.feature._latlngs.splice(index, 0, shape)
      this.feature.redraw()
      if (this._enabled) this.reset()
    },

    extendBounds: function(e) {
      this.feature._bounds.extend(e.vertex.latlng)
    },

    onDragStart: function(e) {
      this.editLayer.clearLayers()
      L.Editable.BaseEditor.prototype.onDragStart.call(this, e)
    },

    onDragEnd: function(e) {
      this.initVertexMarkers()
      L.Editable.BaseEditor.prototype.onDragEnd.call(this, e)
    },
  })

  L.Editable.CircleEditor = L.Editable.PathEditor.extend({
    MIN_VERTEX: 2,

    options: {
      skipMiddleMarkers: true,
    },

    initialize: function(map, feature, options) {
      L.Editable.PathEditor.prototype.initialize.call(this, map, feature, options)
      this._resizeLatLng = this.computeResizeLatLng()

      // TODO add configurable custom labels -> console.info('Options', options.editTools)
      // Click and drag cursor to draw checkpoint
      this._initialLabelText = 'Нажмите и потяните курсор чтобы нарисовать контрольную точку'
      this._endLabelText = '' // 'Checkpoint__'

      this._isDrawing = false
    },

    addHooks: function() {
      L.Editable.PathEditor.prototype.addHooks.call(this)
      this._tooltip.updateContent({ text: this._initialLabelText })
      return this
    },

    computeResizeLatLng: function() {
      // While circle is not added to the map, _radius is not set.
      const delta = (this.feature._radius || this.feature._mRadius) * Math.cos(Math.PI / 4)
      const point = this.map.project(this.feature._latlng)
      return this.map.unproject([point.x + delta, point.y - delta])
    },

    updateResizeLatLng: function() {
      this._resizeLatLng.update(this.computeResizeLatLng())
      this._resizeLatLng.__vertex.update()
    },

    getLatLngs: function() {
      return [this.feature._latlng, this._resizeLatLng]
    },

    getDefaultLatLngs: function() {
      return this.getLatLngs()
    },

    onVertexMarkerDrag: function(e) {
      if (e.vertex.getIndex() === 1) this.resize(e)
      else this.updateResizeLatLng(e)
      L.Editable.PathEditor.prototype.onVertexMarkerDrag.call(this, e)
    },

    resize: function(e) {
      const radius = this.feature._latlng.distanceTo(e.latlng)
      this.feature.setRadius(radius)
    },

    onDrawingMouseDown: function(e) {
      L.Editable.PathEditor.prototype.onDrawingMouseDown.call(this, e)
      this._resizeLatLng.update(e.latlng)
      this.feature._latlng.update(e.latlng)
      this.connect()
      // Stop dragging map.
      e.originalEvent._simulated = false
      this.map.dragging._draggable._onUp(e.originalEvent)
      // Now transfer ongoing drag action to the radius handler.
      this._resizeLatLng.__vertex.dragging._draggable._onDown(e.originalEvent)

      this._isDrawing = true
    },

    onDrawingMouseUp: function(e) {
      this.commitDrawing(e)
      e.originalEvent._simulated = false
      L.Editable.PathEditor.prototype.onDrawingMouseUp.call(this, e)

      this._isDrawing = false
    },

    onDrawingMouseMove: function(e) {
      // TODO update tooltip
      // const showRadius = this.options.showRadius;
      const showRadius = true
      const useMetric = true

      this._tooltip.updatePosition(e.latlng)

      if (this._isDrawing) {
        const radius = this.feature._latlng.distanceTo(e.latlng).toFixed(1)
        this._tooltip.updateContent({
          text: this._endLabelText,
          subtext: showRadius ? 'Радиус: ' + L.GeometryUtil.readableDistance(radius, -1, useMetric) : '',
        })
      }

      e.originalEvent._simulated = false
      L.Editable.PathEditor.prototype.onDrawingMouseMove.call(this, e)
    },

    onDrag: function(e) {
      L.Editable.PathEditor.prototype.onDrag.call(this, e)
      this.feature.dragging.updateLatLng(this._resizeLatLng)
    },
  })

  L.Editable.PolygonEditor = L.Editable.PathEditor.extend({
    CLOSED: true,
    MIN_VERTEX: 3,

    newPointForward: function(latlng) {
      L.Editable.PathEditor.prototype.newPointForward.call(this, latlng)
      if (!this.tools.backwardLineGuide._latlngs.length) this.tools.anchorBackwardLineGuide(latlng)
      if (this._drawnLatLngs.length === 2) this.tools.attachBackwardLineGuide()
    },

    addNewEmptyHole: function(latlng) {
      this.ensureNotFlat()
      const latlngs = this.feature.shapeAt(latlng)
      if (!latlngs) return
      const holes = []
      latlngs.push(holes)
      return holes
    },

    // 🍂method newHole(latlng?: L.LatLng, index: int)
    // Set up drawing tools for creating a new hole on the Polygon.
    // If the `latlng` param is given, a first point is created.
    newHole: function(latlng) {
      const holes = this.addNewEmptyHole(latlng)
      if (!holes) return
      this.setDrawnLatLngs(holes)
      this.startDrawingForward()
      if (latlng) this.newPointForward(latlng)
    },

    addNewEmptyShape: function() {
      if (this.feature._latlngs.length && this.feature._latlngs[0].length) {
        const shape = []
        this.appendShape(shape)
        return shape
      } else {
        return this.feature._latlngs
      }
    },

    ensureMulti: function() {
      if (this.feature._latlngs.length && this._isFlat(this.feature._latlngs[0])) {
        this.feature._latlngs = [this.feature._latlngs]
      }
    },

    ensureNotFlat: function() {
      if (!this.feature._latlngs.length || this._isFlat(this.feature._latlngs)) {
        this.feature._latlngs = [this.feature._latlngs]
      }
    },

    vertexCanBeDeleted: function(vertex) {
      const parent = this.feature.parentShape(vertex.latlngs)
      const idx = L.Util.indexOf(parent, vertex.latlngs)
      if (idx > 0) return true // Holes can be totally deleted without removing the layer itself.
      return L.Editable.PathEditor.prototype.vertexCanBeDeleted.call(this, vertex)
    },

    getDefaultLatLngs: function() {
      if (!this.feature._latlngs.length) this.feature._latlngs.push([])
      return this.feature._latlngs[0]
    },

    formatShape: function(shape) {
    // [[1, 2], [3, 4]] => must be nested
    // [] => must be nested
    // [[]] => is already nested
      if (this._isFlat(shape) && (!shape[0] || shape[0].length !== 0)) return [shape]
      else return shape
    },
  })

  const EditableMixin = {
    createEditor: function(map) {
      map = map || this._map

      // console.info('mixin', this, 'map editTools', map)
      const tools = (this.options.editOptions || {}).editTools || map.editTools
      if (!tools) throw Error('Unable to detect Editable instance.')
      const Klass = this.options.editorClass || this.getEditorClass(tools)
      return new Klass(map, this, this.options.editOptions)
    },

    // 🍂method enableEdit(map?: L.Map): this.editor
    // Enable editing, by creating an editor if not existing, and then calling `enable` on it.
    enableEdit: function(map) {
      if (!this.editor) this.createEditor(map)
      this.editor.enable()
      return this.editor
    },

    // 🍂method editEnabled(): boolean
    // Return true if current instance has an editor attached, and this editor is enabled.
    editEnabled: function() {
      return this.editor && this.editor.enabled()
    },

    // 🍂method disableEdit()
    // Disable editing, also remove the editor property reference.
    disableEdit: function() {
      if (this.editor) {
        this.editor.disable()
        delete this.editor
      }
    },

    // 🍂method toggleEdit()
    // Enable or disable editing, according to current status.
    toggleEdit: function() {
      if (this.editEnabled()) this.disableEdit()
      else this.enableEdit()
    },

    _onEditableAdd: function() {
      if (this.editor) this.enableEdit()
    },
  }

  const CircleMixin = {
    getEditorClass: function(tools) {
      return (tools && tools.options.circleEditorClass) ? tools.options.circleEditorClass : L.Editable.CircleEditor
    },
  }

  const PolygonMixin = {
    getEditorClass: function(tools) {
      return (tools && tools.options.polygonEditorClass) ? tools.options.polygonEditorClass : L.Editable.PolygonEditor
    },

    shapeAt: function(latlng, latlngs) {
      // We can have those cases:
      // - latlngs are just a flat array of latlngs, use this
      // - latlngs is an array of arrays of latlngs, this is a simple polygon (maybe with holes), use the first
      // - latlngs is an array of arrays of arrays, this is a multi, loop over
      let shape = null
      latlngs = latlngs || this._latlngs
      if (!latlngs.length) return shape
      else if (this._isFlat(latlngs) && this.isInLatLngs(latlng, latlngs)) shape = latlngs
      else if (this._isFlat(latlngs[0]) && this.isInLatLngs(latlng, latlngs[0])) shape = latlngs
      else for (let i = 0; i < latlngs.length; i++) if (this.isInLatLngs(latlng, latlngs[i][0])) return latlngs[i]
      return shape
    },

    isInLatLngs: function(l, latlngs) {
      let inside = false; let l1; let l2; let j; let k; let len2

      for (j = 0, len2 = latlngs.length, k = len2 - 1; j < len2; k = j++) {
        l1 = latlngs[j]
        l2 = latlngs[k]

        if (((l1.lat > l.lat) !== (l2.lat > l.lat)) &&
                    (l.lng < (l2.lng - l1.lng) * (l.lat - l1.lat) / (l2.lat - l1.lat) + l1.lng)) {
          inside = !inside
        }
      }

      return inside
    },

    parentShape: function(shape, latlngs) {
      latlngs = latlngs || this._latlngs
      if (!latlngs) return
      let idx = L.Util.indexOf(latlngs, shape)
      if (idx !== -1) return latlngs
      for (let i = 0; i < latlngs.length; i++) {
        idx = L.Util.indexOf(latlngs[i], shape)
        if (idx !== -1) return latlngs[i]
      }
    },
  }

  // const keepEditable = function () {
  //   // Make sure you can remove/readd an editable layer.
  //   this.on('add', this._onEditableAdd);
  // };

  if (L.Circle) {
    L.Circle.include(EditableMixin)
    L.Circle.include(CircleMixin)
  }

  if (L.Polygon) {
    L.Polygon.include(EditableMixin)
    L.Polygon.include(PolygonMixin)
  }

  L.LatLng.prototype.update = function(latlng) {
    latlng = L.latLng(latlng)
    this.lat = latlng.lat
    this.lng = latlng.lng
  }

  L.PathDraggable = L.Draggable.extend({
    initialize: function(path) {
      this._path = path
      this._canvas = (path._map.getRenderer(path) instanceof L.Canvas)
      const element = this._canvas ? this._path._map.getRenderer(this._path)._container : this._path._path
      L.Draggable.prototype.initialize.call(this, element, element, true)
    },

    _updatePosition: function() {
      const e = { originalEvent: this._lastEvent }
      this.fire('drag', e)
    },

    _onDown: function(e) {
      const first = e.touches ? e.touches[0] : e
      this._startPoint = new L.Point(first.clientX, first.clientY)
      if (this._canvas && !this._path._containsPoint(this._path._map.mouseEventToLayerPoint(first))) {return}
      L.Draggable.prototype._onDown.call(this, e)
    },
  })

  L.Handler.PathDrag = L.Handler.extend({
    initialize: function(path) {
      this._path = path
    },

    getEvents: function() {
      return {
        dragstart: this._onDragStart,
        drag: this._onDrag,
        dragend: this._onDragEnd,
      }
    },

    addHooks: function() {
      if (!this._draggable) {this._draggable = new L.PathDraggable(this._path)}
      this._draggable.on(this.getEvents(), this).enable()
      L.DomUtil.addClass(this._draggable._element, 'leaflet-path-draggable')
    },

    removeHooks: function() {
      this._draggable.off(this.getEvents(), this).disable()
      L.DomUtil.removeClass(this._draggable._element, 'leaflet-path-draggable')
    },

    moved: function() {
      return this._draggable && this._draggable._moved
    },

    _onDragStart: function() {
      this._startPoint = this._draggable._startPoint
      this._path
          .closePopup()
          .fire('movestart')
          .fire('dragstart')
    },

    _onDrag: function(e) {
      const path = this._path
      const event = (e.originalEvent.touches && e.originalEvent.touches.length === 1 ?
        e.originalEvent.touches[0] : e.originalEvent)
      const newPoint = L.point(event.clientX, event.clientY)
      const latlng = path._map.layerPointToLatLng(newPoint)

      this._offset = newPoint.subtract(this._startPoint)
      this._startPoint = newPoint

      this._path.eachLatLng(this.updateLatLng, this)
      path.redraw()

      e.latlng = latlng
      e.offset = this._offset
      path.fire('drag', e)
      e.latlng = this._path.getCenter ? this._path.getCenter() : this._path.getLatLng()
      path.fire('move', e)
    },

    _onDragEnd: function(e) {
      if (this._path._bounds) this.resetBounds()
      this._path.fire('moveend')
          .fire('dragend', e)
    },

    latLngToLayerPoint: function(latlng) {
      // Same as map.latLngToLayerPoint, but without the round().
      const projectedPoint = this._path._map.project(L.latLng(latlng))
      return projectedPoint._subtract(this._path._map.getPixelOrigin())
    },

    updateLatLng: function(latlng) {
      const oldPoint = this.latLngToLayerPoint(latlng)
      oldPoint._add(this._offset)
      const newLatLng = this._path._map.layerPointToLatLng(oldPoint)
      latlng.lat = newLatLng.lat
      latlng.lng = newLatLng.lng
    },

    resetBounds: function() {
      this._path._bounds = new L.LatLngBounds()
      this._path.eachLatLng((latlng) => {
        this._path._bounds.extend(latlng)
      })
    },
  })

  L.Path.include({
    eachLatLng: function(callback, context) {
      context = context || this
      const loop = function(latlngs) {
        for (let i = 0; i < latlngs.length; i++) {
          if (L.Util.isArray(latlngs[i])) loop(latlngs[i])
          else callback.call(context, latlngs[i])
        }
      }
      loop(this.getLatLngs ? this.getLatLngs() : [this.getLatLng()])
    },
  })

  L.Path.addInitHook(function() {
    // eslint-disable-next-line no-invalid-this
    this.dragging = new L.Handler.PathDrag(this)
    // eslint-disable-next-line no-invalid-this
    if (this.options.draggable) {
      // eslint-disable-next-line no-invalid-this
      this.once('add', function() {
        // eslint-disable-next-line no-invalid-this
        this.dragging.enable()
        console.info('Dragging enabled')
      })
    }
  })

  L.GeometryUtil = L.extend(L.GeometryUtil || {}, {
    geodesicArea: function(latLngs) {
      const pointsCount = latLngs.length
      let area = 0.0
      const d2r = L.LatLng.DEG_TO_RAD
      let p1; let p2

      if (pointsCount > 2) {
        for (let i = 0; i < pointsCount; i++) {
          p1 = latLngs[i]
          p2 = latLngs[(i + 1) % pointsCount]
          area += ((p2.lng - p1.lng) * d2r) *
              (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r))
        }
        area = area * 6378137.0 * 6378137.0 / 2.0
      }
      return Math.abs(area)
    },

    readableArea: function(area, isMetric) {
      let areaStr

      if (isMetric) {
        if (area >= 10000) {
          areaStr = (area * 0.0001).toFixed(2) + ' ha'
        } else {
          areaStr = area.toFixed(2) + ' m&sup2;'
        }
      } else {
        area /= 0.836127 // Square yards in 1 meter

        if (area >= 3097600) { // 3097600 square yards in 1 square mile
          areaStr = (area / 3097600).toFixed(2) + ' mi&sup2;'
        } else if (area >= 4840) {// 48040 square yards in 1 acre
          areaStr = (area / 4840).toFixed(2) + ' acres'
        } else {
          areaStr = Math.ceil(area) + ' yd&sup2;'
        }
      }
      return areaStr
    },

    readableDistance: function(distance, bearing, isMetric) {
      let distanceStr; let bearingStr
      bearingStr = ''
      if (bearing >= 0) {
        bearingStr = ' at ' + bearing.toFixed(0) + '°'
      }
      if (isMetric) {
        // show metres when distance is < 1km, then show km
        if (distance > 1000) {
          distanceStr = (distance / 1000).toFixed(2) + ' km' + bearingStr
        } else {
          distanceStr = Math.ceil(distance) + ' m' + bearingStr
        }
      } else {
        const nm = distance / 1852

        if (nm > 10.0) distanceStr = nm.toFixed(1) + ' NM' + bearingStr
        else if (nm > 1.0) distanceStr = nm.toFixed(2) + ' NM' + bearingStr
        else distanceStr = nm.toFixed(2) + ' NM' + bearingStr
      }
      return distanceStr
    },

    /**
     * Calculate the bearing between two positions as a value from 0-360
     *
     * @param {*} alat1 - The latitude of the first position
     * @param {*} alng1 - The longitude of the first position
     * @param {*} alat2 - The latitude of the second position
     * @param {*} alng2 - The longitude of the second position
     *
     * @return {Number} int - The bearing between 0 and 360
     */
    bearing: function(alat1, alng1, alat2, alng2) {
      const lat1 = this._toRad(alat1)
      const lng1 = this._toRad(alng1)
      const lat2 = this._toRad(alat2)
      const lng2 = this._toRad(alng2)
      const dLon = (lng2 - lng1)
      const y = Math.sin(dLon) * Math.cos(lat2)
      const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)
      const brng = this._toDeg(Math.atan2(y, x))
      return ((brng + 360) % 360)
    },

    /**
     * Since not all browsers implement this we have our own utility that will
     * convert from degrees into radians
     *
     * @param {*} deg - The degrees to be converted into radians
     * @return {Number} radians
     */
    _toRad: function(deg) {
      return deg * Math.PI / 180
    },

    /**
     * Since not all browsers implement this we have our own utility that will
     * convert from radians into degrees
     *
     * @param {*} rad - The radians to be converted into degrees
     * @return {Number} degrees
     */
    _toDeg: function(rad) {
      return rad * 180 / Math.PI
    },

    formatPositionTextForLatitude: function(latitude, longitude, oneLine) {
      const degrees = Math.floor(latitude)
      const decimal = Math.abs(latitude - degrees)
      const minutesandseconds = decimal * 60.0
      const minutes = Math.floor(minutesandseconds)
      const seconds = (minutesandseconds - minutes) * 60.0
      // double seconds = decimal * 3600 - minutes * 60;
      let lat = degrees + '°' + minutes + '\'' + seconds.toFixed(2) + '" N'

      if (latitude < 0.0) lat = -degrees + '°' + minutes + '\'' + seconds.toFixed(2) + '" S'
      // Add N and S and E and W labels
      // degrees = Math.floor(longitude);
      // decimal = Math.abs(longitude - degrees);
      // var minutesandseconds = decimal * 60.0;
      // var minutes = Math.floor(minutesandseconds);
      // var seconds = (minutesandseconds - minutes) * 60.0;

      let longt = degrees + '°' + minutes + '\'' + seconds.toFixed(2) + '" E'
      if (longitude < 0.0) longt = -degrees + '°' + minutes + '\'' + seconds.toFixed(2) + '" W'

      let label = lat + ',' + longt
      if (!oneLine) label = lat + '<br>' + longt
      return label
    },

    formatPositionTextForDecimalMinutes: function(latitude, longitude, oneLine) {
      const degrees = Math.trunc(latitude)
      const decimal = Math.abs(latitude - degrees)
      const minutesandseconds = decimal * 60.0
      // var minutes = Math.floor(minutesandseconds);
      // var seconds = (minutesandseconds - minutes) * 60.0;
      // double seconds = decimal * 3600 - minutes * 60;
      let lat = degrees + '°' + minutesandseconds.toFixed(2) + '\' N'
      if (latitude < 0.0) lat = -degrees + '°' + minutesandseconds.toFixed(2) + '\' S'
      // Add N and S and E and W labels
      // degrees = Math.trunc(longitude);
      // decimal = Math.abs(longitude - degrees);
      // var minutesandseconds = decimal * 60.0;
      // var minutes = Math.floor(minutesandseconds);
      // var seconds = (minutesandseconds - minutes) * 60.0;

      let longt = degrees + '°' + minutesandseconds.toFixed(2) + '\' E'
      if (longitude < 0.0) longt = -degrees + '°' + minutesandseconds.toFixed(2) + '\' W'
      let label = lat + ',' + longt
      if (!oneLine) label = lat + '<br>' + longt
      return label
    },

    formatLatLong: function(lat, lng, oneline) {
      const latitude = Number(lat)
      const longitude = Number(lng)
      return this.formatPositionTextForLatitude(latitude, longitude, oneline)
    },
  })

  const defaultOptions = {
    position: 'topright',
    features: [],
  }

  L.EditControl = L.Control.extend({
    options: {},

    // _links: {},
    // _active: null,
    _features: {},

    onAdd: function(map) {
      // const container = L.DomUtil.create('div', styles.section);
      const container = L.DomUtil.create('div', `leaflet-control leaflet-bar`)
      // this._toolbarContainer = L.DomUtil.create('div', `leaflet-control leaflet-bar`);
      this._map = map
      this.options = Object.assign({}, defaultOptions, map.options.editOptions.controls)

      let buttonIndex = 0
      for (let i = 0; i < this.options.features.length; i++) {
        this._initHandler(
            this._map.editTools[this.options.features[i].handler],
            container,
            buttonIndex++,
            this.options.features[i].icon,
            this.options.features[i].title,
        )
      }

      // if no buttons were added, do not add the toolbar
      // if (!buttonIndex) return;

      // container.appendChild(this._toolbarContainer);
      return container
    },

    _initHandler: function(handler, container, buttonIndex, icon, buttonTitle) {
      const type = handler.type

      this._features[type] = {}

      this._features[type].handler = handler

      this._features[type].button = this._createButton({
        title: buttonTitle,
        icon: icon,
        container: container,
        callback: handler,
        // callback: this._features[type].handler.enable,
        // context: this._features[type].handler
        context: this,
      })

      this._features[type].buttonIndex = buttonIndex

      // this._features[type].handler
      //   .on('enabled', this._handlerActivated, this)
      //   .on('disabled', this._handlerDeactivated, this);
    },

    _createButton: function(options) {
      const link = L.DomUtil.create('a', options.className || '', options.container)
      link.href = '#'

      if (options.icon) {
        link.innerHTML = options.icon
      }

      if (options.title) {
        link.title = options.title
      }

      const callback = () => {
        options.callback.call(this._map.editTools)
      }

      L.DomEvent
          .on(link, 'click', L.DomEvent.stopPropagation)
          .on(link, 'mousedown', L.DomEvent.stopPropagation)
          .on(link, 'dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.preventDefault)
          .on(link, 'click', callback, options.context)

      return link
    },

    _handlerActivated: function() {

    },

    _handlerDeactivated: function() {

    },

    _toggle: function() {
      if (this._active) {
        this._active._deactiate()
      } else {
        this._activate()
        this._active = this
      }
    },

    _activate: function() {
      this._active = true
      L.DomUtil.addClass(this._links[this.options.kind], styles.controlActive)

      this._features[this.options.kind] = this.options.callback.call(this._map.editTools)
    },

    _deactiate: function() {
      this._active = false
      L.DomUtil.removeClass(this._links[this.options.kind], styles.controlActive)

      this._features[this.options.kind].editor.cancelDrawing()
    },

    _disableAllPrevActive: function() {
      for (const kind in this._actives) {
        if (kind === this.options.kind) continue

        this._actives[kind] = false
      }
    },
  })

  L.Map.mergeOptions({
    editToolsClass: L.Editable,
    editable: false,
    editOptions: {},
  })

  L.Map.addInitHook(function() {
    // eslint-disable-next-line no-invalid-this
    this.whenReady(function() {
      // eslint-disable-next-line no-invalid-this
      if (this.options.editable) {
        // eslint-disable-next-line no-invalid-this
        const EditToolsClass = this.options.editToolsClass
        // eslint-disable-next-line no-invalid-this
        this.editTools = new EditToolsClass(this, this.options.editOptions)
      }
    })
  })
}, window))
