import React from 'react'
import PropTypes from 'prop-types'
import { parseJSON } from 'date-fns'

import { getReadableDuration, getReadableTime } from '../common'

const cardStyle = { padding: '10px', minWidth: '250px' }

export const StopHelperCard = ({ stop, asTableRow }) => {
  const start = parseJSON(stop.timeBegin)
  const end = parseJSON(stop.timeEnd)
  const delta = end - start

  const startString = getReadableTime(start)
  const endString = getReadableTime(end)
  const durationString = getReadableDuration(delta)

  // const onCardClick = () => {
  //   onClick(id, stop.coords)
  // }

  if (asTableRow) {
    return (
      // <tr onClick={onCardClick} style={onClick ? { cursor: 'pointer' } : {}}>
      <tr>
        <td>
          {startString}
        </td>

        <td>
          {endString}
        </td>

        <td>
          {durationString}
        </td>

        {stop.properties.map((prop, key) => {
          return (
            <td key={key}>{prop.sValue}</td>
          )
        })}
      </tr>
    )
  }

  return (
    <div style={cardStyle}>
      {/* <h3 onClick={onCardClick} style={onClick ? { cursor: 'pointer' } : {}}>
        Остановка
      </h3> */}
      <h3>Остановка</h3>

      <div>
        {'Начало: ' + startString}
      </div>

      <div>
        {'Конец: ' + endString}
      </div>

      <div>
        {'Продолжительность: ' + durationString}
      </div>

      {stop.properties.map((prop, key) => {
        return (
          <div key={key}>{prop.name + ' ' + prop.sValue}</div>
        )
      })}
    </div>
  )
}

StopHelperCard.propTypes = {
  // id: PropTypes.number.isRequired,
  // onClick: PropTypes.func,
  stop: PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      sValue: PropTypes.shape.isRequired,
    })).isRequired,
  }),
  asTableRow: PropTypes.bool,
}
