import L from 'leaflet'

export const warningDivIcon = (
    size,
    fillColor = '#ffbf00',
    strokeColor = '#000'
) => new L.DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
    <svg
      width="${size}" height="${size}"
      version="1.1"
      viewBox="0 0 128.7 117.7"
      xmlns="http://www.w3.org/2000/svg"
    >
     <g transform="translate(-40.64 -89.67)">
      <g transform="matrix(.2646 0 0 .2646 40.65 84.14)">
       <path fill="${strokeColor}" d="m243.2 333.4c-13.6 0-25 11.4-25 25s11.\
4 25 25 25c13.1 0 25-11.4 24.4-24.4 0.6-14.3-10.7-25.6-24.4-25.6z"/>
       <path fill="${strokeColor}" d="m474.6 422c15.7-27.1 15.8-59.4 0.2-86.4l-\
156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4 16.3-74.9 43.4l-156.8 271.5c-\
15.6 27.3-15.5 59.8 0.3 86.9 15.6 26.8 43.5 42.9 74.7 42.9h312.8c31.3 0 59.4-\
16.3 75.2-43.6zm-34-19.6c-8.7 15-24.1 23.9-41.3 23.9h-312.8c-17 0-32.3-8.7-\
40.8-23.4-8.6-14.9-8.7-32.7-0.1-47.7l156.8-271.4c8.5-14.9 23.7-23.7 40.9-23.\
7 17.1 0 32.4 8.9 40.9 23.8l156.7 271.4c8.4 14.6 8.3 32.2-0.3 47.1z"/>
       <path fill="${strokeColor}" d="m237 157.9c-11.9 3.4-19.3 14.2-19.3 27.\
3 0.6 7.9 1.1 15.9 1.7 23.8 1.7 30.1 3.4 59.6 5.1 89.7 0.6 10.2 8.5 17.\
6 18.7 17.6s18.2-7.9 18.7-18.2c0-6.2 0-11.9 0.6-18.2 1.1-19.3 2.3-38.\
6 3.4-57.9 0.6-12.5 1.7-25 2.3-37.5 0-4.5-0.6-8.5-2.3-12.5-5.1-11.2-\
17-16.9-28.9-14.1z"/>
      </g>
      <path
        transform="matrix(.2646 0 0 .2646 40.64 89.67)"
        fill="${fillColor}" stroke="${fillColor}" stroke-width="10"
        d="m65.64 400c-17.\
54-8.647-28.85-31.06-25.21-49.96 1.014-5.259 38.97-73.85 84.34-152.4 62.12-107.\
6 84.77-144.4 91.7-149.2 18.87-13.03 43.92-11.08 58.69 4.568 10.04 10.64 166.\
3 281.5 169.9 294.4 5.407 19.58-7.815 45.18-27.61 53.45-7.522 3.143-41.56 3.\
885-175.8 3.832-147.7-0.05772-167.6-0.5878-175.9-4.7zm195.3-45.65c21.25-22.\
93-9.367-54.5-34.04-35.09-5.876 4.622-7.528 8.413-7.528 17.27 0 23.23 26.15 34.\
45 41.56 17.82zm-6.172-64.58c6.98-6.284 7.218-7.855 10.62-70.04 3.779-69.13 3.\
123-74.04-10.75-80.35-14.9-6.787-27.93-2.13-33.75 12.06-3.543 8.636-3.024 25.\
42 4.055 131.2 0.2984 4.458 11.8 13.57 17.13 13.57 3.055 0 8.768-2.893 12.7-6.\
429z" fill="#ffffff" stroke="#ffffff" stroke-width="10.8"/>
     </g>
    </svg>`,
  iconAnchor: [size / 2, size / 2],
  popupAnchor: [0, -size / 2],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [size, size],
  className: '', // 'with-shadow',
})
