import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-leaflet'


import { ExtendedMarker, StopHelperCard } from '../components'
import { parkingDivIcon } from '../icons'

// TODO open handlers

export const StopMarker = (props) => {
  return (
    <ExtendedMarker
      position={[props.stop.coords.lat, props.stop.coords.lon]}
      icon={parkingDivIcon(24)}
      openPopup={props.openPopup}
      onClick={props.onClick}
    >
      <Popup /* onOpen={props.onClick} onClose={props.onClick}*/ >
        <StopHelperCard id={props.stop.id} stop={props.stop} />
      </Popup>
      {/* {props.openPopup && <Tooltip permanent>
        <StopHelperCard stop={props.stop} />
      </Tooltip>} */}
    </ExtendedMarker>
  )
}

StopMarker.propTypes = {
  openPopup: PropTypes.bool,
  stop: PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      sValue: PropTypes.shape.isRequired,
    })).isRequired,
  }),
  onClick: PropTypes.func,
}

StopMarker.defaultProps = {
  openPopup: false,
}
