
const strLen = 32

export const shortString = (str) => {
  const len = str.length
  if (len > strLen) {
    const cut = str.split('')
    const trimLen = (strLen - 2) / 2
    const trimPoint = len - trimLen

    return [
      ...cut.slice(0, trimLen),
      '...',
      ...cut.slice(trimPoint, len),
    ].join('')
  }
  return str
}

export const parseTitle = (device) => {
  let name = device.imei
  if (device.model && device.model.length !== 0) {
    name = device.model
  }

  if (device.number && device.number.length !== 0) {
    if (name.length !== 0) {
      name += ' (' + device.number + ')'
    } else {
      name += device.number
    }
  }
  return {
    short: shortString(name),
    full: name,
  }
}
