import React, { useRef, useEffect, useState, Suspense } from 'react'
import {Switch, Route, withRouter, Redirect} from 'react-router-dom'
import { observer } from 'mobx-react'
import { Map, ZoomControl, LayersControl, TileLayer } from 'react-leaflet'
import { CompassOutlined, ContainerOutlined, EnvironmentOutlined, FlagOutlined,
  LineChartOutlined, UserOutlined } from '@ant-design/icons'


import SideControlPanel from './SideControlPanel'
import { useStore } from '../stores/rootStore'
import TrackLayer from './TrackLayer'


// TODO conditional plugin import
import '../tools/editable/initGlobal'


const calcBbox = (map) => {
  const bounds = map.getBounds()
  return [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()]
}

const attr = '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

const availableLayers = [{
  name: 'OpenStreetMap.Mapnik',
  attribution: attr,
  tiles: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
}, {
  name: 'OpenStreetMap.De',
  attribution: attr,
  tiles: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
}, {
  name: 'Esri Satellite',
  attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, ' +
    'Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  tiles: 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
}]

// TODO optional depth layer?
// const depthTiles = 'http://{s}.tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png'
// const showDepthLayer = false
// {showDepthLayer && <TileLayer url={depthTiles} />}

const minZoom = 3
const maxZoom = 17

const features = [
  {
    name: 'navigation',
    title: 'Навигация',
    absolutePath: `/`,
    icon: CompassOutlined,
    control: React.lazy(() => import('./NavigationControl')),
    layer: React.lazy(() => import('./ClusterLayer')),
  },
  {
    name: 'track',
    title: 'Запрос трека',
    absolutePath: `/track`,
    feature: 'tracks',
    icon: FlagOutlined,
    control: React.lazy(() => import('./TrackControl')),
    layer: TrackLayer, // React.lazy(() => import('./TrackLayer')),
  },
  {
    name: 'reports',
    title: 'Отчеты',
    absolutePath: `/reports`,
    feature: 'reportsVisibility',
    icon: ContainerOutlined,
    control: React.lazy(() => import('./ReportsControl/ReportsControl')),
  },
  {
    name: 'charts',
    title: 'Графики',
    absolutePath: `/charts`,
    feature: 'charts',
    icon: LineChartOutlined,
    control: React.lazy(() => import('./ChartsControl')),
  },
  {
    name: 'geozones',
    title: 'Геозоны',
    absolutePath: `/geozones`,
    feature: 'geozones',
    icon: EnvironmentOutlined,
    control: React.lazy(() => import('./GeozonesControl')),
    layer: React.lazy(() => import('./GeozoneLayer')),
  },
  {
    name: 'user',
    title: 'Пользователь',
    absolutePath: `/settings`,
    icon: UserOutlined,
    control: React.lazy(() => import('./SettingsControl')),
  }
]

const MapContainer = (props) => {
  const store = useStore()
  const mapRef = useRef(null)

  useEffect(() => {
    store.ui.mapState.ref = mapRef
    return () => {
      store.ui.mapState.ref = null
    }
  }, [store])

  const handleMapLoadEnd = (event) => {
    store.map.setBounds(calcBbox(event.target))
  }

  const handleMoveend = (event) => {
    store.map.setMapCenter(event.target.getCenter())
    store.map.setBounds(calcBbox(event.target))
    // this.map.stopTracking()
  }

  const handleZoomend = (event) => {
    store.map.setZoom(event.target.getZoom())
    store.map.setBounds(calcBbox(event.target))
  }

  const allowGeozones = store.profile.allowGeozones

  const savedTiles = window.localStorage.getItem('checkedTiles')
  const currentTiles = savedTiles != null ? savedTiles : 'OpenStreetMap.Mapnik'
  const [checked, setChecked] = useState(currentTiles)

  const userID = store.profile.user.userID !== store.profile.invoker.userID 
      ? store.profile.user.userID : '0'

  const allowedFeatures = []
  features.forEach((ft) => {
    if (ft.feature != null && !store.profile.allowFeature(ft.feature)) {
      return
    }
    ft.path = `/maps/${userID}`+ft.absolutePath
    allowedFeatures.push(ft)
  })

  return (
    <div className="core">
      <div className="map-layout">
        <SideControlPanel features={allowedFeatures} />

        <Map
          className="leaflet-container"
          ref={mapRef}
          preferCanvas={true}
          center={store.map.currentMapCenter}
          zoom={store.map.currentZoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onMoveend={handleMoveend}
          onZoomend={handleZoomend}
          whenReady={handleMapLoadEnd}
          editable={allowGeozones}
          drawTooltips={allowGeozones}
          zoomControl={false}
          onbaselayerchange={(e) => {
            setChecked(e.name)
            window.localStorage.setItem('checkedTiles', e.name)
          }}
        >
          <LayersControl position='topright'>
            {availableLayers.map((layer) => (
              <LayersControl.BaseLayer key={`tl-${layer.name}`} checked={checked === layer.name} name={layer.name}>
                <TileLayer attribution={layer.attribution} url={layer.tiles} />
              </LayersControl.BaseLayer>
            ))}
          </LayersControl>

          <ZoomControl position="topright" />

          <Suspense fallback={<div>Загрузка...</div>}>
            <Switch>
              {allowedFeatures.map((ft) => {
                let layer = ft.layer
                if (layer== null) {
                  layer = () => null
                }
                return <Route exact key={ft.name} path={ft.path} component={layer} />
              })}
              <Redirect from='*' to={`/maps/${userID}/`} />
            </Switch>
          </Suspense>
        </Map>
      </div>
    </div>
  )
}

export const MapLayoutContainer = withRouter(observer(MapContainer))
