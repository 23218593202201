import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { differenceInSeconds } from 'date-fns'


import api from '../api'
import { prod } from '../common'
import {
  IGeozone,
  IGeozoneExtended,
  IGeozoneUpdate,
  ISelectedGeozone,
  RPCError,
} from './interfaces'
import { SystemGeozone } from './SystemGeozone'


const reloadPeriod = 60


class GeozonesStore {
  private rootStore: any
  private geozonesList: IGeozoneExtended[]
  private lastUpdate: Date
  private filter: string
  selectedTags: number[]
  refs: Map<string, any>
  hidden: string[] // Map<string, boolean>
  loading: boolean
  error: RPCError | null
  editedGeozone: string | null
  selectedGeozone: ISelectedGeozone
  tempBuffer: IGeozoneExtended | null

  constructor(rootStore: any) {
    this.rootStore = rootStore

    // let hmap = new Map()
    let hmap: string[] = []
    const rawHidden = window.localStorage.getItem('gzhidden')
    if (rawHidden != null) {
      try {
        hmap = JSON.parse(rawHidden)
      } catch (err) {
        console.error('Unable to parse saved hidden geozones')
      }
    }

    this.geozonesList = []
    this.lastUpdate = new Date(0)
    this.filter = ''
    this.selectedTags = []
    this.refs = new Map()
    this.hidden = hmap
    this.loading = false
    this.error = null

    this.editedGeozone = null
    this.selectedGeozone = {
      id: null,
      prev: null,
    }

    this.tempBuffer = null

    window.addEventListener("beforeunload", (e: BeforeUnloadEvent) => {
      e.preventDefault()
      window.localStorage.setItem('gzhidden', JSON.stringify(this.hidden))
    })

    makeAutoObservable(this, {}, { autoBind: true })
  }

  setRef(id: string, ref: any) {
    this.refs.set(id, ref)
  }

  clear() {
    this.geozonesList = []
    this.lastUpdate = new Date(0)
    this.loading = false
    this.error = null
  }

  setFilter(filter: string) {
    this.filter = filter.toLowerCase()
  }

  setTags(tags: number[]) {
    this.selectedTags = tags
  }

  get isGeozoneChanged(): boolean {
    if (this.selectedGeozone.id === 'new') {
      return true
    }

    if (this.editedGeozone == null) {
      return false
    }

    if (this.selectedGeozone.id == null) {
      return false
    }

    if (this.tempBuffer == null) {
      return false
    }

    const geozone = this.geozonesList.find((g) => g.id === this.selectedGeozone.id)
    if (geozone == null) {
      return false
    }
    return !this.tempBuffer.equals(geozone)
  }

  /**
   * @param {string} id 
   * @param {bool} state
   */
  setHidden(id: string, state: boolean = true) {
    if (!state) {
      const index = this.hidden.findIndex((hid) => hid === id)
      if (index !== -1) {
        this.hidden.splice(index, 1)
      }
    } else {
      this.hidden.push(id)

      if (this.selectedGeozone.id === id) {
        this.setGeozone(null)
      }

      if (this.editedGeozone === id) {
        this.setEdit(null)
      }
    }

    // if (this.hidden.get(id) === state) return
    // if (!state) {
    //   this.hidden.delete(id)
    // } else {
    //   this.hidden.set(id, state)

    //   if (this.selectedGeozone.id === id) {
    //     this.setGeozone(null)
    //   }
    // }
  }

  isHidden(id: string): boolean {
    return this.hidden.includes(id)
  }

  get geozones(): IGeozoneExtended[] {
    if (this.error != null) {
      return this.geozonesList
    }

    if (differenceInSeconds(Date.now(), this.lastUpdate) > reloadPeriod) {
      this.loadGeozones(this.rootStore.profile.user.userID)
    }

    return this.geozonesList.filter((g) => g.title.toLowerCase().includes(this.filter))
  }

  // get geozonesFiltered(): IGeozoneExtended[] {
  //   return this.geozones.filter((g) => g.title.toLowerCase().includes(this.filter))
  // }

  private async loadGeozones(userID: string) {
    runInAction(() => {
      this.loading = true
    })

    try {
      const geozones = await api.geozones.list(userID)

      const newGeozones = geozones.map((geo) => {
        return new SystemGeozone(geo)
      })

      const newGeozone = this.geozonesList.find((g) => g.id === 'new')


      runInAction(() => {
        this.geozonesList = newGeozones
        if (newGeozone != null) {
          this.geozonesList.push(newGeozone)
        }
        this.loading = false
        this.lastUpdate = new Date()
      })
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
      throw err
    }
  }

  addGeozones(geozones: IGeozone[]) {
    // FIXME this method
    // this.geozones = geozones.map((geo) => {
    //   geo.visuals = makeVisuals(geo.visual_props.stroke, geo.visual_props.fill)
    //   return geo
    // })
  }

  setEdit(id: string | null) {
    const prevID = this.editedGeozone
    if (this.editedGeozone !== id) {
      this.editedGeozone = id
    }

    if (id === null) {
      // Rolling back changes
      const prevIndex = this.geozonesList.findIndex((g) => g.id === prevID)
      if (this.tempBuffer != null && this.geozonesList[prevIndex] != null && this.geozonesList[prevIndex].id !== 'new') {
        !prod && console.info('Checking up buffer, looking for some changes', toJS(this.geozonesList[prevIndex].props), toJS(this.tempBuffer.props))
        if (!this.tempBuffer.equals(this.geozonesList[prevIndex])) {
          !prod && console.info('Got some changes, rollback')
          this.geozonesList[prevIndex] = SystemGeozone.copy(this.tempBuffer)
        }
      }
    }
  }

  focus(id: string) {
    const geo = this.geozonesList.find((g) => g.id === id)
    if (geo != null) {
      this.rootStore.map.fitBounds(geo.bbox)
    }
  }

  setGeozone(id: string | null) {
    if (this.selectedGeozone.id === id) {
      return
    }

    // Disable edit for current or previous
    const prevID = this.selectedGeozone.id
    this.selectedGeozone.prev = prevID
    this.selectedGeozone.id = id

    if (id == null) {
      this.setBuffer(null)
      return
    }

    const geo = this.geozonesList.find((g) => g.id === id)

    !prod && console.info('Selected geozone', geo, toJS(this.geozonesList))

    if (geo != null) {
      // saving geozone state before changes
      // if (geo.id !== 'new') {
      !prod && console.info('Backing up geozone before changes', toJS(geo))
      this.setBuffer(geo)
      // }

      // FIXME conditions, use first case only if geozone don't fit screen
      if (this.rootStore.map.currentBounds[0] > geo.bbox[0] || this.rootStore.map.currentBounds[1] > geo.bbox[1] ||
        this.rootStore.map.currentBounds[2] < geo.bbox[2] || this.rootStore.map.currentBounds[3] < geo.bbox[3]) {
        // this.rootStore.map.fitBounds(geo.bbox, { x: 300 })
        this.rootStore.map.fitBounds(geo.bbox)
      } else {
        this.rootStore.map.setMapCenter(geo.center)
      }
    }
  }

  removeNewGeozone() {
    const idx = this.geozonesList.findIndex((geo) => geo.id === 'new')
    if (idx !== -1) {
      runInAction(() => {
        this.geozonesList.splice(idx, 1)
      })
    }
  }

  setBuffer(geozone: IGeozoneExtended | null) {
    if (geozone != null) {
      this.tempBuffer = SystemGeozone.copy(geozone)
    } else {
      this.tempBuffer = null
    }
  }

  createGeozone(geozone: IGeozoneUpdate) {
    const index = this.geozonesList.findIndex((g) => g.id === geozone.id)
    if (index === -1) {
      this.geozonesList.push(SystemGeozone.makeFromUpdated(geozone))
    } else {
      this.geozonesList[index].updateFromLayer(geozone)
    }
  }

  async saveCurrentGeozone(userID: string) {
    // TODO replace with buffered variant
    if (this.selectedGeozone.id == null) return
    const geozone = this.geozonesList.find((geo) => geo.id === this.selectedGeozone.id)
    if (!geozone) return // TODO throw error

    const promise = geozone.id === 'new'
      ? api.geozones.create(userID, geozone.idGeozone)
      : api.geozones.update(geozone.idGeozone)

    const resp = await promise
    try {
      geozone.setID(resp.id)
      this.setBuffer(geozone)
      this.setGeozone(resp.id)
      return resp
    } catch (err) {
      if (!prod) {
        console.error('Geozones save err', err)
      }

      // FIXME remove?
      if (err.name === 'NetworkError' && err.response.status === 401) {
        this.rootStore.profile.logout()
      }

      throw err
    }
  }

  async removeCurrentGeozone() {
    const id = this.selectedGeozone.id
    if (id == null) {
      return // TODO throw error?
    }

    await api.geozones.delete(id)
    try {
      const index = this.geozonesList.findIndex((g) => g.id === id)
      if (index !== -1) {
        runInAction(() => {
          this.setEdit(null)
          this.setGeozone(null)
          this.geozonesList.splice(index, 1)
        })
      }
    } catch (err) {
      throw err
    }
  }

  async uploadGeozone(userID: string, geozone: IGeozone): Promise<IGeozone> {
    const updatedGeozone = await api.geozones.create(userID, geozone)
    return updatedGeozone
  }
}

export default GeozonesStore
