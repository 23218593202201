interface TSortable { [index: string]: any }


export const sortBy = (field: string = '', asc: boolean = true) => (a?: TSortable | string, b?: TSortable | string) => {
  if (a == null || b == null) {
    return 0
  }

  const afr = typeof a === 'string' ? a : a[field]
  const bfr = typeof b === 'string' ? b : b[field]

  if (afr == null && bfr == null) {
    return 0
  }

  if (afr == null) {
    return asc ? -1 : 1
  }

  if (bfr == null) {
    return asc ? 1 : -1
  }

  const af = typeof afr === 'string' ? afr.toUpperCase() : afr
  const bf = typeof bfr === 'string' ? bfr.toUpperCase() : bfr

  if (afr.length === 0) {
    return asc ? 1 : -1
  }

  if (bfr.length === 0) {
    return asc ? -1 : 1
  }

  if (af < bf) return asc ? -1 : 1
  if (af > bf) return asc ? 1 : -1
  return 0
}
