import React from 'react'
import PropTypes from 'prop-types'

import { Marker } from 'react-leaflet'

/**
 * ExtendedMarker component with popup open state
 */
export class ExtendedMarker extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props)

    this.toTop = 9001
    this.toFloor = 200

    this.state = {
      openPopup: false,
    }

    this.myRef = React.createRef()
  }

  /**
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.openPopup !== this.props.openPopup) {
      const isOpen = this.props.openPopup

      if (isOpen) {
        this.myRef.current.leafletElement.openPopup()
        this.myRef.current.leafletElement.setZIndexOffset(this.toTop)
      } else {
        this.myRef.current.leafletElement.closePopup()
        this.myRef.current.leafletElement.setZIndexOffset(this.toFloor)
      }

      this.setState(() => ({ openPopup: isOpen }))
    }
  }

  /**
   * Std after mount hook
   */
  componentDidMount() {
    const isOpen = this.props.openPopup

    if (isOpen) {
      this.myRef.current.leafletElement.openPopup()
      this.myRef.current.leafletElement.setZIndexOffset(this.toTop)
    } else {
      this.myRef.current.leafletElement.closePopup()
      this.myRef.current.leafletElement.setZIndexOffset(this.toFloor)
    }

    this.setState(() => ({ openPopup: isOpen }))
  }

  /**
 * @return {Object}
 */
  render() {
    // return <CircleMarker ref={this.myRef} center={this.props.position} {...this.props} />
    return <Marker ref={this.myRef} {...this.props} />
  }
}

ExtendedMarker.propTypes = {
  position: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  openPopup: PropTypes.bool,
}

ExtendedMarker.defaultProps = {
  openPopup: false,
}
