
export const getAccountStatus = (status: number): string => {
  switch (status) {
    case 0:
      return 'Аккаунт в порядке'

    case 1:
      return 'Неизвестная ошибка аккаунта'

    case 2:
      return 'Ошибка в учетных данных интеграции'

    case 3:
      return 'Неправльные настройки аккаунта для интеграции'

    default:
      return 'Ошибка аккаунта ' + status.toString()
  }
}

export const getServerStatus = (status: number): string => {
  switch (status) {
    case 0:
      return 'Сервер исправен'

    case 1:
      return 'Неизвестная ошибка сервера'

    case 2:
      return 'Неправильная версия телематического сервера'

    case 3:
      return 'Неправильный адрес сервера'

    case 4:
      return 'Неправильно указан порт телематического сервера'

    case 5:
      return 'Неправильно указан порт Информера телематического сервера'

    default:
      return 'Ошибка сервера ' + status.toString()
  }
}
