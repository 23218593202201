import L from 'leaflet'

export const sensorDivIcon = (
    size,
    fillColor = '#ffbf00',
    strokeColor = '#000'
) => new L.DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
    <g fill="${strokeColor}">
      <path d="m35.13 311.3h153.3v-231.5h145.3" fill="none" stroke="#000000"
        stroke-width="30"
      />
        <text x="31.932203" y="273.02029" fill="#000000"
          font-family="sans-serif" font-size="120px" font-weight="bold"
          letter-spacing="0px" stroke-width="1px" word-spacing="0px"
          style="line-height:125%" xml:space="preserve"
        >
          <tspan x="31.932203" y="273.02029">0</tspan>
        </text>
        <text x="258.65085" y="204.36609" fill="#000000"
          font-family="sans-serif" font-size="120px" font-weight="bold"
          letter-spacing="0px" stroke-width="1px" word-spacing="0px"
          style="line-height:125%" xml:space="preserve"
        >
          <tspan x="258.65085" y="204.36609">1</tspan>
        </text>
    </g>
  </svg>`,
  iconAnchor: [size / 2, size / 2],
  popupAnchor: [0, -size / 2],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [size, size],
  className: '',
})
