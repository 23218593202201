import { getMinutes, getSeconds, getMilliseconds } from 'date-fns'

export const getReadableDuration = (delta) => {
  const secondsInDay = 60 * 60 * 24 * 1000
  const secondsInHour = 3600000

  const days = Math.floor(delta / secondsInDay)
  const daysStr = days > 0 ? days + 'д' : ''

  const rawDelta = days > 0 ? delta - days * secondsInDay : delta
  const hours = Math.floor(rawDelta / secondsInHour)
  let hourStr = hours > 0 ? hours + 'ч' : ''
  if (days > 0) hourStr = ' ' + hourStr

  const min = getMinutes(delta)
  let minStr = min > 0 ? min + 'м' : ''
  if (hours > 0) minStr = ' ' + minStr

  const sec = getSeconds(delta)
  let secStr = sec > 0 ? sec + 'с' : ''
  if (min > 0) secStr = ' ' + secStr

  let msecStr = ''
  if (hours < 1 && min < 1 && sec < 1) msecStr = `${getMilliseconds(delta)}мс`

  return `${daysStr}${hourStr}${minStr}${secStr}${msecStr}`
}

export const getReadableDurationOld = (delta) => {
  const days = delta.days()
  const daysStr = days !== 0 ? days + ' д ' : ''

  const hour = delta.hours()
  const hourStr = hour !== 0 ? hour + ' ч ' : ''

  const min = delta.minutes()
  const minStr = min !== 0 ? min + ' м ' : ''

  const sec = delta.seconds()
  const secStr = sec !== 0 ? sec + ' с ' : ''

  const msec = delta.milliseconds()
  const msecStr = hour < 1 && min < 1 && sec < 1 ? msec + ' мс' : ''

  return `${daysStr}${hourStr}${minStr}${secStr}${msecStr}`
}
