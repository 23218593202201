const liEarthRadius = 111319.5
const weirdConst = 0.01745329251994329577
const d2r = Math.PI / 180
const r2 = 6378137.0 * 6378137.0
// const r2d = 180 / Math.PI

// TODO handle multypoligons later
export const coordsToArray = (latlngs) => {
  const points = []
  latlngs[0].forEach((latlng) => {
    points.push([latlng.lat, latlng.lng])
  })
  return points
}

const simpleDistance = (p1, p2) => {
  let x = (p1[1] - p2[1]) * liEarthRadius * Math.cos(p1[0] * weirdConst)
  x *= x
  let y = (p1[0] - p2[0]) * liEarthRadius
  y *= y
  return Math.sqrt(x + y)
}

export const polygonCircleRadius = (center, coords) => {
  let distance = 0
  let maxDistance = 0

  for (let i = 0; i < coords.length; i++) {
    distance = simpleDistance(center, coords[i])
    maxDistance = Math.max(distance, maxDistance)
  }
  return Math.round(maxDistance)
}

export const geodesicArea = (latLngs) => {
  const pointsCount = latLngs.length
  let area = 0
  let p1
  let p2

  if (pointsCount > 2) {
    for (let i = 0; i < pointsCount; i++) {
      p1 = latLngs[i]
      p2 = latLngs[(i + 1) % pointsCount]
      area += ((p2[1] - p1[1]) * d2r) * (2 + Math.sin(p1[0] * d2r) + Math.sin(p2[0] * d2r))
    }
    area = area * r2 / 2.0
  }
  return Math.abs(area)
}

