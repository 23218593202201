import L from 'leaflet'

// ${strokeColor}
export const carBatteryDivIcon = (
    size,
    fillColor = '#ffbf00',
    strokeColor = '#000'
) => new L.DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
    <g fill="${strokeColor}" transform="matrix(.6506 0 0 .6506 43.26 43.26)">
        <path d="m439.1 81.16h-68.51v-21.74c0-3.946-3.199-7.146-7.146-7.146h-\
49c-3.946 0-7.146 3.199-7.146 7.146v21.74h-168.4v-21.74c0-3.946-3.199-7.146-7.\
146-7.146h-49c-3.946 0-7.146 3.199-7.146 7.146v21.74h-68.51c-3.946 0-7.146 3.\
199-7.146 7.146v45.61c0 3.946 3.199 7.146 7.146 7.146h432c3.945 0 7.146-3.\
199 7.146-7.146v-45.61c-1e-3 -3.946-3.201-7.146-7.147-7.146z"/>
        <path d="m30.35 384.7c0 5.119 4.149 9.27 9.268 9.27h367c5.119 0 9.\
269-4.15 9.269-9.27v-219.3h-385.6zm148.9-94.71 21.72-69.4c0.373-1.192 1.478-2.\
004 2.727-2.004h33.86c0.914 0 1.771 0.438 2.311 1.177 0.538 0.738 0.689 1.\
691 0.406 2.561l-13.22 40.84h37.22c1.047 0 2.008 0.572 2.51 1.49 0.5 0.918 0.\
459 2.035-0.107 2.914l-49.72 77.15c-0.538 0.835-1.453 1.31-2.402 1.31-0.343 0-\
0.689-0.062-1.025-0.19-1.263-0.485-2.012-1.793-1.793-3.129l8.028-49h-37.78c-0.\
909 0-1.764-0.434-2.302-1.166-0.54-0.733-0.698-1.678-0.426-2.545z"/>
    </g>
  </svg>`,
  iconAnchor: [size / 2, size / 2],
  popupAnchor: [0, -size / 2],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [size, size],
  className: '',
})
