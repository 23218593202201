import React from 'react'
import PropTypes from 'prop-types'

import { UpOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'

const minifyStyle = {
  position: 'absolute',
  zIndex: '9999',
  backgroundColor: 'orange',
  cursor: 'pointer',
}

const minifyVerticalStyle = {
  ...minifyStyle,
  top: '50%',
  transform: 'translateY(-50%)',
  width: '24px',
  height: '48px',
}

const minifyHorizontalStyle = {
  ...minifyStyle,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '48px',
  height: '24px',
}

const minifyTopStyle = {
  ...minifyHorizontalStyle,
  top: '-18px',
  borderRadius: '5px 5px 0 0',
}

const minifyBottomStyle = {
  ...minifyHorizontalStyle,
  bottom: '-18px',
  borderRadius: '0 0 5px 5px',
}

const minifyRightStyle = {
  ...minifyVerticalStyle,
  right: '-18px',
  borderRadius: '0 5px 5px 0',
}

const minifyLeftStyle = {
  ...minifyVerticalStyle,
  left: '-18px',
  borderRadius: '5px 0 0 5px',
}

const chevronVerticalStyle = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
}

const chevronHorizontalStyle = {
  // position: 'relative',
  // left: '50%',
  // transform: 'translateX(-50%)',
  textAlign: 'center',
}

const sizeStyle = {
  fontSize: '2em',
}

export const MinifyPannelButton = ({ position, open, onClick }) => {
  let style = {}
  let chevronStyle = {}
  const options = []

  switch (position) {
    case 'top':
      style = minifyTopStyle
      chevronStyle = chevronHorizontalStyle
      options.push(
          <DownOutlined style={sizeStyle} />,
          <UpOutlined style={sizeStyle} />,
      )
      break

    case 'bottom':
      style = minifyBottomStyle
      chevronStyle = chevronHorizontalStyle
      options.push(
          <UpOutlined style={sizeStyle} />,
          <DownOutlined style={sizeStyle} />,
      )
      break

    case 'right':
      style = minifyRightStyle
      chevronStyle = chevronVerticalStyle
      options.push(
          <RightOutlined style={sizeStyle} />,
          <LeftOutlined style={sizeStyle} />,
      )
      break

    case 'left':
      style = minifyLeftStyle
      chevronStyle = chevronVerticalStyle
      options.push(
          <LeftOutlined style={sizeStyle} />,
          <RightOutlined style={sizeStyle} />,

      )
      break

    default:
      style = minifyRightStyle
      chevronStyle = chevronVerticalStyle
      options.push(
          <RightOutlined style={sizeStyle} />,
          <LeftOutlined style={sizeStyle} />,
      )
  }

  return (
    <div style={style} onClick={onClick}>
      <div style={chevronStyle}>
        {open ? options[0] : options[1]}
      </div>
    </div>
  )
}

MinifyPannelButton.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  open: PropTypes.bool,
  onClick: PropTypes.func,
}

MinifyPannelButton.defaultProps = {
  open: true,
}
