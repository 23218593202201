import React from 'react'
import PropTypes from 'prop-types'

import { Popup } from 'react-leaflet'

import { ExtendedMarker, WarningHelperCard } from '../components'
import {
  reloadDivIcon,
  lowBatteryDivIcon,
  signalLostDivIcon,
  sensorDivIcon,
  fuelUpDivIcon,
  fuelDownDivIcon,
  carBatteryDivIcon,
  speedometerDivIcon,
  warningDivIcon,
} from '../icons'

import icon from '../icons/drop-up2.svg'

const getWarningTypeDivIcon = (warningType, size = 24) => {
  switch (warningType) {
    // 00 - Переподключение
    case 0:
      return reloadDivIcon(size)

    // 01 - Потеря питания
    case 1:
      return lowBatteryDivIcon(size)

    // 02 - Потеря навигации
    case 2:
      return signalLostDivIcon(size)

    // 03 - Датчик 1, 04 - Датчик 2
    case 3:
    case 4:
      return sensorDivIcon(size)

    // 08 - Заправка
    case 8:
      return fuelUpDivIcon(size)

    // 09 - Слив
    case 9:
      return fuelDownDivIcon(size)

    // 15 - Работа от аккумулятора
    case 15:
      return carBatteryDivIcon(size)

    // 16 - Превышение скорости
    case 16:
      return speedometerDivIcon(size)

    // 10 - Прочие предупреждения (default)
    // 11 - Ошибка потока данных
    // 12 - Ошибка устройства
    // 13 - Предупреждение устройства
    // 14 - Отсутствие данных от устройства
    default:
      return warningDivIcon(size)
  }
}

export const WarningMarker = (props) => {
  return (
    <ExtendedMarker
      // opacity={props.opacity}
      // zIndexOffset={props.opacity === 0 ? 0 : undefined}
      position={[props.warning.coords.lat, props.warning.coords.lon]}
      icon={getWarningTypeDivIcon(props.warning.warningType)}
      openPopup={props.openPopup}
      onClick={props.onClick}
    >
      <Popup>
        <WarningHelperCard warning={props.warning} />
      </Popup>
    </ExtendedMarker>
  )
}

WarningMarker.propTypes = {
  openPopup: PropTypes.bool,
  warning: PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    warningType: PropTypes.number.isRequired,
    warningMessage: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      dir: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }),
  }),
  onClick: PropTypes.func,
}

WarningMarker.defaultProps = {
  openPopup: false,
}

export const WarningMarker2 = (props) => {
  return (
    <ExtendedMarker
      opacity={props.opacity}
      position={[props.warning.coords.lat, props.warning.coords.lon]}
      icon={icon}
      openPopup={props.openPopup}
    >
      <Popup>
        <WarningHelperCard warning={props.warning} />
      </Popup>
    </ExtendedMarker>
  )
}

WarningMarker2.propTypes = {
  opacity: PropTypes.number,
  openPopup: PropTypes.bool,
  warning: PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    warningType: PropTypes.number.isRequired,
    warningMessage: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      dir: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }),
  }),
}

WarningMarker2.defaultProps = {
  openPopup: false,
}
