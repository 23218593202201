import L from 'leaflet'

// ${strokeColor}
export const signalLostDivIcon = (
  size,
  fillColor = '#ffbf00',
  strokeColor = '#000'
) => new L.DivIcon({
    html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg
    width="${size}"
    height="${size}"
    version="1.1"
    viewBox="0 0 376.8 376.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="376.8" height="376.8" ry="38.45" fill="${fillColor}"/>
    <g fill="${strokeColor}" stroke-width=".6135">
      <path d="m145.1 176.7c-14.1 0-25.53 11.42-25.53 25.53v74.45c0 14.09 11.\
43 25.52 25.53 25.52h3.801c14.1 0 25.53-11.43 25.53-25.52v-74.45c0-14.1-11.43-\
25.53-25.53-25.53z"/><path d="m228.8 302.2h3.801c14.1 0 25.53-11.43 25.53-25.\
52v-117c0-14.09-11.43-25.53-25.53-25.53h-3.801c-14.1 0-25.53 11.43-25.53 25.\
53v117c6e-3 14.09 11.43 25.52 25.53 25.52z"/><path d="m316.3 74.62h-3.807c-14.\
09 0-25.52 11.43-25.52 25.53v176.5c0 14.09 11.43 25.52 25.52 25.52h3.807c14.\
1 0 25.53-11.43 25.53-25.52v-176.6c0-14.09-11.43-25.52-25.53-25.52z"/>
      <path d="m95.82 276.7v-43.96c0-14.09-11.43-25.52-25.53-25.52h-3.802c-14.\
1 0-25.53 11.43-25.53 25.52v43.96c0 14.09 11.43 25.52 25.53 25.52h3.802c14.\
09 0 25.53-11.43 25.53-25.52z"/><path d="m48.83 178.6 19.01-19.01 19.01 19.\
01c3.15 3.15 8.254 3.15 11.4 0 3.156-3.15 3.15-8.255 0-11.4l-19.01-19.01 19.\
01-19.01c3.15-3.15 3.15-8.254 0-11.4-3.15-3.15-8.255-3.15-11.4 0l-19.01 19.01-\
19.01-19.01c-3.15-3.15-8.255-3.15-11.4 0-3.15 3.15-3.15 8.249 0 11.4l19.01 19.\
01-19.01 19.01c-3.15 3.15-3.15 8.254 0 11.4 3.145 3.15 8.255 3.15 11.4 6.1e-4z"
fill="#e10000"/>
    </g>
  </svg>`,
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    className: '',
  })
