import React from 'react'
import { PropTypes } from 'prop-types'

import { DomEvent } from 'leaflet'
import { MapComponent } from 'react-leaflet'

import { UpOutlined, DownOutlined } from '@ant-design/icons'

import './MapSidePanel.css'

/**
 * Universal collapsible leaflet map panel
 */
export class MapSidePanel extends MapComponent {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props)

    this.state = {
      collapsed: false,
      width: 0,
      height: 0,
    }

    this.toggle = this.toggle.bind(this)
    // this.setSize = this.setSize.bind(this)
  }

  /**
   * Toggle list visibility
   */
  toggle() {
    !this.props.collapsed && this.setState((ps) => ({ ...ps, collapsed: !ps.collapsed }))
    this.props.onToggle && this.props.onToggle(this.state.collapsed)
  }

  // TODO FIXME fix crash on small sizes
  /**
   * Settups pane size
   */
  // setSize() {
  //   this.setState((ps) => ({
  //     ...ps,
  //     width: this.wrapper.clientWidth,
  //     height: this.wrapper.clientHeight,
  //   }))

  //   if (!this.props.onSetSize || typeof this.props.onSetSize !== 'function') return
  //   this.props.onSetSize(this.wrapper.clientWidth, this.wrapper.clientHeight)
  // }

  /**
   * Std on mount hook
   */
  componentDidMount() {
    // window.addEventListener('resize', this.setSize)

    DomEvent
        .disableClickPropagation(this.rootElement)
        .disableScrollPropagation(this.rootElement)

    // this.setSize()
  }

  /**
   * Std hook
   */
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.setSize)
  // }

  /**
   * @return {Object}
   */
  render() {
    const style = Object.assign(
        {},
        { width: this.props.width },
        this.state.collapsed ? {} : { height: '50vh' },
    )

    return (
      <div
        className={'side-panel leaflet-touch'}
        ref={(el) => {this.rootElement = el}}
        style={style}
      >
        <div className='resizer' onClick={this.toggle}>
          {this.state.collapsed ?
            <UpOutlined style={{ fontSize: '24px' }} /> :
            <DownOutlined style={{ fontSize: '24px' }} />
          }
        </div>

        <div className='caption'>
          <span>{this.props.caption}</span>
        </div>

        {!this.state.collapsed && <div
          className='ss-scroll-wrapper'
          // ref={(el) => {this.wrapper = el}}
        >
          {this.props.children}
        </div>}
      </div>
    )
  }
}

MapSidePanel.propTypes = {
  onToggle: PropTypes.func,
  collapsed: PropTypes.bool,
  caption: PropTypes.string,
  // title: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.any,
  // onSetSize: PropTypes.func,
}

MapSidePanel.defaultProps = {
  caption: '',
  // title: '',
}

