import React from 'react'
import PropTypes from 'prop-types'
import { DivIcon } from 'leaflet'
import { Popup } from 'react-leaflet'

import { ExtendedMarker } from '../components'
import { getReadableTime } from '../common'

const coordsType = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
})

const cardStyle = { padding: '10px', minWidth: '250px' }

const ShiftMarker = (props) => {
  let icon = null
  let caption = ''
  switch (props.type) {
    case 'start':
      icon = startIcon
      caption = 'Начало смены'
      break

    case 'finish':
      icon = finishIcon
      caption = 'Конец смены'
      break

    default:
  }

  return (
    <ExtendedMarker position={[props.coords.lat, props.coords.lon]} icon={icon} >
      <Popup>
        <div style={cardStyle}>
          <h3>{caption}</h3>
          <div>{props.time}</div>
        </div>
      </Popup>
    </ExtendedMarker>
  )
}

ShiftMarker.propTypes = {
  time: PropTypes.string,
  type: PropTypes.oneOf(['start', 'finish']).isRequired,
  coords: coordsType.isRequired,
}

export const ShiftMarkers = ({ shift, start, end }) => {
  const timeStart = getReadableTime(shift.timeBegin)
  const timeEnd = getReadableTime(shift.timeEnd)

  return <React.Fragment>
    <ShiftMarker type={'start'} coords={start} time={timeStart} />
    <ShiftMarker type={'finish'} coords={end} time={timeEnd} />
  </React.Fragment>
}

ShiftMarkers.propTypes = {
  start: coordsType.isRequired,
  end: coordsType.isRequired,
  shift: PropTypes.object.isRequired,
}

const finishIcon = new DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"\
  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#FFFFFF;}
</style>
<g>
 <rect x="100" y="40" class="st0" width="320" height="240"/>
 <rect x="76" y="16" class="st0" width="8" height="464"/>
 <path d="M428,24H100V8c0-4.4-3.6-8-8-8H68c-4.4,0-8,3.6-8,8v480c0,4.4,3.6,8,8,8h24c4.4,\
0,8-3.6,8-8V296h328c4.4,0,8-3.6,8-8V32
   C436,27.6,432.4,24,428,24z M84,480h-8V16h8V480z M420,120h-80v80h80v80h-80v-80h-80v80h-\
80v-80h-80v-80h80V40h80v80h80V40h80V120z
   "/>
 <rect x="180" y="120" width="80" height="80"/>
</g>
</svg>`,
  iconAnchor: [0, 24],
  popupAnchor: [12, -24],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [24, 24],
  className: '',
})

const startIcon = new DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"\
  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<style type="text/css">
  .st0{fill:#FFFFFF;}
</style>
<g>
  <path d="M428,24H100V8c0-4.4-3.6-8-8-8H68c-4.4,0-8,3.6-8,8v480c0,4.4,3.6,8,8,\
8h24c4.4,0,8-3.6,8-8V296h328c4.4,0,8-3.6,8-8V32
    C436,27.6,432.4,24,428,24z M84,480h-8V16h8V480z M420,40v240"/>
  <rect x="100" y="40" class="st0" width="320" height="240"/>
</g>
</svg>`,
  iconAnchor: [0, 24],
  popupAnchor: [12, -24],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [24, 24],
  className: '',
})
