import React from 'react'
import { observer } from 'mobx-react'


import { CollapsablePanel } from '../CollapsablePanel'
import { TrackFilterForm } from '../TrackFilterForm'
import Control from './Control'
import { useStore } from '../../stores/rootStore'


interface Props {

}


const TrackFilters: React.FC<Props> = (props) => {
  const store = useStore()

  const title = 'Фильтрация событий'

  // className={'leaflet-bar leaflet-touch'}
  return <Control position={'topright'}>
    <CollapsablePanel
      title={title}
      caption={title}
      collapsed={store.ui.mapState.navigation.filterForm}
      onToggle={store.ui.toggleFilterForm}
    >
      <TrackFilterForm
        filter={store.track.trackFilters}
        navigation={store.ui.mapState.navigation.visible}
        onChange={store.track.setFilters}
        onToggleObjects={store.ui.toggleObjects}
      />
    </CollapsablePanel>
  </Control>
}

export const FilterPanel = observer(TrackFilters)

