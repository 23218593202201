import { makeAutoObservable } from "mobx"
import { parseTitle } from "../common"
import { DeviceDataCell } from "./DeviceDataCell"
import { IDevice, IDeviceDataCell, IDeviceParams, IDeviceSystem } from "./interfaces"


export class SystemDevice implements IDevice {
  readonly deviceID: string
  readonly accountID: string
  readonly imei: string
  model: string
  number: string
  type: string
  blackDate: string
  permissions: number
  isDisabled: boolean
  isLicensed: boolean
  isUnlimited: boolean
  licenseBlackBate: string
  created: string
  serverStatus: number
  accountStatus: number
  data: IDeviceDataCell
  params: IDeviceParams

  constructor(device: IDeviceSystem) {
    const title = parseTitle(device)

    this.deviceID = device.deviceID
    this.accountID = device.accountID
    this.imei = device.imei
    this.model = device.model
    this.number = device.number
    this.type = device.type
    this.blackDate = device.blackDate
    this.permissions = device.permissions
    this.isDisabled = device.isDisabled
    this.isLicensed = device.isLicensed
    this.isUnlimited = device.isUnlimited
    this.licenseBlackBate = device.licenseBlackBate
    this.created = device.created
    this.serverStatus = device.serverStatus
    this.accountStatus = device.accountStatus

    this.data = new DeviceDataCell(device.deviceID)
    this.params = {
      ref: null,
      visible: true,
      filtered: false,
      title: title.short,
      fullTitle: title.full,
    }

    makeAutoObservable(this, {}, { autoBind: true })
  }

  get id(): string {
    return this.deviceID
  }
}