import React from 'react'
import PropTypes from 'prop-types'

/**
 * HOC error handler
 */
export class ErrorHandler extends React.Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props)

    this.state = {
      error: false,
    }
  }

  /**
   * @param {object} err
   * @param {object} info
   */
  componentDidCatch(err, info) {
    this.setState({ error: true, msg: err, stack: info })
  }

  /**
   * @return {object}
   */
  render() {
    if (this.state.error) {
      return <div style={{ padding: '20px' }}>
        <p>
        I used to be an adventurer like you, then I took an error in the knee
        </p>
        <a href='/'>Start new adventure</a>
      </div>
    }
    return this.props.children
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.any.isRequired,
}
