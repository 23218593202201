import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect, Switch, Route } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { MapLayoutContainer } from '../../components'
// import AdminPanel from '../AdminPanel'

import 'leaflet/dist/leaflet.css'
import './mapLayout.css'

const MapLayout = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    props.rootStore.profile.getSettings()
      .catch((err) => { console.info('Settings fetch error:', err) })
      .finally(() => {
        setLoading(false)
        // props.rootStore.map.startLoadingMessage()
      })
  }, [props])

  if (loading) {
    return <div>Loading...</div>
  }

  if (props.rootStore.profile.user == null) {
    return <Redirect to='/maps/login' />
  }

  return (
    <Switch>
      <Route exact path={`${props.match.url}/:id`} component={MapLayoutContainer} />
    </Switch>
  )
}

MapLayout.propTypes = {
  rootStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default inject('rootStore')(withRouter(observer(MapLayout)))
