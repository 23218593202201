import React from 'react'
import { observer } from 'mobx-react'
import { isMobile } from 'react-device-detect'


import { StopMarker } from '../../components'
import { useStore } from '../../stores/rootStore'
import { IStop } from '../../stores/interfaces'


interface Props { }


const Stop: React.FC<Props> = (props) => {
  const store = useStore()

  const [stop, setStop] = React.useState<IStop | null>(null)

  const xShift = isMobile ? -store.ui.window.size.width + (store.ui.window.size.width / 4) : 100
  const yShift = store.ui.window.size.height / 2

  React.useEffect(() => {
    if (store.track.selectedStop != null && store.track.shiftHelpers.stops.length >= store.track.selectedStop + 1) {
      const stop = store.track.shiftHelpers.stops[store.track.selectedStop]
      setStop(stop)
      const bounds = [stop.coords.lat, stop.coords.lon, stop.coords.lat, stop.coords.lon]
      store.map.fitBounds(bounds, { x: xShift, y: yShift })
    } else if (store.track.selectedStop == null) {
      setStop(null)
    }
  }, [store.map, store.track.selectedStop, store.track.shiftHelpers.stops, xShift, yShift])

  return <>
    {stop != null && <StopMarker
      stop={stop}
      openPopup={true}
      onClick={() => { store.track.setStop(null) }}
    />}
  </>
}

export default observer(Stop)
