import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import Table from 'antd/es/table'


import { getReadableDuration } from '../common'


export const StopsHelperTable = ({ stops, onClick }) => {
  const columns = [{
    title: 'Начало',
    dataIndex: 'startTime',
    key: 'startTime',
  }, {
    title: 'Конец',
    dataIndex: 'endTime',
    key: 'endTime',
  }, {
    title: 'Продолжительность',
    dataIndex: 'duration',
    key: 'duration',
  }]

  const dataSource = []
  const coords = []

  stops.forEach((stop, key) => {
    const start = Moment(stop.timeBegin)
    const end = Moment(stop.timeEnd)
    const delta = Moment.duration(end.diff(start))
    const duration = getReadableDuration(delta)

    dataSource.push({
      key: key,
      startTime: start.format('Do MMMM YYYY, HH:mm:ss'),
      endTime: end.format('Do MMMM YYYY, HH:mm:ss'),
      duration: duration,
    })

    coords.push({
      lat: stop.coords.lat,
      lon: stop.coords.lon,
    })

    stop.properties.Properties.forEach((prop, ikey) => {
      if (key === 0) {
        columns.push({
          title: prop.Name,
          dataIndex: `propName${ikey}`,
          key: `propName${ikey}`,
        })
      }

      dataSource[key][`propName${ikey}`] = prop.SValue
    })
  })

  // return (
  //   <div>
  //     <h3 onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}>
  //       Остановка
  //     </h3>

  //     <div>
  //       {'Начало: ' + start.format('Do MMMM YYYY, HH:mm:ss')}
  //     </div>

  //     <div>
  //       {'Конец: ' + end.format('Do MMMM YYYY, HH:mm:ss')}
  //     </div>

  //     <div>
  //       {'Продолжительность: ' + getReadableDuration(delta)}
  //     </div>

  //     {stop.properties.Properties.map((prop, key) => {
  //       return (
  //         <div key={key}>{prop.Name + ' ' + prop.SValue}</div>
  //       )
  //     })}
  //   </div>
  // )

  return <Table
    dataSource={dataSource}
    columns={columns}
    scroll={{ y: 200 }}
    size='small'
    pagination={false}
    onRow={(record) => {
      return {
        onClick: () => {
          onClick(record.key, coords[record.key])
        },
        onMouseEnter: () => { },
      }
    }}
  />
}

StopsHelperTable.propTypes = {
  onClick: PropTypes.func,
  stops: PropTypes.arrayOf(PropTypes.shape({
    timeBegin: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      Properties: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string.isRequired,
        SValue: PropTypes.shape.isRequired,
      })).isRequired,
    }).isRequired,
  })),
}
