import React from 'react'
import { createPortal } from 'react-dom'


interface PortalProps {
  // style: React.CSSProperties
  className?: string
}


const appRoot = document.getElementById('root');

export class Portal extends React.Component<PortalProps, {}> {
  private el: HTMLDivElement

  // public static defaultProps: {
  //   style: {
  //     position: 'absolute',
  //     top: '0',
  //     right: '0',
  //   }
  // }

  constructor(props: PortalProps) {
    super(props);
    this.el = document.createElement('div');
    if (props.className != null) {
      this.el.classList.add(props.className)
    }
  }

  componentDidMount() {
    // @ts-ignore: Object is possibly 'null'.
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    // @ts-ignore: Object is possibly 'null'.
    appRoot.removeChild(this.el);
  }

  render() {
    return createPortal(
      this.props.children,
      this.el
    );
  }
}