import React from 'react'
import { Redirect, useLocation, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Button from 'antd/es/button'


import { useStore } from '../../stores/rootStore'


import styles from './LoginPage.module.css'


const idURL = process.env.REACT_APP_ID_URL
const mapsURL = process.env.REACT_APP_MAPS_URL

const LoginPage = (props) => {
  const store = useStore()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const target = params.get('redirect') || `${mapsURL}/0`
  const loginPath = `${idURL}/login?redirect=${encodeURIComponent(target)}`
  // TODO const demoLoginPath = loginPath + '&'

  // const fetchCurrentUser = useCallback(() => {
  //   setLoading(true)
  //   setError(false)
  //   api.currentUser()
  //     // .then((user) => {
  //     //   props.rootStore.profile.setInvoker(user)
  //     //   return user
  //     // })
  //     .then(props.rootStore.profile.getSettings)
  //     .catch((err) => {
  //       setLoading(false)
  //       setError(getErrorMessage(err, 'Произошла ошибка при загрузке данных пользователя'))
  //     })
  // }, [props.rootStore.profile])

  // useEffect(() => {
  //   if (props.rootStore.profile.invoker != null) {
  //     return
  //   }

  //   fetchCurrentUser()

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  if (store.profile.invoker != null) {
    return <Redirect to={'/maps/0'} />
  }

  return <div className={styles.loginContainer}>
    <div className={styles.loginForm}>
      <div>
        <h2>Locarus Maps</h2> {/* Beta sign <i>{'\u03B2'}</i> */}
      </div>

      {/* <p>
          {props.strings.disclamer}
        </p> */}

      {/* {error != null && (
          <div className={styles.errorBlock}>
            <p>{error}</p>
            <Button size='small' htmlType='button' onClick={fetchCurrentUser}>
              Попробовать снова
          </Button>
          </div>
        )} */}

      <a href={loginPath} rel='noopener noreferrer' className={styles.formButton}>
        <Button type='primary' htmlType='button'>
          {/* {props.strings.submitButton} */}
            Войти через Locarus ID
          </Button>
      </a>

      {/* TODO <a href={demoLoginPath} rel='noopener noreferrer' className={styles.demoButton}>
        <Button size='small' htmlType='button'>
            Демо вход
          </Button>
      </a> */}
    </div>
  </div>
}

export default withRouter(observer(LoginPage))

// const LoginPage = (props) => {
//   const [loading, setLoading] = useState(false)
//   const [error, setError] = useState(null)
//   const [authorized, setAuthorized] = useState(false)

//   const [form] = Form.useForm()

//   // const validateEmail = (rule, value) => {
//   //   if (!value || !v.isEmail(value)) {
//   //     return Promise.reject(props.strings.invalidEmail)
//   //   }
//   //   return Promise.resolve()
//   // }

//   const handleSubmit = (values) => {
//     setLoading(true)
//     api.login({ login: values.email, password: values.password })
//         .then(() => {
//           setLoading(false)
//           setAuthorized(true)
//         })
//         .catch((err) => {
//           setError(err)
//           setLoading(false)
//         })
//   }

//   const demoLogin = () => {
//     api.login({ login: 'demo', password: 'demo' })
//         .then(() => {
//           setLoading(false)
//           setAuthorized(true)
//         })
//         .catch((err) => {
//           setError(err)
//           setLoading(false)
//         })
//   }

//   if (authorized) {
//     return <Redirect to='/' />
//   }


//   if (!loading && !error && props.rootStore.profile.user != null) {
//     return <Redirect to='/' />
//   }

//   const err = getReadableError(error)

//   return (
//     <div className={styles.loginContainer}>
//       <div className={styles.loginForm}>
//         <Spin spinning={loading}>
//           <div>
//             <h2>Locarus Maps</h2> {/* Beta sign <i>{'\u03B2'}</i> */}
//           </div>

//           <p>
//             {props.strings.disclamer}
//           </p>

//           {error && (
//             <div className={styles.errorBlock}>
//               <h3>{err.title}</h3>
//               <p>{err.text}</p>
//             </div>
//           )}

//           <Form
//             name="loginForm"
//             form={form}
//             initialValues={{ email: props.rootStore.profile.email }}
//             onFinish={handleSubmit}
//             validateMessages={{
//               required: props.strings.requiredField,
//               types: {
//                 email: props.strings.invalidEmail,
//               },
//             }}
//           >
//             <Form.Item
//               name='email'
//               rules={[{
//                 required: true,
//                 type: 'email',
//                 // message: props.strings.requiredField,
//               }, {
//                 // validator: validateEmail,
//               }]}
//             >
//               <Input
//                 prefix={<MailFilled className={styles.formInput} />}
//                 placeholder={props.strings.emailPlaceholder}
//                 autoComplete='username'
//               />
//             </Form.Item>

//             <Form.Item
//               name='password'
//               rules={[{ required: true, message: props.strings.requiredField }]}
//             >
//               <Input.Password
//                 prefix={<LockFilled className={styles.formInput} />}
//                 placeholder={props.strings.passwordPlaceholder}
//                 autoComplete='current-password'
//               />
//             </Form.Item>

//             <Form.Item>
//               <Button type="primary" htmlType="submit" className={styles.formButton}>
//                 {props.strings.submitButton}
//               </Button>
//             </Form.Item>

//             <Form.Item>
//               <a className={styles.idLink} target='_blank' rel='noopener noreferrer' href={props.general.idLink}>
//                 {props.strings.toID}
//               </a>
//             </Form.Item>

//             <Form.Item>
//               <Button size="small" htmlType="button" onClick={demoLogin}>
//                 {props.strings.demoLoginButton}
//               </Button>
//             </Form.Item>

//           </Form>
//         </Spin>
//       </div>
//     </div>
//   )
// }

// LoginPage.displayName = 'LoginPage'

// LoginPage.propTypes = {
//   rootStore: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired,
//   strings: PropTypes.shape({
//     disclamer: PropTypes.string,
//     emailPlaceholder: PropTypes.string,
//     passwordPlaceholder: PropTypes.string,
//     requiredField: PropTypes.string,
//     invalidEmail: PropTypes.string,
//     submitButton: PropTypes.string,
//     demoLoginButton: PropTypes.string,
//     remember: PropTypes.string,
//     toID: PropTypes.string,
//   }),
//   general: PropTypes.shape({
//     idLink: PropTypes.string,
//   }),
// }

// export default inject('rootStore')(observer(LoginPage))

// const getReadableError = (error) => {
//   if (!error) return

//   let title = ''
//   let text = ''

//   switch (error.name) {
//     case 'NetworkError':
//       title = 'Ошибка авторизации'
//       switch (error.response.status) {
//         case 401:
//           text = 'Неправильный логин или пароль'
//           break

//         case 406:
//           text = 'Введенные данные не верны или отсутствуют'
//           break

//         case 500:
//           text = 'Внутренняя ошибка сервера'
//           break

//         case 502:
//           text = 'Сервер не отвечает, попробуйте позднее'
//           break

//         default:
//           text = ''
//       }
//       break

//     default:
//       if (prod) console.info(error)
//       title = 'Ошибка'
//       text = 'Что-то пошло не так'
//   }

//   return {
//     title: title,
//     text: text,
//   }
// }
