import React from 'react'
import { observer } from 'mobx-react'


import { TrackHelpersPanel } from '../TrackHelpersPanel'
import Track from './Track'
import HelperLayer from './HelperLayer'
import Arrows from './Arrows'


/**
   * Корректировка округления десятичных дробей.
   *
   * @param {String}  type  Тип корректировки.
   * @param {Number}  value Число.
   * @param {Integer} exp   Показатель степени
   * @return {Number} Скорректированное значение.
   */
// function decimalAdjust(type, value, exp) {
//   // Если степень не определена, либо равна нулю...
//   if (typeof exp === 'undefined' || +exp === 0) {
//     return Math[type](value)
//   }
//   value = +value
//   exp = +exp
//   if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
//     return NaN
//   }
//   // Сдвиг разрядов
//   value = value.toString().split('e')
// value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
//   // Обратный сдвиг
//   value = value.toString().split('e')
//   return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
// }

// const roundDec = (cal, exp) => decimalAdjust('round', cal, exp)

// const f = (pos) => (point) => {
//   const p0 = Math.abs(point[0] - pos.lat)
//   const p1 = Math.abs(point[1] - pos.lng)
//   return p0 <= 0.01 && p1 <= 0.01
// }

interface TrackLayerProps { }


const TrackLayer: React.FC<TrackLayerProps> = (props) => {
  // const store = useStore()

  // const map = store.map
  // if (!store.track.done || store.track.data.length === 0) {
  //   return null
  // }

  // if (store.track.data.length === 0) {
  //   return null
  // }

  // const hasCoords = store.track.data.length > 0
  // if (hasCoords) {
  //   const bounds = store.map.trackBounds
  //   const currentBounds = [bounds.NorthEast[0], bounds.NorthEast[1], bounds.SouthWest[0], bounds.SouthWest[1]]
  //   // TODO offset using collapsed variables
  //   store.map.fitBounds(currentBounds, { x: 300, y: 400 })
  // }

  // if (store.track.data.length === 0) {
  //   return null
  // }

  // const selectedType = store.map.track.selected.type
  // const selectedIndex = store.map.track.selected.index

  // const shiftIndex = store.map.track.shiftSelected
  // const isShiftSelected = shiftIndex != null
  // const currentShift = isShiftSelected ? map.track.helpers.shifts[shiftIndex] : null
  // const start = hasCoords ? line[0] : null
  // const end = hasCoords ? line[line.length - 1] : null

  // const stops = store.map.trackHelpers.stops
  // const warnings = store.map.trackHelpers.warnings

  // const hasStops = stops && stops.length > 0
  // const hasWarns = warnings && warnings.length > 0

  // const allowStops = store.track.isStopsAllowed
  // const allowCP = map.isCPAllowed()

  // const hasTrack = store.track.done && store.track.length > 0

  return <>
    {/* <FilterPanel /> */}
    <TrackHelpersPanel />
    <HelperLayer />
    <Track />
    <Arrows />

    {/* <Polyline
      style={{ zIndex: '1900' }}
      color={'blue'}
      weight={4}
      lineCap={'round'}
      lineJoin={'round'}
      positions={store.track.trackLineLeaflet}
      smoothFactor={0.4}
    // onMouseOver={(event) => {
    //   // const index = line.findIndex(findIndex(event.latlng))
    //   const index = line.findIndex(f(event.latlng))

    //   line.forEach(f(event.latlng))
    //   // const index = line.findIndex(asdf(event.latlng))
    //   if (index !== -1 && map.track.data[index].properties) {
    //     map.setMarker({
    //       position: map.track.data[index].geometry.coordinates,
    //       speed: map.track.data[index].properties.speed,
    //       time: map.track.data[index].properties.time,
    //     })
    //   }
    // }}
    // onMouseOut={() => {map.setMarker({ position: [], speed: 0 })}}
    />

    {store.track.trackArrows.map((arw, key) => {
      return <RotatedMarker
        key={`arw-${key}`}
        position={[arw.lat, arw.lon]}
        icon={arrowDivIcon(16, 'blue', 1, 'blue', 6, false)}
        rotationAngle={arw.dir}
      />
    })} */}

    {/* {store.track.currentShift != null && <ShiftMarkers
      start={store.track.trackLine[0]}
      end={store.track.trackLine[store.track.trackLine.length - 1]}
      shift={store.track.currentShift}
    />} */}

    {/* Stops */}
    {/* {store.track.isStopsAllowed && store.track.trackHelpers.stops.length > 0
      && store.track.trackHelpers.stops.map((stop, key) => {
        // const opened = selectedType === 0 && selectedIndex === key
        // // if (!opened) return null

        // const func = opened ?
        //   store.map.unsetSelectedHelper :
        //   () => {
        //     store.map.setSelectedHelper(0, key)
        //     // store.map.centerWithBounds(stop.coords, { x: 300, y: -400 })
        //   }
        // store.map.setSelectedHelper

        // if (opened) {
        //   store.map.centerWithBounds(stop.coords, { x: 300, y: -400 })
        // }

        return <OStopMarker key={key} index={key} />

        // return <StopMarker
        //   key={key}
        //   stop={stop}
        //   openPopup={store.track.selectedStop === key}
        //   onClick={() => store.track.setStop(key)}
        // />
      })} */}

    {/* Warnings */}
    {/* {store.track.trackHelpers.warnings.length > 0
      && store.track.trackHelpers.warnings.map((warning, key) => {
        // const opened = selectedType === 1 && selectedIndex === key
        if (!store.track.isWarningAllowed(warning.warningType)) {
          return null
        }

        // const func = opened ?
        //   store.map.unsetSelectedHelper :
        //   store.map.setSelectedHelper

        return <WarningMarker
          key={key}
          openPopup={store.track.selectedWarning === key}
          warning={warning}
          onClick={() => store.track.setWarning(key)}
        />
      })} */}

    {/* TODO Control points */}
    {/* allowCP && null */}
  </>
}

// TrackLayer.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'TrackLayer!'
// }
export default observer(TrackLayer)

