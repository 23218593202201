import { LatLngBounds, Point } from 'leaflet'


import { Projector, LatLngDir } from './downsampling'


export const getArrows = (projector: Projector, zoom: number, track: LatLngDir[], dist: number = 50): LatLngDir[] => {
  if (track.length === 0) {
    return []
  }

  const arrows: LatLngDir[] = [{
    lat: track[0].lat,
    lon: track[0].lon,
    dir: track[0].dir,
  }]

  if (track.length === 1) {
    return arrows
  }

  let p1 = projector.project([track[0].lat, track[0].lon], zoom)
  let p2: Point | null = null
  const ddist = dist * dist
  const last = track.length - 1
  for (let i = 1; i < last - 1; i++) {
    p2 = projector.project([track[i].lat, track[i].lon], zoom)
    if (squaredDistance(p1, p2) < ddist) {
      continue
    }

    arrows.push({
      lat: track[i].lat,
      lon: track[i].lon,
      dir: track[i].dir,
    })

    p1 = p2
  }

  arrows.push({
    lat: track[last].lat,
    lon: track[last].lon,
    dir: track[last].dir,
  })
  return arrows
}

export const getArrowsBounded = (projector: Projector, zoom: number, bounds: LatLngBounds, track: LatLngDir[], dist: number = 50): LatLngDir[] => {
  if (track.length === 0) {
    return []
  }

  const arrows: LatLngDir[] = [{
    lat: track[0].lat,
    lon: track[0].lon,
    dir: track[0].dir,
  }]

  if (track.length === 1) {
    return arrows
  }

  let p1 = projector.project([track[0].lat, track[0].lon], zoom)
  let p2: Point | null = null
  const ddist = dist * dist
  const last = track.length - 1
  for (let i = 1; i < last - 1; i++) {
    if (!bounds.contains([track[i].lat, track[i].lon])) {
      continue
    }

    p2 = projector.project([track[i].lat, track[i].lon], zoom)
    if (squaredDistance(p1, p2) < ddist) {
      continue
    }

    arrows.push({
      lat: track[i].lat,
      lon: track[i].lon,
      dir: track[i].dir,
    })

    p1 = p2
  }

  arrows.push({
    lat: track[last].lat,
    lon: track[last].lon,
    dir: track[last].dir,
  })
  return arrows
}

export const squaredDistance = (p1: Point, p2: Point): number => {
  return (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)
}