import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'antd/es/select'


import styles from './AdminPanel.module.css'


const logLevels = [{
  text: 'Only errors',
  value: 'errors',
}, {
  text: 'Info',
  value: 'info',
}, {
  text: 'Debug',
  value: 'debug',
}]

export const LogConfig = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [level, setLevel] = useState(logLevels[0].value)

  useEffect(() => {
    props.onAction('log', { action: 'get' }, (resp, err) => {
      setLoading(false)
      if (err) {
        setError(err.message)
        return
      }
      setLevel(resp.level)
    })
  }, [props])

  const onLogChange = (e) => {
    setLoading(true)
    props.onAction('log', { action: 'set', level: e }, (resp, err) => {
      setLoading(false)
      if (err) {
        setError(err.message)
        return
      }
      setLevel(resp.level)
    })
  }

  if (error) return <div> Error: {error}  </div>

  return <Select className={styles.logSelect} value={level} onChange={onLogChange} loading={loading}>
    {logLevels.map((level, key) => (
      <Select.Option key={key} value={level.value}>
        {level.text}
      </Select.Option>
    ))}
  </Select>
}

LogConfig.propTypes = {
  onAction: PropTypes.func.isRequired,
}
