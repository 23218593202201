import React from 'react'
import { observer } from 'mobx-react'
import { isMobile } from 'react-device-detect'


import { WarningMarker } from '../../components'
import { useStore } from '../../stores/rootStore'
import { IWarning } from '../../stores/interfaces'


interface Props { }


const Warnings: React.FC<Props> = (props) => {
  const store = useStore()

  const [warning, setWarning] = React.useState<IWarning | null>(null)

  const xShift = isMobile ? -store.ui.window.size.width + (store.ui.window.size.width / 4) : 100
  const yShift = store.ui.window.size.height / 2

  React.useEffect(() => {
    if (store.track.selectedWarning != null && store.track.shiftHelpers.warnings.length >= store.track.selectedWarning + 1) {
      const warn = store.track.shiftHelpers.warnings[store.track.selectedWarning]
      setWarning(warn)
      const bounds = [warn.coords.lat, warn.coords.lon, warn.coords.lat, warn.coords.lon]
      store.map.fitBounds(bounds, { x: xShift, y: yShift })
    } else if (store.track.selectedWarning == null) {
      setWarning(null)
    }
  }, [store.map, store.track.selectedWarning, store.track.shiftHelpers.warnings, xShift, yShift])

  return <>
    {warning != null && <WarningMarker
      warning={warning}
      openPopup={true}
      onClick={() => { store.track.setWarning(null) }}
    />}
  </>
}

export default observer(Warnings)
