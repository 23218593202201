// import { Children } from 'react'
import { PropTypes } from 'prop-types'
import { render, unmountComponentAtNode } from 'react-dom'
import { DomEvent } from 'leaflet'
import { MapControl, withLeaflet } from 'react-leaflet'


import DivControl from './DivControl'



/**
 * Basic Control component
 */
class Control extends MapControl {
  /**
   * @param {Object} param
   * @return {Object}
   */
  createLeafletElement({ position, className }) {
    this.leafletElement = new DivControl({ position, className })
    return this.leafletElement
  }

  /**
   * @param {Object} fromProps
   * @param {Object} toProps
   */
  updateLeafletElement(fromProps, toProps) {
    super.updateLeafletElement(fromProps, toProps)
    this.renderContent()
  }

  /**
   * Std lc hook
   */
  componentDidMount() {
    super.componentDidMount()
    this.renderContent()
    DomEvent.on(this.leafletElement.getContainer(), 'mousewheel', DomEvent.stopPropagation)
  }

  /**
   * std lc hook
   */
  componentWillUnmount() {
    DomEvent.off(this.leafletElement.getContainer(), 'mousewheel', DomEvent.stopPropagation)
    unmountComponentAtNode(this.leafletElement.getContainer())
    super.componentWillUnmount()
  }

  /**
   * Rendering method
   */
  renderContent() {
    const container = this.leafletElement.getContainer()
    // render(Children.only(this.props.children), container)
    render(this.props.children, container)
  }
}

Control.propTypes = {
  // className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  position: PropTypes.string,
}

export default withLeaflet(Control)
