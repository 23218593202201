import React from 'react'
import { observer } from 'mobx-react'


import { ShiftMarkers } from '../ShiftMarkers'
import { useStore } from '../../stores/rootStore'
import Stops from './Stops'
import Warnings from './Warnings'


interface HelperLayerProps { }


const HelperLayer: React.FC<HelperLayerProps> = (props) => {
  const store = useStore()

  return <>
    {store.track.currentShift != null && <ShiftMarkers
      start={store.track.trackLine[0]}
      end={store.track.trackLine[store.track.trackLine.length - 1]}
      shift={store.track.currentShift}
    />}

    <Stops />
    <Warnings />

    {/* Stops */}
    {/* {store.track.isStopsAllowed && store.track.trackHelpers.stops.length > 0
      && store.track.trackHelpers.stops.filter((stop, key) => {
        // const opened = selectedType === 0 && selectedIndex === key
        // // if (!opened) return null

        // const func = opened ?
        //   store.map.unsetSelectedHelper :
        //   () => {
        //     store.map.setSelectedHelper(0, key)
        //     // store.map.centerWithBounds(stop.coords, { x: 300, y: -400 })
        //   }
        // store.map.setSelectedHelper

        // if (opened) {
        //   store.map.centerWithBounds(stop.coords, { x: 300, y: -400 })
        // }

        // return <OStopMarker key={key} index={key} />

        if (store.track.selectedStop === key) {
          return <StopMarker
            key={key}
            stop={stop}
            openPopup={true}
            onClick={() => store.track.setStop(key)}
          />
        }
      })} */}

    {/* Warnings */}
    {/* {store.track.trackHelpers.warnings.length > 0
      && store.track.trackHelpers.warnings.map((warning, key) => {
        // const opened = selectedType === 1 && selectedIndex === key
        if (!store.track.isWarningAllowed(warning.warningType)) {
          return null
        }

        // const func = opened ?
        //   store.map.unsetSelectedHelper :
        //   store.map.setSelectedHelper

        return <WarningMarker
          key={key}
          openPopup={store.track.selectedWarning === key}
          warning={warning}
          onClick={() => store.track.setWarning(key)}
        />
      })} */}

    {/* TODO Control points */}
    {/* allowCP && null */}
  </>
}

export default observer(HelperLayer)


// interface OStopMarkerProps {
//   index: number
// }


// const OStopMarker: React.FC<OStopMarkerProps> = observer(({ index }) => {
//   const store = useStore()

//   const stop = store.track.trackHelpers.stops[index]

//   return <StopMarker
//     stop={stop}
//     openPopup={store.track.selectedStop === index}
//     onClick={() => store.track.setStop(index)}
//   />
// })
