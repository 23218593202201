import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Polyline, Tooltip } from 'react-leaflet'


import { RotatedMarker } from '../RotatedMarker'
import { getReadableTime } from '../../common'
import { arrowDivIcon, circleDivIcon } from '../../icons'
import { vertexClusterReductionSimple } from '../../tools/downsampling.ts'


import './device.css'


const normalizeDir = (dir) => dir < 0 ? 360 + dir : dir

const getColor = (status) => {
  let indicator = ''
  switch (status) {
    case 'online':
      indicator = '#3da107'
      break

    case 'old':
      indicator = '#f4dc22'
      break

    case 'rotten':
      indicator = '#727272'
      break

    case 'invalid':
    case 'error':
      indicator = '#c20000'
      break

    case 'offline':
    default:
      indicator = '#0f0f0f'
  }
  return indicator
}

const colors = {
  main: '#008000',
  mainFill: '#52af52',
  selected: '#035396',
  selectedFill: '#5c8bb2',
}

const DeviceComponent = (props) => {
  const deviceRef = useRef(null)

  const device = props.rootStore.map.devices.find((d) => d.deviceID === props.id)

  useEffect(() => {
    if (device != null) {
      device.params.ref = deviceRef
    }

    return () => {
      device.params.ref = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (device == null) {
    return null
  }

  const selected = props.rootStore.map.selected.id === props.id
  const color = selected ? colors.selected : getColor(device.data.status)

  const onClick = () => {
    if (typeof props.onClick === 'function') props.onClick()
  }

  const name = props.rootStore.map.devices.find((d) => d.deviceID === props.id).params.title
  const mobile = props.rootStore.ui.window.mobile
  const dir = normalizeDir(props.lastPoint.coords.dir)

  const condition = (dir > 45 && dir < 135) || (dir > 225 && dir < 315)
  const direction = mobile ? 'top' : condition ? 'top' : 'right'
  const offset = mobile ? [0, -12] : condition ? [0, -12] : [12, 0]

  const cn = selected ? 'top-tooltip' : ''
  const tooltip = <Tooltip permanent offset={offset} direction={direction} zoomAnimation={false} className={cn}>
    <div>{name}</div>
    {selected && (
      <React.Fragment>
        <div>{'Время: ' + getReadableTime(props.lastPoint.time)}</div>
        <div>{'Скорость: ' + props.lastPoint.coords.speed + 'км/ч'}</div>
      </React.Fragment>
    )}
  </Tooltip>

  const icon = props.lastPoint.coords.speed > 0
    ? arrowDivIcon(24, '#fff', 0.6, color, 7, selected)
    : circleDivIcon(24, '#fff', 0.6, color, 2, selected)

  const angle = props.lastPoint.coords.speed > 0 ? props.lastPoint.coords.dir : 0

  const tail = vertexClusterReductionSimple(
    props.rootStore.ui.mapState.ref.current.leafletElement,
    props.rootStore.map.currentZoom,
    device.data.points,
  )

  return (
    <React.Fragment>
      {tail.length > 0 && <Polyline color={color} weight={4} lineCap={'round'} positions={tail} />}

      <RotatedMarker
        ref={deviceRef}
        icon={icon}
        position={[props.lastPoint.coords.lat, props.lastPoint.coords.lon]}
        rotationAngle={angle}
        onClick={onClick}
        onAdd={(evt) => {
          if (selected) {
            evt.target.setZIndexOffset(999999)
          } else {
            evt.target.setZIndexOffset(0)
          }
        }}
      >
        {tooltip}
      </RotatedMarker>
    </React.Fragment>
  )
}

DeviceComponent.propTypes = {
  rootStore: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  lastPoint: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export const Device = inject('rootStore')(observer(DeviceComponent))
