import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';


import { MapLayoutContainer } from '../components';
import { getErrorMessage, errors } from '../common';
import api from '../api';
import { useStore } from '../stores/rootStore';


// import styles from './Users.module.css'


interface AppRootParams {
  userID: string
}

interface AppRootProps extends RouteComponentProps<AppRootParams> { }


const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

const AppRoot: React.FC<AppRootProps> = ({ match }) => {
  const store = useStore()

  const history = useHistory()
  const [loading, setLoading] = useState(true) // && store.core.changingUser
  const [error, setError] = useState(null)

  // useEffect(() => {
  //   // props.rootStore.profile.getSettings)
  // }, [])

  useEffect(() => {
    if (store.profile.invoker == null) {
      return
    }

    setLoading(true)

    const id = match.params.userID
    const len = id.length
    if (len === 0 || (id !== '0' && !uuidRegex.test(id))) {
      // setLoading(false)
      history.push(`/maps/0/`)
      return
    }

    // if (store.core.current == null) {
    //   return
    // }

    // if (store.core.current != null && (match.params.userID === store.core?.current.userID ||
    //   (match.params.userID === '0' && store.core.current?.userID === store.core.invoker.userID))) {
    //   setLoading(false)
    //   return
    // }

    if (id === '0' && store.profile.user == null) {
      api.currentUser()
        .then(store.profile.getSettings)
        .catch((err) => { console.info('Settings fetch error:', err) })
        .finally(() => {
          // rootStore.map.loadMapCenter()
          setLoading(false)
          // props.rootStore.map.startLoadingMessage()
        })
      // setLoading(false)
      return
    }

    if (store.profile.user == null || (store.profile.user.userID !== id && id !== '0')) {
      api.user(id)
        .then(store.profile.getSettings)
        .then(() => {
          setLoading(false)
        })
        .catch((err) => {
          console.info('Cannot set current', err)
          setLoading(false)

          if (err.code === errors.accessDenied) {
            // history.push(slugs.main + slugs.dashboard + '/0')
            return
          }

          setError(getErrorMessage(err, 'Ошибка при получении данных пользователя'))
        })
    } else {
      setLoading(false)
    }
  }, [history, match.params.userID, match.url, store.profile, store.profile.getSettings, store.profile.invoker, store.profile.user])

  // if (rootStore.profile.user == null) return <Redirect to={'/maps/login'} />

  if (error) {
    return <div>{error}</div>
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return <MapLayoutContainer rootStore={store} />
}

export default withRouter(observer(AppRoot))
