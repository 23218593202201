import React, { Suspense } from 'react'
import { NavLink, Route, RouteComponentProps, Switch, withRouter, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { ArrowLeftOutlined } from '@ant-design/icons'


// import { MapFeature } from '../../store/interfaces'
import { useStore } from '../../stores/rootStore'


import styles from './SideControlPanel.module.css'


interface SidePanelProps extends RouteComponentProps {
  features: any[] // TODO type MapFeature[]
}


// TODO On mobile stays horizontal
const SideControlPanel: React.FC<SidePanelProps> = (props) => {
  const store = useStore()
  const location = useLocation()

  const onClick = () => {
    store.ui.setSideMenuActive(!store.ui.sideMenuActive)
  }

  return <div className={styles.container}>
    <div className={styles.controls}>
      {props.features.map((ft) => {
        return <NavLink
          className={styles.linkIcon}
          activeClassName={styles.linkIconActive}
          key={`tab-key-${ft.name}`}
          title={ft.title}
          exact
          to={ft.path}
          onClick={() => {
            if (store.ui.sideMenuActive && location.pathname === ft.path) {
              store.ui.setSideMenuActive(false)
            } else {
              store.ui.setSideMenuActive(true)
            }
          }}
        >
          <ft.icon />
        </NavLink>
      })}
    </div>

    <div className={store.ui.sideMenuActive ? styles.panel : styles.panelCollapsed}>
      <div className={styles.panelHeader}>
        <div className={styles.headerTitle}>
          <Switch>
            {props.features.map((ft) => {
              return <Route key={`panel-header-${ft.name}`} exact path={ft.path} render={() => {
                return <span className={styles.headerLabel}>{ft.title}</span>
              }} />
            })}
          </Switch>
        </div>

        <div className={styles.collapseIcon} onClick={onClick}>
          <ArrowLeftOutlined />
        </div>
      </div>

      <div className={styles.panelBody}>
        <Suspense fallback={<div>Загрузка...</div>}>
          <Switch>
            {props.features.map((ft) => {
              return <Route
                key={`panel-route-${ft.name}`}
                exact
                path={ft.path}
                component={ft.control}
              />
            })}
          </Switch>
        </Suspense>
      </div>
    </div>
  </div>
}

export default withRouter(observer(SideControlPanel))
