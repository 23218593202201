import L from 'leaflet'

export const arrowDivIcon = (size, fillColor, fillOpacity, strokeColor, strokeWidth, onTop) => new L.DivIcon({
  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg width="${size}" height="${size}" version="1.1"
    viewBox="0 0 26.458 26.458" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 -92.484)">
      <path transform="matrix(.26458 0 0 .26458 0 92.484)"
        d="m16.326
        76.609c-0.17247-0.087262-0.36627-0.3085-0.43068-0.49164-0.10281-0.29233
        1.8952-4.6621 16.366-35.793 11.851-25.495 16.57-35.525 16.793-35.692
        0.45928-0.34291 1.2144-0.39766 1.7283-0.12532 0.40682 0.21563 1.2936
        2.0873 16.888 35.646 9.0525 19.48 16.491 35.518 16.531 35.64 0.04652
        0.14348-0.06159 0.35541-0.30596 0.59978-0.31888
        0.31888-0.47646 0.37789-1.0091 0.37789-0.53499
        0-0.75295-0.0825-1.4302-0.54135-0.43948-0.29774-7.6656-5.4685\
-16.058-11.49-8.3924-6.022-15.325-10.948-15.405-10.946-0.08022
        0.0017-5.2598 3.6735-11.51 8.1594-22.912 16.444-20.58 14.819-21.252
        14.817-0.32597-1e-3 -0.73377-0.07326-0.90624-0.16052z"
        fill="${fillColor}"
        fill-opacity=${fillOpacity}
        stroke="${strokeColor}"
        stroke-width="${strokeWidth}"/>
    </g>
  </svg>`,
  iconAnchor: [size / 2, size / 2],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [size, size],
  className: onTop ? 'onTop' : '', // 'with-shadow',
})

export const circleDivIcon = (size, fillColor, fillOpacity, strokeColor, strokeWidth, onTop) => {
  const c = size / 2
  const r = (size - 4) / 2

  return new L.DivIcon({
    html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg width="${size}" height="${size}" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle cx="${c}" cy="${c}" r="${r}" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${fillColor}" />
  </svg>`,
    iconAnchor: [c, c],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    className: onTop ? 'onTop' : '', // 'with-shadow',
  })
}
